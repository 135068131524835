import React from "react";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import { connect } from "react-redux";
import { getDiversionCentersAction } from "../../actions/diversion-center";
import {
  DATE_FORMAT,
  RISK_FLAG_COLOR,
  TIME_FORMAT,
  DIVERSION_TAB,
  SEARCH_CLIENT_RES,
  TO_DIVERSION_CENTRE,
  IS_CLICKED_DIVERSION,
} from "../../helpers/constants";
import store from "../../store";
import parsePhoneNumber from "libphonenumber-js";
import { BootstrapTooltip } from "../shared/hovertip/tooltip";
import moment from "moment";
import { getAge, getUser, toast } from "../../helpers/utility";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import FlagIcon from "@material-ui/icons/Flag";

import "../community-patrol/community-patrol.scss";

import Styles from "../shared/css/default.module.css";

class ActiveUserCommunity extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { diversions: [] };
  }
  componentDidMount() {
    store.dispatch(getDiversionCentersAction());
  }

  componentDidUpdate(prevProps) { }

  activeClock(observation) {
    if (!observation || observation.length === 0) {
      return true;
    }

    const time = moment().diff(
      moment(observation[observation.length - 1].clientCheck),
      "m"
    );
    const initTime = moment().diff(moment(observation[0].clientCheck), "m");

    /** Requirement 1: Alarm must activate every 15 minutes for the first 4 hours */
    if (initTime < 240 && time >= 15) {
      return true;
    }

    /** Requirement 2: Alarm must activate every 30 minutes every hour after */
    if (initTime >= 240 && time >= 30) {
      return true;
    }

    return false;
  }

  handleClick(diversion) {
    store.dispatch({ type: DIVERSION_TAB, payload: diversion });
    store.dispatch({ type: IS_CLICKED_DIVERSION, payload: true });
    // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [diversion] });
    setTimeout(() => {
      this.props.setKey("client");
    }, 500);
  }

  render() {
    return (
      <>
        <div className="table-responsive">
          <div className="active-user full-width">
            {/* <Table className="text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile</th>
                            <th>DOB</th>
                            <th>Date</th>
                            <th>Time in</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.diversions.map((diversion, index) => (
                            <React.Fragment key={"Diversion" + index}>
                                {!diversion.isCheckout &&
                                    <BootstrapTooltip key={"active-diversion-" + index} title={diversion.createdDiversion.name}>
                                        <tr style={{ cursor: "pointer" }} onClick={() => { this.props.fromDashboard ? this.props.history.push(TO_DIVERSION_CENTRE + '/new/' + diversion.Client.id) : this.handleClick(diversion) }}>

                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {diversion.Client.highRisk && <FlagIcon style={{ fill: RISK_FLAG_COLOR }} />}
                                            </td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.firstName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.lastName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.phone && parsePhoneNumber(diversion.Client.phone) ? parsePhoneNumber(diversion.Client.phone).formatNational() : diversion.Client.phone}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.dob ? <Moment format={DATE_FORMAT}>{diversion.Client.dob}</Moment> : 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={DATE_FORMAT}>{diversion.timeIn}</Moment></td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={TIME_FORMAT}>{diversion.timeIn}</Moment></td>
                                            <td className="justify-content-end">
                                                <img
                                                    src={diversion.isCheckout ? require("../../assets/images/orange-checkbox.svg") : require("../../assets/images/grey-checkbox.svg")}
                                                    alt="logo"
                                                />
                                                {this.activeClock(diversion.ClientObservationForms) && <AccessAlarmIcon className="ml-1" style={{ color: '#f36b21' }}></AccessAlarmIcon>}
                                                {!this.activeClock(diversion.ClientObservationForms) && <AccessAlarmIcon className="ml-1" style={{ color: '#929eaa' }}></AccessAlarmIcon>}
                                            </td>
                                        </tr>
                                    </BootstrapTooltip>}
                                {diversion.isCheckout && getUser() && getUser().Role.admin &&
                                    <BootstrapTooltip key={"active-diversion-flags-" + index} title={diversion.createdDiversion.name}>
                                        <tr style={{ cursor: "pointer" }} onClick={() => { this.props.fromDashboard ? this.props.history.push(TO_DIVERSION_CENTRE + '/new/' + diversion.Client.id) : this.handleClick(diversion) }}>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {diversion.Client.highRisk && <FlagIcon style={{ fill: RISK_FLAG_COLOR }} />}
                                            </td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.firstName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.lastName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.phone && parsePhoneNumber(diversion.Client.phone) ? parsePhoneNumber(diversion.Client.phone).formatNational() : diversion.Client.phone}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{diversion.Client.dob ? <Moment format={DATE_FORMAT}>{diversion.Client.dob}</Moment> : 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={DATE_FORMAT}>{diversion.timeIn}</Moment></td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={TIME_FORMAT}>{diversion.timeIn}</Moment></td>
                                            <td className="justify-content-end">
                                                <img
                                                    src={diversion.isCheckout ? require("../../assets/images/orange-checkbox.svg") : require("../../assets/images/grey-checkbox.svg")}
                                                    alt="logo"
                                                />
                                                <AccessAlarmIcon className="ml-1" style={{ color: '#929eaa' }}></AccessAlarmIcon>
                                            </td>
                                        </tr>
                                    </BootstrapTooltip>
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table> */}

            <table
              className={`${Styles.table_custom} ${Styles.diversion_table}`}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Mobile</th>
                  <th>DOB</th>
                  <th>Date</th>
                  <th>Time in</th>
                  <th>Time out</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "#fff" }}>
                {this.props.diversions.map((diversion, index) => (
                  <React.Fragment key={"Diversion" + index}>
                    {!diversion.isCheckout && (
                      <BootstrapTooltip
                        key={"active-diversion-" + index}
                        title={diversion.createdDiversion.name}
                      >
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (this.props.fromDashboard) {
                              if (getUser().Role.admin) {
                                this.props.history.push(
                                  TO_DIVERSION_CENTRE +
                                  "/new/" +
                                  diversion.Client.id
                                );
                              } else {
                                toast(
                                  "You are not allowed to edit diversion center!",
                                  "error"
                                );
                              }
                            } else {
                              this.handleClick(diversion);
                            }
                          }}
                        >
                          <td>
                            {diversion.Client.highRisk && (
                              <FlagIcon style={{ fill: RISK_FLAG_COLOR }} />
                            )}
                          </td>
                          <td>{diversion.Client.firstName || "N/A"}</td>
                          <td>{diversion.Client.lastName || "N/A"}</td>
                          <td>
                            {diversion.Client.phone &&
                              parsePhoneNumber(diversion.Client.phone)
                              ? parsePhoneNumber(
                                diversion.Client.phone
                              ).formatNational()
                              : diversion.Client.phone}
                          </td>
                          <td>
                            {diversion.Client.dob ? (
                              <Moment format={DATE_FORMAT}>
                                {diversion.Client.dob}
                              </Moment>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            <Moment format={DATE_FORMAT}>
                              {diversion.timeIn}
                            </Moment>
                          </td>
                          <td>
                            <Moment format={TIME_FORMAT}>
                              {diversion.timeIn}
                            </Moment>
                          </td>
                          <td></td>
                          <td>
                            <img
                              src={
                                diversion.isCheckout
                                  ? require("../../assets/images2/check-circle.svg")
                                  : require("../../assets/images2/check-circle.svg")
                              }
                              alt="logo"
                            />
                            {this.activeClock(
                              diversion.ClientObservationForms
                            ) && (
                                <img
                                  src={
                                    diversion.isCheckout
                                      ? require("../../assets/images2/bell-grey.png")
                                      : require("../../assets/images2/bell-red.png")
                                  }
                                  alt="logo"
                                />
                              )}
                            {!this.activeClock(
                              diversion.ClientObservationForms
                            ) && (
                                <img
                                  src={
                                    diversion.isCheckout
                                      ? require("../../assets/images2/bell-grey.png")
                                      : require("../../assets/images2/bell-grey.png")
                                  }
                                  alt="logo"
                                />
                              )}
                          </td>
                        </tr>
                      </BootstrapTooltip>
                    )}
                    {diversion.isCheckout &&
                      getUser() &&
                      getUser().Role.admin && (
                        <BootstrapTooltip
                          key={"active-diversion-flags-" + index}
                          title={diversion.createdDiversion.name}
                        >
                          <tr
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.fromDashboard
                                ? this.props.history.push(
                                  TO_DIVERSION_CENTRE +
                                  "/new/" +
                                  diversion.Client.id
                                )
                                : this.handleClick(diversion);
                            }}
                          >
                            <td>
                              {diversion.Client.highRisk && (
                                <FlagIcon style={{ fill: RISK_FLAG_COLOR }} />
                              )}
                            </td>
                            <td>{diversion.Client.firstName || "N/A"}</td>
                            <td>{diversion.Client.lastName || "N/A"}</td>
                            <td>
                              {diversion.Client.phone &&
                                parsePhoneNumber(diversion.Client.phone)
                                ? parsePhoneNumber(
                                  diversion.Client.phone
                                ).formatNational()
                                : diversion.Client.phone}
                            </td>
                            <td>
                              {diversion.Client.dob ? (
                                <Moment format={DATE_FORMAT}>
                                  {diversion.Client.dob}
                                </Moment>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td>
                              <Moment format={DATE_FORMAT}>
                                {diversion.timeIn}
                              </Moment>
                            </td>
                            <td>
                              <Moment format={TIME_FORMAT}>
                                {diversion.timeIn}
                              </Moment>
                            </td>
                            <td>
                              <Moment format={TIME_FORMAT}>
                                {diversion.checkoutAt}
                              </Moment>
                            </td>
                            <td className="justify-content-end">
                              <img
                                src={
                                  diversion.isCheckout
                                    ? require("../../assets/images2/check-circle-green.svg")
                                    : require("../../assets/images2/check-circle-green.svg")
                                }
                                alt="logo"
                              />
                              <img
                                src={require("../../assets/images2/bell.png")}
                                alt="logo"
                              />
                            </td>
                          </tr>
                        </BootstrapTooltip>
                      )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {this.props.fromDashboard && (
          // <div class="btn-sec full-width form-btn text-right">
          <div className="btn-sec full-width form-btn text-right">
            {/* <button
              onClick={() =>
                this.props.history.push(TO_DIVERSION_CENTRE + "/new")
              }
              className="main-btn orange-btn btn btn-primary mr-3 save-btn"
            >
              Add New
            </button> */}
            <div
              className={`${Styles.diversion_block}`}
              style={{ textAlign: "end" }}
            >
              <a
                className={`${Styles.btn} ${Styles.btn_yellow}`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.history.push(TO_DIVERSION_CENTRE + "/new")
                }
              >
                Add New
              </a>
            </div>
            {/* </div>
                        <button class="main-btn default-btn btn btn-primary mr-2"> </button> */}
          </div>
        )}
      </>
    );
  }
}
const stateMap = (state) => {
  return {
    diversions: state.diversionCenter.diversions,
    // searchResult: state.communityPatrol.activePatrolClients
  };
};
// export default connect(stateMap)(MainClientsContainer);
export default connect(stateMap)(ActiveUserCommunity);
