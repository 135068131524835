import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import AdminUserTable from "./../../components/admin-form/admin-user-listing";
import AdminForm from "../../components/admin-form/admin-form";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";
import { connect } from "react-redux";
import store from "../../store";
import { listUsers } from "../../api/user/user-api";
import { toast } from "../../helpers/utility";
import { GetUsersAction } from "../../actions/user/profile";
import { StylesContext } from "@material-ui/styles";

import Styles from "../../components/shared/css/default.module.css";

class ListAdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
      isSidebarOpen: false,
    };
  }
  componentDidMount() {
    // store.dispatch(listUsers())
    store.dispatch(GetUsersAction(`query=&page=1`)); //set in session storage
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.users) !== JSON.stringify(this.props.users)) {
      toast("Success");
    }
  }
  render() {
    return (
      //   <div className="admin-content" style={{ display: "flex" }}>
      <div
        id={Styles["wrapper"]}
        className={this.state.isSidebarOpen ? Styles.toggled : ""}
      >
        <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
          {/* <AdminForm {...this.props} /> */}
        </Sidebar>
        <Header
          handleSidebar={() =>
            this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
          }
          {...this.props}
        />
        <AdminUserTable users={this.props.users} {...this.props} />
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    searchClients: state.client.searchClients,
    client: state.client.client,
    user: state.user.admin,
    users: state.user.users,
    totalUsers: state.user.totalUsers,
    totalSearchUsers: state.user.totalSearchUsers,
  };
};
export default connect(stateMap)(ListAdminContainer);
