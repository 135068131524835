import React from "react";
import LoginForm from "../../../components/user/login-form/login-form";

export default class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
  }
  render() {
    return (
      <div>
        <div className="main-content" style={{ backgroundColor: "white" }}>
          <LoginForm {...this.props} />
        </div>
      </div>
    );
  }
}
