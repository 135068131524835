import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import Modal from "react-bootstrap/Modal";
import ClientMedicalRiskForm from "../shared/client-medical-risk-form";
import ClientWellBeingForm from "../shared/client-wellbeing-observation-form";
import ClientObservationForm from "../shared/client-observation-form";
import ViolanceForm from "../shared/domestic-violance";
import CustomForm from "react-validation/build/form";
import moment from "moment";
import Moment from "react-moment";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import "./diversion-centre.scss";
import {
  FULL_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT,
} from "../../helpers/constants";
import { toast } from "../../helpers/utility";
import LocationSearchInput from "../shared/google-places";
import BackgroundInformation from "../shared/background-information";
import CharacterCounter from "../../containers/character-counter/character-counter";
import { CLIENT_ACCESS } from "../../helpers/constants";
import { getUser } from '../../helpers/utility';

import Styles from "../shared/css/default.module.css";

const className = "UserDetailDiversion";

class UserDetailDiversion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      cTime: "",
      isUpdate: false,
      petrol: {},
      contacts: [],
      user: {
        confirmSignup: null,
        createdAt: null,
        deletedAt: null,
        dob: null,
        email: null,
        firstName: null,
        id: null,
        lastName: null,
        location: null,
        name: null,
        phone: null,
        picture: null,
        refId: null,
        role: null,
        updatedAt: null
      }
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.contactChange = this.contactChange.bind(this);

    this.handleClose = this.handleClose.bind(this);

    this.options4 = [
      { label: "Awake", value: 1 },
      { label: "Sleep", value: 2 },
    ];
    this.options5 = [
      { label: "Yes", value: 1 },
      { label: "No", value: 2 },
    ];
    this.options6 = [
      { label: "Service 1", value: 1 },
      { label: "Service 2", value: 2 },
    ];

    // React doesn't like nulls being used as values on inputs
    if (props.stateDiversion && props.stateDiversion.alterComment === null) {
      props.stateDiversion.alterComment = "";
    }

    // if (
    //   props.stateDiversion &&
    //   props.stateDiversion.belongingComment === null
    // ) {
    //   props.stateDiversion.belongingComment = "";
    // }

    if (props.stateDiversion && props.stateDiversion.leaveComment2 === null) {
      props.stateDiversion.leaveComment2 = "";
    }

    if (props.stateDiversion && props.stateDiversion.accessComment === null) {
      props.stateDiversion.accessComment = "";
    }

    if (
      (props.stateDiversion && props.stateDiversion.bedNumber === null) ||
      (props.stateDiversion && props.stateDiversion.bedNumber === undefined)
    ) {
      props.stateDiversion.bedNumber = 0;
    }

    if (props.stateDiversion && props.stateDiversion.estimatedTime === null) {
      props.stateDiversion.estimatedTime = 0;
    }
  }

  componentDidMount() {
    const user = getUser();
    this.setState({
      user,
      cTime: moment().format(FULL_TIME_FORMAT).trim(),
    });

    this.setState({ contacts: this.props.contacts });
    this.setState({ petrol: this.props.stateDiversion });
  }

  componentDidUpdate(prevProp) {
    const signature = className + '.componentDidUpdate: ';

    if (
      JSON.stringify(prevProp.contacts) !== JSON.stringify(this.props.contacts)
    ) {
      console.log(signature + `Updating Contact List. Found Total[${this.props.contacts.length}] contacts`);
      this.setState({ contacts: this.props.contacts });
    }

  }

  handleSave() {
    if (
      this.props.stateDiversion.thingsToConsider &&
      this.props.stateDiversion.thingsToConsider2 &&
      this.props.stateDiversion.thingsToConsider3
    ) {
      this.setState({ show: false });
    } else {
      toast("Please select all consent boxes ", "error");
    }
  }

  handleFilter(e, newInputValue) {
    this.setState({
      contacts: this.props.contacts.filter(
        (elem) =>
          elem.firstName.toLowerCase().includes(newInputValue) ||
          elem.lastName.toLowerCase().includes(newInputValue) ||
          elem.organizationName.toLowerCase().includes(newInputValue)
      ),
    });
  }

  handleClose() {
    if (
      JSON.stringify(this.state.petrol) !==
      JSON.stringify(this.props.stateDiversion)
    ) {
      if (
        this.state.petrol.thingsToConsider ||
        this.state.petrol.thingsToConsider2 ||
        this.state.petrol.thingsToConsider3
      ) {
        this.setState({ show: false, isUpdate: false });
      } else {
        this.props.changeState();
        this.setState({ show: false, isUpdate: false });
      }
    } else if (!this.state.isUpdate) {
      this.props.changeState();
      this.setState({ show: false, isUpdate: false });
    } else {
      //
      this.setState({ show: false, isUpdate: false });
    }
  }

  contactChange(e, v) {
    if (v)
      this.props.onChange(
        { target: { name: "referrelId", value: v.id } },
        "select"
      );
  }

  // render() {
  //   return (
  //     <div className="new-community diversion full-width">
  //       <CustomForm
  //         ref={(c) => {
  //           this.form = c;
  //         }}
  //         onSubmit={(e) => this.props.onhandleOnSubmit(e)}
  //       >
  //         <Row className="p-2">
  //           <Col md={4}>
  //             <Form.Label className="text-label">
  //               How did the person access the service?
  //             </Form.Label>
  //           </Col>
  //           <Col md={8}>
  //             <Select
  //               className="selection-select arrow-down custom-width"
  //               placeholder=""
  //               options={CLIENT_ACCESS}
  //               values={[
  //                 ...CLIENT_ACCESS.filter(
  //                   (elem) =>
  //                     elem.value ===
  //                     (this.props.stateDiversion.accessComment || "")
  //                 ),
  //               ]}
  //               name="accessComment"
  //               onChange={(e) => this.props.handleDropDown(e, "accessComment")}
  //             />
  //           </Col>
  //         </Row>

  //         <Row className="p-2">
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Breaking the Cycle Participant?{" "}
  //                 </Form.Label>
  //               </Col>
  //               <Col md={3}>
  //                 <Form.Control
  //                   checked={!this.props.stateDiversion.isBreakingTheCycle}
  //                   value={false}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isBreakingTheCycle"
  //                   type="radio"
  //                   id="community-checkbox49"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox49">No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   checked={this.props.stateDiversion.isBreakingTheCycle}
  //                   value={true}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isBreakingTheCycle"
  //                   type="radio"
  //                   id="community-checkbox50"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox50">Yes</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>

  //         <Row className="p-2 ">
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">Bed Number</Form.Label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={5}>
  //                 <Form.Control
  //                   value={this.props.stateDiversion.bedNumber}
  //                   name="bedNumber"
  //                   onChange={(e) => this.props.onChange(e)}
  //                   type="number"
  //                   className="form-fields pt-0"
  //                   placeholder="Bed Number"
  //                   min={0}
  //                 />
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>

  //         <Row className="p-2">
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Has the client been involved in any altercations immediately
  //                   prior to their arrival at the Diversion Centre?
  //                 </Form.Label>
  //               </Col>
  //               <Col md={3}>
  //                 <Form.Control
  //                   checked={!this.props.stateDiversion.isAlter}
  //                   value={false}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isAlter"
  //                   type="radio"
  //                   id="community-checkbox51"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox51">No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   checked={this.props.stateDiversion.isAlter}
  //                   value={true}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isAlter"
  //                   type="radio"
  //                   id="community-checkbox52"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox52">
  //                   Yes. If yes, provide details below.
  //                 </label>
  //               </Col>
  //               <Col md={12}>
  //                 <Form.Group controlId="exampleForm.ControlTextarea1">
  //                   <CharacterCounter>
  //                     <Form.Control
  //                       disabled={
  //                         !this.props.stateDiversion.isAlter ||
  //                         this.props.stateDiversion.isCheckout
  //                       }
  //                       value={this.props.stateDiversion.alterComment}
  //                       onChange={(e) => this.props.onChange(e)}
  //                       name="alterComment"
  //                       as="textarea"
  //                       rows={2}
  //                       maxLength={255}
  //                     />
  //                   </CharacterCounter>
  //                 </Form.Group>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>
  //         {/* <Row className="bg-grey d-flex align-items-center p-2">
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Does the client have any belongings on them (Including
  //                   medication)?{" "}
  //                 </Form.Label>
  //               </Col>
  //               <Col md={3}>
  //                 <Form.Control
  //                   checked={!this.props.stateDiversion.isBelonging}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   value={false}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isBelonging"
  //                   type="radio"
  //                   id="community-checkbox53"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox53">No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   checked={this.props.stateDiversion.isBelonging}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   value={true}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isBelonging"
  //                   type="radio"
  //                   id="community-checkbox54"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox54">
  //                   Yes. If yes, please ensure all items are stored securely.
  //                 </label>
  //               </Col>
  //               <Col md={12}>
  //                 <Form.Group controlId="exampleForm.ControlTextarea1">
  //                   <CharacterCounter>
  //                     <Form.Control
  //                       disabled={
  //                         !this.props.stateDiversion.isBelonging ||
  //                         this.props.stateDiversion.isCheckout
  //                       }
  //                       value={this.props.stateDiversion.belongingComment}
  //                       onChange={(e) => this.props.onChange(e)}
  //                       name="belongingComment"
  //                       as="textarea"
  //                       rows={2}
  //                       maxLength={255}
  //                     />
  //                   </CharacterCounter>
  //                 </Form.Group>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row> */}
  //         <Row className="d-flex align-items-center  p-2">
  //           <ViolanceForm
  //             patrol={this.props.stateDiversion}
  //             {...this.props}
  //           ></ViolanceForm>
  //         </Row>

  //         <Row className="d-flex align-items-center p-2">
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Does the client have any weapons, illicit substances or
  //                   alcohol in their possession?{" "}
  //                 </Form.Label>
  //               </Col>
  //               <Col md={4}>
  //                 <Form.Control
  //                   checked={
  //                     !this.props.stateDiversion.isIllicitSubstancesFound
  //                   }
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   value={false}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isIllicitSubstancesFound"
  //                   type="radio"
  //                   id="community-checkbox160"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox160">No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   checked={this.props.stateDiversion.isIllicitSubstancesFound}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   value={true}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isIllicitSubstancesFound"
  //                   type="radio"
  //                   id="community-checkbox161"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox161">Yes</label>
  //               </Col>
  //             </Row>
  //           </Col>

  //           <Col
  //             style={
  //               this.props.isIllicitSubstancesFoundCheck
  //                 ? { marginTop: "1rem" }
  //                 : {}
  //             }
  //             md={12}
  //           >
  //             <>
  //               {(this.props.isIllicitSubstancesFoundCheck ||
  //                 this.props.stateDiversion.isIllicitSubstancesFound) && (
  //                 <Row className="p-2">
  //                   <Col md={6}>
  //                     <Row>
  //                       <Col md={8}>
  //                         <Form.Label className="text-label">
  //                           Have the items been confiscated?{" "}
  //                         </Form.Label>
  //                       </Col>
  //                       <Col md={3}>
  //                         <Form.Control
  //                           checked={
  //                             !this.props.stateDiversion
  //                               .isIllicitSubstancesConfiscated
  //                           }
  //                           value={false}
  //                           disabled={this.props.stateDiversion.isCheckout}
  //                           onChange={(e) => this.props.onChange(e, "check")}
  //                           name="isIllicitSubstancesConfiscated"
  //                           type="radio"
  //                           id="community-checkbox162"
  //                           className="custom-checkbox"
  //                         />
  //                         <label htmlFor="community-checkbox162">No</label>
  //                       </Col>
  //                     </Row>
  //                   </Col>
  //                   <Col md={6}>
  //                     <Row>
  //                       <Col md={12}>
  //                         <Form.Control
  //                           checked={
  //                             this.props.stateDiversion
  //                               .isIllicitSubstancesConfiscated
  //                           }
  //                           value={true}
  //                           disabled={this.props.stateDiversion.isCheckout}
  //                           onChange={(e) => this.props.onChange(e, "check")}
  //                           name="isIllicitSubstancesConfiscated"
  //                           type="radio"
  //                           id="community-checkbox158"
  //                           className="custom-checkbox"
  //                         />
  //                         <label htmlFor="community-checkbox158">Yes</label>
  //                       </Col>
  //                     </Row>
  //                   </Col>
  //                 </Row>
  //               )}
  //             </>
  //           </Col>
  //         </Row>

  //         <Row className="bg-grey d-flex align-items-center p-2">
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Does the client have any known medical conditions?{" "}
  //                 </Form.Label>
  //               </Col>
  //               <Col md={4}>
  //                 <Form.Control
  //                   checked={!this.props.stateDiversion.isMedical}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   value={false}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isMedical"
  //                   type="radio"
  //                   id="community-checkbox155"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox155">No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   checked={this.props.stateDiversion.isMedical}
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   value={true}
  //                   onChange={(e) => this.props.onChange(e, "check")}
  //                   name="isMedical"
  //                   type="radio"
  //                   id="community-checkbox156"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox156">
  //                   Yes. If yes, you must complete the Client needs and risk
  //                   identification form
  //                 </label>
  //               </Col>
  //             </Row>
  //           </Col>

  //           <Col
  //             style={
  //               this.props.isMedicalCheck
  //                 ? { border: "1px solid black", marginTop: "1rem" }
  //                 : {}
  //             }
  //             md={12}
  //           >
  //             <>
  //               {(this.props.isMedicalCheck ||
  //                 this.props.stateDiversion.isMedical) && (
  //                 <ClientMedicalRiskForm
  //                   disabled={this.props.stateDiversion.isCheckout}
  //                   medObs={this.props.stateDiversion}
  //                   onFieldChange={this.props.onChange}
  //                 ></ClientMedicalRiskForm>
  //               )}
  //             </>
  //           </Col>
  //         </Row>
  //         {this.props.clientTab ? (
  //           <>
  //             <Row>
  //               <ClientWellBeingForm
  //                 medObs={this.props.stateDiversion}
  //                 disabled={this.props.stateDiversion.isCheckout}
  //                 onFieldChange={this.props.onChange}
  //               ></ClientWellBeingForm>
  //             </Row>
  //             <Row className="d-flex align-items-center p-2">
  //               <ClientObservationForm
  //                 medObs={this.props.stateDiversion}
  //                 disabled={this.props.stateDiversion.isCheckout}
  //                 {...this.props}
  //               ></ClientObservationForm>
  //             </Row>
  //           </>
  //         ) : null}
  //         <Row className="d-flex align-items-center p-2">
  //           <Col md={12}>
  //             <div className="client-record pl-0">
  //               <h6>CLIENT BACKGROUND INFORMATION</h6>
  //             </div>
  //           </Col>
  //           <Col md={12}>
  //             <BackgroundInformation
  //               stateDiversion={this.props.stateDiversion}
  //               {...this.props}
  //             ></BackgroundInformation>
  //           </Col>
  //         </Row>
  //         <Row className="pl-2">
  //           <Col md={12}>
  //             <div className="client-record pl-0">
  //               {this.props.clientTab && <h6>CLIENT EXIT DETAILS</h6>}
  //             </div>
  //           </Col>
  //         </Row>
  //         {this.props.clientTab && (
  //           <Row className="p-2 bg-grey">
  //             <Col md={6}>
  //               <Row>
  //                 <Col md={8}>
  //                   <Form.Label className="text-label">
  //                     Has a referral been made on behalf of the client{" "}
  //                   </Form.Label>
  //                 </Col>
  //                 <Col md={4}>
  //                   <Form.Control
  //                     checked={!this.props.stateDiversion.isReferral}
  //                     disabled={this.props.stateDiversion.isCheckout}
  //                     value={false}
  //                     onChange={(e) => {
  //                       this.props.onChange(e, "check");
  //                       this.setState({ show: false });
  //                     }}
  //                     name="isReferral"
  //                     type="radio"
  //                     id="community-checkbox63"
  //                     className="custom-checkbox"
  //                   />
  //                   <label htmlFor="community-checkbox63">No</label>
  //                 </Col>
  //               </Row>
  //             </Col>
  //             <Col md={6}>
  //               <Row>
  //                 <Col md={8}>
  //                   <Form.Control
  //                     checked={this.props.stateDiversion.isReferral}
  //                     value={true}
  //                     disabled={this.props.stateDiversion.isCheckout}
  //                     onChange={(e) => {
  //                       this.props.onChange(e, "check");
  //                       this.setState({ show: true });
  //                     }}
  //                     name="isReferral"
  //                     type="radio"
  //                     id="community-checkbox64"
  //                     className="custom-checkbox"
  //                   />
  //                   <label htmlFor="community-checkbox64">
  //                     Yes. If yes, please ensure a Client referral and consent
  //                     from is complete.
  //                   </label>
  //                 </Col>
  //                 <Col md={4} className="pl-3">
  //                   {this.props.stateDiversion.isReferral && (
  //                     <Button
  //                       type="button"
  //                       size="sm"
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       onClick={(e) =>
  //                         this.setState({ show: true, isUpdate: true })
  //                       }
  //                       variant="outline-danger"
  //                       className="btn btn-sm mt-2"
  //                     >
  //                       {" "}
  //                       <EditIcon></EditIcon>{" "}
  //                     </Button>
  //                   )}
  //                 </Col>
  //               </Row>
  //             </Col>
  //           </Row>
  //         )}
  //         {this.props.clientTab && (
  //           <Row className="p-2 ">
  //             <Col md={6}>
  //               <Row>
  //                 <Col md={8}>
  //                   <Form.Label className="text-label">
  //                     Estimated time spent (minutes)
  //                   </Form.Label>
  //                 </Col>
  //               </Row>
  //             </Col>
  //             <Col md={6}>
  //               <Row>
  //                 <Col md={5}>
  //                   <Form.Control
  //                     value={this.props.stateDiversion.estimatedTime}
  //                     name="estimatedTime"
  //                     onChange={(e) => this.props.onChange(e)}
  //                     type="number"
  //                     className="form-fields pt-0"
  //                     placeholder="Estimated Time"
  //                   />
  //                 </Col>
  //               </Row>
  //             </Col>
  //           </Row>
  //         )}

  //         <Row>
  //           {this.props.clientTab && (
  //             <>
  //               <Col md={12} className="p-2">
  //                 <Row className="row d-flex align-items-center">
  //                   <Col md={4} className="pl-3">
  //                     <Form.Label className="text-label">
  //                       Has the client collected their personal belongings?
  //                     </Form.Label>
  //                   </Col>
  //                   <Col md={2}>
  //                     <Form.Control
  //                       checked={
  //                         this.props.stateDiversion.isBelongingTaken == 1
  //                       }
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       value={1}
  //                       name="isBelongingTaken"
  //                       onChange={(e) => this.props.onChange(e, "check")}
  //                       type="radio"
  //                       id="community-checkbox61"
  //                       className="custom-checkbox"
  //                     />
  //                     <label htmlFor="community-checkbox61">Yes</label>
  //                   </Col>
  //                   <Col md={2}>
  //                     <Form.Control
  //                       checked={
  //                         this.props.stateDiversion.isBelongingTaken == 2
  //                       }
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       value={2}
  //                       name="isBelongingTaken"
  //                       onChange={(e) => this.props.onChange(e, "check")}
  //                       type="radio"
  //                       id="community-checkbox62"
  //                       className="custom-checkbox"
  //                     />
  //                     <label htmlFor="community-checkbox62">No</label>
  //                   </Col>
  //                   <Col md={2}>
  //                     <Form.Control
  //                       checked={
  //                         this.props.stateDiversion.isBelongingTaken == 3
  //                       }
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       value={3}
  //                       name="isBelongingTaken"
  //                       onChange={(e) => this.props.onChange(e, "check")}
  //                       type="radio"
  //                       id="community-checkbox263"
  //                       className="custom-checkbox"
  //                     />
  //                     <label htmlFor="community-checkbox263">N/A</label>
  //                   </Col>
  //                 </Row>
  //               </Col>
  //             </>
  //           )}
  //           <Col md={12}>
  //             <Row className="p-2 bg-grey">
  //               {this.props.clientTab && (
  //                 <Col md={4}>
  //                   <Row>
  //                     <Col md={12}>
  //                       <Form.Label className="text-label">
  //                         How did the client leave the service?
  //                       </Form.Label>
  //                     </Col>
  //                   </Row>
  //                 </Col>
  //               )}
  //               {this.props.clientTab && (
  //                 <>
  //                   <Col md={4}>
  //                     <Form.Control
  //                       checked={this.props.stateDiversion.isLeave == 1}
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       value={1}
  //                       onChange={(e) => this.props.onChange(e, "check")}
  //                       name="isLeave"
  //                       type="radio"
  //                       id="community-checkbox65"
  //                       className="custom-checkbox"
  //                     />
  //                     <label htmlFor="community-checkbox65">
  //                       Transported to a safe place (please advise the location)
  //                     </label>
  //                   </Col>
  //                   <Col md={4}>
  //                     <Form.Group controlId="exampleForm.ControlTextarea1">
  //                       <LocationSearchInput
  //                         address={this.props.stateDiversion.leaveComment1}
  //                         onSelect={(address) =>
  //                           this.props.onChange({
  //                             target: { name: "leaveComment1", value: address },
  //                           })
  //                         }
  //                       ></LocationSearchInput>
  //                     </Form.Group>
  //                   </Col>
  //                   <Col md={12}>
  //                     <Form.Control
  //                       checked={this.props.stateDiversion.isLeave == 2}
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       value={2}
  //                       onChange={(e) => this.props.onChange(e, "check")}
  //                       name="isLeave"
  //                       type="radio"
  //                       id="community-checkbox66"
  //                       className="custom-checkbox"
  //                     />
  //                     <label htmlFor="community-checkbox66">
  //                       Transported via Ambulance
  //                     </label>
  //                   </Col>

  //                   <Col md={12}>
  //                     <Form.Control
  //                       checked={this.props.stateDiversion.isLeave == 5}
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       value={5}
  //                       onChange={(e) => this.props.onChange(e, "check")}
  //                       name="isLeave"
  //                       type="radio"
  //                       id="community-checkbox68"
  //                       className="custom-checkbox"
  //                     />
  //                     <label htmlFor="community-checkbox68">
  //                       Picked up by QPS
  //                     </label>
  //                   </Col>
  //                   <Col md={12}>
  //                     <Form.Control
  //                       checked={this.props.stateDiversion.isLeave == 4}
  //                       disabled={this.props.stateDiversion.isCheckout}
  //                       value={4}
  //                       onChange={(e) => this.props.onChange(e, "check")}
  //                       name="isLeave"
  //                       type="radio"
  //                       id="community-checkbox69"
  //                       className="custom-checkbox"
  //                     />
  //                     <label htmlFor="community-checkbox69">
  //                       Other (please provide details)
  //                     </label>
  //                   </Col>
  //                   <Col md={12}>
  //                     <Form.Group controlId="exampleForm.ControlTextarea1">
  //                       <CharacterCounter>
  //                         <Form.Control
  //                           value={this.props.stateDiversion.leaveComment2}
  //                           disabled={this.props.stateDiversion.isCheckout}
  //                           onChange={(e) => this.props.onChange(e)}
  //                           name="leaveComment2"
  //                           as="textarea"
  //                           rows={2}
  //                           maxLength={255}
  //                         />
  //                       </CharacterCounter>
  //                     </Form.Group>
  //                   </Col>
  //                 </>
  //               )}
  //               {!this.props.stateDiversion.isCheckout && (
  //                 <Col md={12}>
  //                   <div className="btn-sec full-width form-btn text-right">
  //                     {this.props.isSaveButtons && (
  //                       <div className="btn-sec  form-btn">
  //                         <button
  //                           type="submit"
  //                           className="main-btn default-btn btn btn-primary"
  //                         >
  //                           Check In
  //                         </button>
  //                       </div>
  //                     )}
  //                     {!this.props.isSaveButtons && (
  //                       <div className="btn-sec full-width form-btn text-right">
  //                         <button
  //                           type="submit"
  //                           className="main-btn default-btn btn btn-primary mr-2"
  //                         >
  //                           {" "}
  //                           Save{" "}
  //                         </button>
  //                         <button
  //                           type="button"
  //                           onClick={(e) =>
  //                             this.props.onhandleOnSubmit(e, "checkOut")
  //                           }
  //                           className="main-btn default-btn btn btn-primary"
  //                         >
  //                           {" "}
  //                           Checkout{" "}
  //                         </button>
  //                       </div>
  //                     )}
  //                   </div>
  //                 </Col>
  //               )}
  //             </Row>
  //           </Col>
  //         </Row>
  //         <Row>
  //           <Col md={12}>
  //             <Modal
  //               className="form-consent"
  //               show={this.state.show}
  //               // onHide={close}
  //               aria-labelledby="contained-modal-title"
  //             >
  //               <Modal.Body>
  //                 <Container>
  //                   <Row className="d-flex align-items-center">
  //                     <Col md={12}>
  //                       <div className="form-consent-heading">
  //                         <Form.Label className="text-label">
  //                           Referral Form
  //                         </Form.Label>
  //                       </div>
  //                     </Col>
  //                     <Col md={12}>
  //                       <div className="form-consent-body">
  //                         <p className="form-para">
  //                           Please make sure to consider that consent can only
  //                           be obtained if{" "}
  //                           <small>(All options must be ticked)</small>:
  //                         </p>
  //                       </div>
  //                     </Col>
  //                     <Col md={12}>
  //                       <Form.Control
  //                         checked={this.props.stateDiversion.thingsToConsider}
  //                         value={!this.props.stateDiversion.thingsToConsider}
  //                         onChange={(e) => this.props.onChange(e, "check")}
  //                         name="thingsToConsider"
  //                         type="checkbox"
  //                         id="community-checkbox71"
  //                         className="custom-checkbox"
  //                       />
  //                       <label htmlFor="community-checkbox71">
  //                         The Client is sober and not intoxicated
  //                       </label>
  //                     </Col>
  //                     <Col md={12}>
  //                       <Form.Control
  //                         checked={this.props.stateDiversion.thingsToConsider2}
  //                         value={!this.props.stateDiversion.thingsToConsider2}
  //                         onChange={(e) => this.props.onChange(e, "check")}
  //                         name="thingsToConsider2"
  //                         type="checkbox"
  //                         id="community-checkbox72"
  //                         className="custom-checkbox"
  //                       />
  //                       <label htmlFor="community-checkbox72">
  //                         The Client is sound mind and their decision-making is
  //                         not impaired
  //                       </label>
  //                     </Col>
  //                     <Col md={12}>
  //                       <Form.Control
  //                         checked={this.props.stateDiversion.thingsToConsider3}
  //                         value={!this.props.stateDiversion.thingsToConsider3}
  //                         onChange={(e) => this.props.onChange(e, "check")}
  //                         name="thingsToConsider3"
  //                         type="checkbox"
  //                         id="community-checkbox73"
  //                         className="custom-checkbox"
  //                       />
  //                       <label htmlFor="community-checkbox73">
  //                         The Client has provided verbal consent
  //                       </label>
  //                     </Col>
  //                     <Col md={12}>
  //                       <div className="form-consent-heading pt-3">
  //                         <Form.Label className="text-label">
  //                           <small>The Client Agrees That:</small>
  //                         </Form.Label>
  //                       </div>
  //                     </Col>
  //                     <Col md={12}>
  //                       <div className="form-consent-body">
  //                         <Form.Control
  //                           checked={this.props.stateDiversion.agreeFor}
  //                           disabled={this.props.stateDiversion.isCheckout}
  //                           value={!this.props.stateDiversion.agreeFor}
  //                           onChange={(e) => this.props.onChange(e, "check")}
  //                           name="agreeFor"
  //                           type="checkbox"
  //                           id="community-checkbox70"
  //                           className="custom-checkbox"
  //                         />
  //                         <label htmlFor="community-checkbox70">
  //                           My personal details may be used so that the
  //                           following additional support can be provided to me:
  //                         </label>
  //                       </div>
  //                     </Col>
  //                     <Col md={4}>
  //                       <div className="form-consent-body">
  //                         <Form.Control
  //                           checked={
  //                             this.props.stateDiversion.isReferralSupport
  //                           }
  //                           value={!this.props.stateDiversion.isReferralSupport}
  //                           onChange={(e) => this.props.onChange(e, "check")}
  //                           name="isReferralSupport"
  //                           type="checkbox"
  //                           id="community-checkbox169"
  //                           className="custom-checkbox"
  //                         />
  //                         <label htmlFor="community-checkbox169">
  //                           A referral to a support service
  //                         </label>
  //                       </div>
  //                     </Col>
  //                     <Col md={4}>
  //                       <Autocomplete
  //                         className="select-form-consent"
  //                         id="filter-demo"
  //                         options={this.state.contacts}
  //                         getOptionLabel={(option) =>
  //                           `${option.firstName}  ${option.lastName}(${option.organizationName})`
  //                         }
  //                         // onInputChange={(event, newInputValue) => this.handleFilter(event, newInputValue)}
  //                         onChange={this.contactChange}
  //                         defaultValue={this.state.contacts.find(
  //                           (elem) =>
  //                             elem.id === this.props.stateDiversion.referrelId
  //                         )}
  //                         disabled={
  //                           !this.props.stateDiversion.isReferralSupport
  //                         }
  //                         style={{ width: 300 }}
  //                         renderInput={(params) => (
  //                           <TextField
  //                             {...params}
  //                             label="Contact Form"
  //                             variant="outlined"
  //                           />
  //                         )}
  //                       />
  //                     </Col>
  //                     <Col md={12} className="pl-0">
  //                       <div className="form-consent-body detail-form-consent mt-2">
  //                         <CharacterCounter>
  //                           <Form.Control
  //                             disabled={
  //                               !this.props.stateDiversion.isReferralSupport ||
  //                               this.props.stateDiversion.isCheckout
  //                             }
  //                             value={
  //                               this.props.stateDiversion.isReferralSupport &&
  //                               this.props.stateDiversion.agreeForDetail3
  //                                 ? this.props.stateDiversion.agreeForDetail3
  //                                 : ""
  //                             }
  //                             onChange={(e) => this.props.onChange(e)}
  //                             name="agreeForDetail3"
  //                             as="textarea"
  //                             rows={3}
  //                             maxLength={255}
  //                           />
  //                         </CharacterCounter>
  //                       </div>
  //                     </Col>
  //                   </Row>

  //                   <Row className="d-flex align-items-center pt-2">
  //                     <Col md={12}>
  //                       <Button
  //                         onClick={this.handleClose}
  //                         className="main-btn default-btn btn-right"
  //                       >
  //                         {" "}
  //                         Close
  //                       </Button>

  //                       <Button
  //                         onClick={this.handleSave}
  //                         className="main-btn default-btn btn-right"
  //                       >
  //                         {" "}
  //                         Save
  //                       </Button>
  //                     </Col>
  //                   </Row>
  //                 </Container>
  //               </Modal.Body>
  //             </Modal>
  //           </Col>
  //         </Row>
  //       </CustomForm>
  //     </div>
  //   );
  // }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        backgroundColor: "transparent",
        border: "none",
      }),
      indicatorsContainer: (base) => ({
        display: "none",
      }),
      contentComponent: (base) => ({
        ...base,
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
      }),
    };

    const checkBoxStyles = {
      appearance: "none",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "#f4f4f4",
    };

    const customCheckBoxStyles = {
      appearance: "none",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "#f4f4f4",
    };

    return (
      <div className="new-community diversion full-width">
        <CustomForm
          ref={(c) => {
            this.form = c;
          }}
          onSubmit={(e) => this.props.onhandleOnSubmit(e)}
        >
          {/* <Row className="p-2">
            <Col md={4}>
              <Form.Label className="text-label">
                How did the person access the service?
              </Form.Label>
            </Col>
            <Col md={8}>
              <Select
                className="selection-select arrow-down custom-width"
                placeholder=""
                options={CLIENT_ACCESS}
                values={[
                  ...CLIENT_ACCESS.filter(
                    (elem) =>
                      elem.value ===
                      (this.props.stateDiversion.accessComment || "")
                  ),
                ]}
                name="accessComment"
                onChange={(e) => this.props.handleDropDown(e, "accessComment")}
              />
            </Col>
          </Row> */}

          <div
            className={Styles.person_block}
            style={{ backgroundColor: "#fff" }}
          >
            <div className={`${Styles.form_group}`}>
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-5">
                  <label>How did the person access the service?</label>
                </div>
                <div className="col-lg-7 col-md-7">
                  <Select
                    className={`${Styles.form_select} ${Styles.select_dropdown}`}
                    values={[
                      ...CLIENT_ACCESS.filter(
                        (elem) =>
                          elem.value ===
                          (this.props.stateDiversion.accessComment || "")
                      ),
                    ]}
                    name="accessComment"
                    onChange={(e) =>
                      this.props.handleDropDown(e, "accessComment")
                    }
                    options={CLIENT_ACCESS}
                    placeholder=""
                    style={{
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      backgroundImage: "none",
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <Row className="p-2">
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Breaking the Cycle Participant?{" "}
                  </Form.Label>
                </Col>
                <Col md={3}>
                  <Form.Control
                    checked={!this.props.stateDiversion.isBreakingTheCycle}
                    value={false}
                    disabled={this.props.stateDiversion.isCheckout}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isBreakingTheCycle"
                    type="radio"
                    id="community-checkbox49"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox49">No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Form.Control
                    checked={this.props.stateDiversion.isBreakingTheCycle}
                    value={true}
                    disabled={this.props.stateDiversion.isCheckout}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isBreakingTheCycle"
                    type="radio"
                    id="community-checkbox50"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox50">Yes</label>
                </Col>
              </Row>
            </Col>
          </Row> */}

            <div className={`${Styles.form_group}`}>
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-5">
                  <label>Breaking the Cycle Participant?</label>
                </div>
                <div className="col-lg-7 col-md-7">
                  <div className={Styles.radio_custom}>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                      style={{ marginRight: "1rem" }}
                    >
                      <input
                        // className={`${Styles.form_check_input} form-check-input`}
                        className={`${Styles.form_check_input} form-check-input ${Styles.final_checkbox}`}
                        checked={!this.props.stateDiversion.isBreakingTheCycle}
                        value={false}
                        id="community-checkbox49"
                        disabled={this.props.stateDiversion.isCheckout}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isBreakingTheCycle"
                        type="radio"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox49"
                      >
                        No
                      </label>
                    </div>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        type="radio"
                        checked={this.props.stateDiversion.isBreakingTheCycle}
                        value={true}
                        disabled={this.props.stateDiversion.isCheckout}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isBreakingTheCycle"
                        id="community-checkbox50"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox50"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Row className="p-2 ">
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">Bed Number</Form.Label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={5}>
                  <Form.Control
                    value={this.props.stateDiversion.bedNumber}
                    name="bedNumber"
                    onChange={(e) => this.props.onChange(e)}
                    type="number"
                    className="form-fields pt-0"
                    placeholder="Bed Number"
                    min={0}
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}

            <div className={`${Styles.form_group}`}>
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-5">
                  <label>Bed Number</label>
                </div>
                <div
                  className="col-xl-3 col-lg-4 col-md-5"
                  style={{ paddingLeft: "12px", paddingRight: "12px" }}
                >
                  <input
                    className={Styles.form_control}
                    type="number"
                    id="bed-number"
                    style={{ width: "100%" }}
                    value={this.props.stateDiversion.bedNumber}
                    name="bedNumber"
                    onChange={(e) => this.props.onChange(e)}
                    placeholder="Bed Number"
                    min={0}
                  />
                </div>
              </div>
            </div>

            {/* <Row className="p-2">
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Has the client been involved in any altercations immediately
                    prior to their arrival at the Diversion Centre?
                  </Form.Label>
                </Col>
                <Col md={3}>
                  <Form.Control
                    checked={!this.props.stateDiversion.isAlter}
                    value={false}
                    disabled={this.props.stateDiversion.isCheckout}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isAlter"
                    type="radio"
                    id="community-checkbox51"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox51">No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Form.Control
                    checked={this.props.stateDiversion.isAlter}
                    value={true}
                    disabled={this.props.stateDiversion.isCheckout}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isAlter"
                    type="radio"
                    id="community-checkbox52"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox52">
                    Yes. If yes, provide details below.
                  </label>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <CharacterCounter>
                      <Form.Control
                        disabled={
                          !this.props.stateDiversion.isAlter ||
                          this.props.stateDiversion.isCheckout
                        }
                        value={this.props.stateDiversion.alterComment}
                        onChange={(e) => this.props.onChange(e)}
                        name="alterComment"
                        as="textarea"
                        rows={2}
                        maxLength={255}
                      />
                    </CharacterCounter>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row> */}

            <div className={Styles.form_group}>
              <div className="row">
                <div className="col-lg-5 col-md-5">
                  <label>
                    Has the client been involved in any altercations immediately
                    prior to their arrival at the Diversion Centre?
                  </label>
                </div>
                <div className="col-lg-7 col-md-7">
                  <div className={Styles.radio_custom}>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        checked={!this.props.stateDiversion.isAlter}
                        value={false}
                        disabled={this.props.stateDiversion.isCheckout}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isAlter"
                        type="radio"
                        id="community-checkbox51"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox51"
                      >
                        No
                      </label>
                    </div>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        checked={this.props.stateDiversion.isAlter}
                        value={true}
                        disabled={this.props.stateDiversion.isCheckout}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isAlter"
                        type="radio"
                        id="community-checkbox52"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox52"
                      >
                        Yes, If yes, provide details below.
                      </label>
                    </div>
                  </div>
                  <div className="mt-13" style={{ marginTop: "13px" }}>
                    <textarea
                      className={Styles.form_control}
                      style={{ width: "100%" }}
                      disabled={
                        !this.props.stateDiversion.isAlter ||
                        this.props.stateDiversion.isCheckout
                      }
                      value={this.props.stateDiversion.alterComment}
                      onChange={(e) => this.props.onChange(e)}
                      name="alterComment"
                      as="textarea"
                      rows={2}
                      maxLength={255}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            {/* <Row className="d-flex align-items-center p-2">
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Does the client have any weapons, illicit substances or
                    alcohol in their possession?{" "}
                  </Form.Label>
                </Col>
                <Col md={4}>
                  <Form.Control
                    checked={
                      !this.props.stateDiversion.isIllicitSubstancesFound
                    }
                    disabled={this.props.stateDiversion.isCheckout}
                    value={false}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isIllicitSubstancesFound"
                    type="radio"
                    id="community-checkbox160"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox160">No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Form.Control
                    checked={this.props.stateDiversion.isIllicitSubstancesFound}
                    disabled={this.props.stateDiversion.isCheckout}
                    value={true}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isIllicitSubstancesFound"
                    type="radio"
                    id="community-checkbox161"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox161">Yes</label>
                </Col>
              </Row>
            </Col>

            <Col
              style={
                this.props.isIllicitSubstancesFoundCheck
                  ? { marginTop: "1rem" }
                  : {}
              }
              md={12}
            >
              <>
                {(this.props.isIllicitSubstancesFoundCheck ||
                  this.props.stateDiversion.isIllicitSubstancesFound) && (
                  <Row className="p-2">
                    <Col md={6}>
                      <Row>
                        <Col md={8}>
                          <Form.Label className="text-label">
                            Have the items been confiscated?{" "}
                          </Form.Label>
                        </Col>
                        <Col md={3}>
                          <Form.Control
                            checked={
                              !this.props.stateDiversion
                                .isIllicitSubstancesConfiscated
                            }
                            value={false}
                            disabled={this.props.stateDiversion.isCheckout}
                            onChange={(e) => this.props.onChange(e, "check")}
                            name="isIllicitSubstancesConfiscated"
                            type="radio"
                            id="community-checkbox162"
                            className="custom-checkbox"
                          />
                          <label htmlFor="community-checkbox162">No</label>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <Form.Control
                            checked={
                              this.props.stateDiversion
                                .isIllicitSubstancesConfiscated
                            }
                            value={true}
                            disabled={this.props.stateDiversion.isCheckout}
                            onChange={(e) => this.props.onChange(e, "check")}
                            name="isIllicitSubstancesConfiscated"
                            type="radio"
                            id="community-checkbox158"
                            className="custom-checkbox"
                          />
                          <label htmlFor="community-checkbox158">Yes</label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            </Col>
          </Row> */}

            <div className={Styles.form_group}>
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-5 col-md-5">
                  <label>
                    Does the client have any weapons, illicit substances or
                    alcohol in their possession?
                  </label>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-7">
                  <div className={Styles.radio_custom}>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        checked={
                          !this.props.stateDiversion.isIllicitSubstancesFound
                        }
                        disabled={this.props.stateDiversion.isCheckout}
                        value={false}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isIllicitSubstancesFound"
                        type="radio"
                        id="community-checkbox160"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox160"
                      >
                        No
                      </label>
                    </div>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        checked={
                          this.props.stateDiversion.isIllicitSubstancesFound
                        }
                        disabled={this.props.stateDiversion.isCheckout}
                        value={true}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isIllicitSubstancesFound"
                        type="radio"
                        id="community-checkbox161"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox161"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {(this.props.isIllicitSubstancesFoundCheck ||
              this.props.stateDiversion.isIllicitSubstancesFound) && (
                <div className={`${Styles.form_group} mb-0`}>
                  <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-5 col-md-5">
                      <label>Have the items have been confiscated?</label>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-7">
                      <div className={Styles.radio_custom}>
                        <div
                          className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                        >
                          <input
                            className={`${Styles.form_check_input} form-check-input`}
                            checked={
                              !this.props.stateDiversion
                                .isIllicitSubstancesConfiscated
                            }
                            value={false}
                            disabled={this.props.stateDiversion.isCheckout}
                            onChange={(e) => this.props.onChange(e, "check")}
                            name="isIllicitSubstancesConfiscated"
                            type="radio"
                            id="community-checkbox162"
                            style={checkBoxStyles}
                          />
                          <label
                            className={Styles.form_check_label}
                            style={{ marginTop: "0", marginBottom: "0" }}
                            htmlFor="community-checkbox162"
                          >
                            No
                          </label>
                        </div>
                        <div
                          className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                        >
                          <input
                            className={`${Styles.form_check_input} form-check-input`}
                            checked={
                              this.props.stateDiversion
                                .isIllicitSubstancesConfiscated
                            }
                            value={true}
                            disabled={this.props.stateDiversion.isCheckout}
                            onChange={(e) => this.props.onChange(e, "check")}
                            name="isIllicitSubstancesConfiscated"
                            type="radio"
                            id="community-checkbox158"
                            style={checkBoxStyles}
                          />
                          <label
                            className={Styles.form_check_label}
                            style={{ marginTop: "0", marginBottom: "0" }}
                            htmlFor="community-checkbox158"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <hr />

            <ViolanceForm
              patrol={this.props.stateDiversion}
              {...this.props}
            ></ViolanceForm>

            {/* <div className={`${Styles.form_group} mb-0`}>
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-5 col-md-5">
                  <label>
                    Are there any “No contact” Domestic Violence orders in
                    place?
                  </label>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-7 d-flex align-items-center flex-wrap">
                  <div className={Styles.radio_custom}>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        type="radio"
                        name="radio-violence"
                        id="no-violence"
                        value="No"
                        checked
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                      >
                        No
                      </label>
                    </div>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        type="radio"
                        name="radio-violence"
                        id="yes-violence"
                        value="Yes"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                  <div className={`${Styles.no_contact_block}`}>
                    <input
                      className={`${Styles.form_control} ${Styles.form_small}`}
                      type="text"
                      placeholder="Contact Name"
                    />
                    <a
                      href="#"
                      className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
                    >
                      Add More
                    </a>
                  </div>
                </div>
              </div>
            </div> */}

            <hr />

            <div className={`${Styles.form_group} mb-0`}>
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-5 col-md-5">
                  <label htmlFor="">
                    Does the client have any known medical conditions?
                  </label>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-7">
                  <div className={`${Styles.radio_custom} d-sm-flex`}>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        checked={!this.props.stateDiversion.isMedical}
                        disabled={this.props.stateDiversion.isCheckout}
                        value={false}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isMedical"
                        type="radio"
                        id="community-checkbox155"
                        style={checkBoxStyles}
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox155"
                      >
                        No
                      </label>
                    </div>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        checked={this.props.stateDiversion.isMedical}
                        disabled={this.props.stateDiversion.isCheckout}
                        value={true}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isMedical"
                        type="radio"
                        id="community-checkbox156"
                        style={checkBoxStyles}
                      />
                      <label
                        // className="form-check-label mt-0"
                        className={Styles.form_check_label}
                        // style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox156"
                        style={{ width: "fit-content" }}
                      >
                        Yes, If yes, you must complete the Client needs and risk
                        identification form.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(this.props.isMedicalCheck ||
            this.props.stateDiversion.isMedical) && (
              <ClientMedicalRiskForm
                disabled={this.props.stateDiversion.isCheckout}
                medObs={this.props.stateDiversion}
                onFieldChange={this.props.onChange}
              ></ClientMedicalRiskForm>
            )}

          {/* <div className={Styles.heading}>
            <h5 style={{ marginBottom: "0" }}>
              Observations of Client Wellbeing
            </h5>
          </div>

          <div className={Styles.clientWellbeing_block}>
            <div className={`${Styles.table_responsive} table-responsive`}>
              <table className={Styles.table_noBg}>
                <thead>
                  <tr>
                    <th>Did you check on them?</th>
                    <th>Were they awake or asleep?</th>
                    <th>Status Change?</th>
                    <th>
                      Comments <span>(Mandatory when status changed)</span>
                    </th>
                    <th>Staff member</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      9/08/22 <span>12:42</span>
                    </td>
                    <td>
                      <select name="" id="" className={Styles.form_select}>
                        <option value="">Awake</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select>
                    </td>
                    <td>
                      <select name="" id="" className={Styles.form_select}>
                        <option value="">No</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select>
                    </td>
                    <td>
                      <select name="" id="" className={Styles.form_select}>
                        <option value="">Status Comments Here...</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select>
                    </td>
                    <td>lorem ipsum dolor sit </td>
                  </tr>
                  <tr>
                    <td>
                      9/08/22 <span>12:42</span>
                    </td>
                    <td>
                      <select name="" id="" className={Styles.form_select}>
                        <option value="">Awake</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select>
                    </td>
                    <td>
                      <select name="" id="" className={Styles.form_select}>
                        <option value="">No</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select>
                    </td>
                    <td>
                      <select name="" id="" className={Styles.form_select}>
                        <option value="">Status Comments Here...</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select>
                    </td>
                    <td>lorem ipsum dolor sit </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="">
              <a
                href="#"
                className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
              >
                Add More
              </a>
            </div>
            <h6 style={{ fontSize: "18px", fontWeight: "600" }}>
              Client Background Information
            </h6>
            <textarea
              className={Styles.form_control}
              style={{ width: "100%" }}
              id=""
              rows="5"
            ></textarea>
            <div className="text-end" style={{ textAlign: "right" }}>
              <a
                href="#"
                className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow} ${Styles.mb_0}`}
                style={{ marginBottom: "0" }}
              >
                Add More
              </a>
            </div>
          </div> */}

          {this.props.clientTab ? (
            <>
              {/* <Row> */}
              <ClientWellBeingForm
                medObs={this.props.stateDiversion}
                disabled={this.props.stateDiversion.isCheckout}
                onFieldChange={this.props.onChange}
              ></ClientWellBeingForm>
              {/* </Row> */}
              {/* <Row className="d-flex align-items-center p-2"> */}
              <ClientObservationForm
                medObs={this.props.stateDiversion}
                disabled={this.props.stateDiversion.isCheckout}
                {...this.props}
              ></ClientObservationForm>
              {/* </Row> */}
            </>
          ) : null}

          <div
            className={Styles.clientWellbeing_block}
            style={{ paddingTop: "0", backgroundColor: "#fff" }}
          >
            <h6 style={{ fontSize: "18px", fontWeight: "600" }}>
              Client Background Information
            </h6>
            {/* <textarea
              className={Styles.form_control}
              style={{ width: "100%" }}
              id=""
              rows="5"
            ></textarea>
            <div className="text-end" style={{ textAlign: "right" }}>
              <a
                href="#"
                className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow} ${Styles.mb_0}`}
                style={{ marginBottom: "0" }}
              >
                Add More
              </a>
            </div> */}
            <BackgroundInformation
              stateDiversion={this.props.stateDiversion}
              {...this.props}
            ></BackgroundInformation>
          </div>

          {this.props.clientTab && (
            <div className={Styles.heading}>
              <h5 style={{ marginBottom: "0" }}>Client exit Details</h5>
            </div>
          )}
          {this.props.clientTab && (
            <div
              className={Styles.clientDetails_block}
              style={{ backgroundColor: "#fff" }}
            >
              <div className={Styles.form_group}>
                <div className="row align-items-center">
                  <div className="col-lg-5 col-md-5">
                    <label htmlFor="">
                      Has a referral been made on behalf of the client
                    </label>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className={`${Styles.radio_custom} d-sm-flex`}>
                      <div
                        className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                      >
                        <input
                          className={`${Styles.form_check_input} form-check-input`}
                          style={checkBoxStyles}
                          checked={!this.props.stateDiversion.isReferral}
                          disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                          value={false}
                          onChange={(e) => {
                            this.props.onChange(e, "check");
                            this.setState({ show: false });
                          }}
                          name="isReferral"
                          type="radio"
                          id="community-checkbox63"
                        />
                        <label
                          className={Styles.form_check_label}
                          style={{ marginTop: "0", marginBottom: "0" }}
                          htmlFor="community-checkbox63"
                        >
                          No
                        </label>
                      </div>
                      <div
                        className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                      >
                        <input
                          className={`${Styles.form_check_input} form-check-input`}
                          style={checkBoxStyles}
                          checked={this.props.stateDiversion.isReferral}
                          value={true}
                          disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                          onChange={(e) => {
                            this.props.onChange(e, "check");
                            this.setState({ show: true });
                          }}
                          name="isReferral"
                          type="radio"
                          id="community-checkbox64"
                        />
                        <label
                          className={Styles.form_check_label}
                          style={{ marginTop: "0", marginBottom: "0" }}
                          htmlFor="community-checkbox64"
                        >
                          Yes, If yes, Please ensure a Client referral and
                          consent from is complete.
                        </label>
                      </div>
                      {this.props.stateDiversion.isReferral && (
                        <button
                          disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                          onClick={(e) => {
                            this.setState({ show: true, isUpdate: true });
                            e.preventDefault();
                          }}
                          className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
                          variant="outline-danger"
                        >
                          {" "}
                          <EditIcon
                            style={{ fontSize: "24px" }}
                          ></EditIcon>{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={Styles.form_group}>
                <div className="row align-items-center">
                  <div className="col-lg-5 col-md-5">
                    <label htmlFor="">Estimated time spent (minutes)</label>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className={`${Styles.radio_custom} d-sm-flex`}>
                      <div
                        className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                      >
                        <input
                          className={Styles.form_control}
                          style={{ width: "100%" }}
                          min={0}
                          value={this.props.stateDiversion.estimatedTime}
                          name="estimatedTime"
                          onChange={(e) => this.props.onChange(e)}
                          type="number"
                          placeholder="Estimated Time"
                        />
                         */}
              {/* <label
                          className={Styles.form_check_label}
                          style={{ marginTop: "0", marginBottom: "0" }}
                          htmlFor="community-checkbox63"
                        >
                          No
                        </label> */}
              {/* </div> */}
              {/* <div
                        className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                      >
                        <input
                          className={`${Styles.form_check_input} form-check-input`}
                          style={checkBoxStyles}
                          checked={this.props.stateDiversion.isReferral}
                          value={true}
                          disabled={this.props.stateDiversion.isCheckout}
                          onChange={(e) => {
                            this.props.onChange(e, "check");
                            this.setState({ show: true });
                          }}
                          name="isReferral"
                          type="radio"
                          id="community-checkbox64"
                        />
                        <label
                          className={Styles.form_check_label}
                          style={{ marginTop: "0", marginBottom: "0" }}
                          htmlFor="community-checkbox64"
                        >
                          Yes, If yes, Please ensure a Client referral and
                          consent from is complete.
                        </label>
                      </div> */}
              {/* {this.props.stateDiversion.isReferral && (
                        <button
                          disabled={this.props.stateDiversion.isCheckout}
                          onClick={(e) =>
                            this.setState({ show: true, isUpdate: true })
                          }
                          className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
                          variant="outline-danger"
                        >
                          {" "}
                          <EditIcon
                            style={{ fontSize: "24px" }}
                          ></EditIcon>{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className={Styles.personalBelongings_block}>
                <div className={Styles.form_group}>
                  <label htmlFor="">
                    Has the client collected their personal belongings?
                  </label>
                  <div className={Styles.radio_custom}>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        style={checkBoxStyles}
                        checked={
                          this.props.stateDiversion.isBelongingTaken == 2
                        }
                        disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                        value={2}
                        name="isBelongingTaken"
                        onChange={(e) => this.props.onChange(e, "check")}
                        type="radio"
                        id="community-checkbox62"
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox62"
                      >
                        No
                      </label>
                    </div>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        style={checkBoxStyles}
                        checked={
                          this.props.stateDiversion.isBelongingTaken == 1
                        }
                        disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                        value={1}
                        name="isBelongingTaken"
                        onChange={(e) => this.props.onChange(e, "check")}
                        type="radio"
                        id="community-checkbox61"
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox61"
                      >
                        Yes
                      </label>
                    </div>
                    <div
                      className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                    >
                      <input
                        className={`${Styles.form_check_input} form-check-input`}
                        style={checkBoxStyles}
                        checked={
                          this.props.stateDiversion.isBelongingTaken == 3
                        }
                        disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                        value={3}
                        name="isBelongingTaken"
                        onChange={(e) => this.props.onChange(e, "check")}
                        type="radio"
                        id="community-checkbox263"
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginTop: "0", marginBottom: "0" }}
                        htmlFor="community-checkbox263"
                      >
                        N/A
                      </label>
                    </div>
                  </div>
                </div>
                <div className={`${Styles.form_group} fw-600`}>
                  <label htmlFor="">
                    How did the person access the service?
                  </label>
                  <div className={`fw-semi ${Styles.checkbox_mrg}`}>
                    <div
                      className={Styles.form_check}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        // className={`${Styles.form_check_input} form-check-input`}
                        className={`${Styles.customCheckBox}`}
                        // style={checkBoxStyles}
                        style={{
                          ...checkBoxStyles,
                          marginLeft: "-27px",
                          marginRight: "7px",
                        }}
                        checked={this.props.stateDiversion.isLeave == 1}
                        disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                        value={1}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox65"
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginBottom: "0" }}
                        htmlFor="community-checkbox65"
                      >
                        Transported to a safe place (please advise the location)
                      </label>
                    </div>
                    <textarea
                      className={Styles.form_control}
                      style={{ width: "100%" }}
                      id=""
                      value={this.props.stateDiversion.leaveComment1}
                      disabled={this.props.stateDiversion.isLeave !== 1}
                      onChange={(e) => this.props.onChange(e)}
                      name="leaveComment1"
                      as="textarea"
                      rows={2}
                      maxLength={255}
                    ></textarea>
                    <div
                      className={Styles.form_check}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        // className={`${Styles.form_check_input} form-check-input`}
                        className={`${Styles.customCheckBox}`}
                        // style={checkBoxStyles}
                        style={{
                          ...checkBoxStyles,
                          marginLeft: "-27px",
                          marginRight: "7px",
                        }}
                        checked={this.props.stateDiversion.isLeave == 2}
                        disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                        value={2}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox66"
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginBottom: "0" }}
                        htmlFor="community-checkbox66"
                      >
                        Transported via Ambulance
                      </label>
                    </div>
                    <div
                      className={Styles.form_check}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        // className={`${Styles.form_check_input} form-check-input`}
                        className={`${Styles.customCheckBox}`}
                        // style={checkBoxStyles}
                        style={{
                          ...checkBoxStyles,
                          marginLeft: "-27px",
                          marginRight: "7px",
                        }}
                        checked={this.props.stateDiversion.isLeave == 5}
                        disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                        value={5}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox68"
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginBottom: "0" }}
                        htmlFor="qps-personal"
                      >
                        Picked up by QPS
                      </label>
                    </div>
                    <div
                      className={Styles.form_check}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        // className={`${Styles.form_check_input} form-check-input`}
                        className={`${Styles.customCheckBox}`}
                        // style={checkBoxStyles}
                        style={{
                          ...checkBoxStyles,
                          marginLeft: "-27px",
                          marginRight: "7px",
                        }}
                        checked={this.props.stateDiversion.isLeave == 4}
                        disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                        value={4}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox69"
                      />
                      <label
                        className={Styles.form_check_label}
                        style={{ marginBottom: "0" }}
                        htmlFor="community-checkbox69"
                      >
                        Picked up by Other (please provide details)
                      </label>
                    </div>
                  </div>
                </div>
                <textarea
                  className={Styles.form_control}
                  style={{ width: "100%" }}
                  id=""
                  value={this.props.stateDiversion.leaveComment2}
                  disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                  onChange={(e) => this.props.onChange(e)}
                  name="leaveComment2"
                  as="textarea"
                  rows={2}
                  maxLength={255}
                ></textarea>
              </div>
            </div>
          )}
          {((!this.props.stateDiversion.isCheckout) || (this.state.user.role && this.state.user.role === "Admin")) && (
            <div
              className={Styles.person_block}
              style={{ backgroundColor: "#fff" }}
            >
              <div className="text-end" style={{ textAlign: "right" }}>
                {!this.props.isSaveButtons && (
                  <label class="mb-0 d-block">
                    Last modified :{" "}
                    <Moment format={DATE_FORMAT}>
                      {this.props.stateDiversion.updatedAt}
                    </Moment>
                    <label>:</label>
                    <Moment format={TIME_FORMAT}>
                      {this.props.stateDiversion.updatedAt}
                    </Moment>
                  </label>
                )}
                {!this.props.isSaveButtons && (
                  <label class="d-block">
                    Modified By : {" "} 
                    {this.props.stateDiversion.updatedDiversion ? this.props.stateDiversion.updatedDiversion.firstName : this.props.stateDiversion.createdDiversion.firstName}
                    {" "} 
                    {this.props.stateDiversion.updatedDiversion ? this.props.stateDiversion.updatedDiversion.lastName : this.props.stateDiversion.createdDiversion.lastName}
                  </label>
                )}
              </div>
              <div className="text-end" style={{ textAlign: "right" }}>
                {this.props.isSaveButtons && (
                  <button
                    type="submit"
                    className={`${Styles.btn} ${Styles.btn_red}`}
                  >
                    Check In
                  </button>
                )}
                {!this.props.isSaveButtons && (
                  <>
                    <button
                      className={`${Styles.btn} ${Styles.btn_yellow}`}
                      type="submit"
                    >
                      Save
                    </button>
                    {!this.props.stateDiversion.isCheckout && <button
                      className={`${Styles.btn} ${Styles.btn_red}`}
                      type="button"
                      onClick={(e) => {
                        this.props.onhandleOnSubmit(e, "checkOut");
                      }}
                    >
                      Checkout
                    </button>
                    }
                  </>
                )}
              </div>
            </div>
          )}
          {/* <Row className="d-flex align-items-center  p-2">
            <ViolanceForm
              patrol={this.props.stateDiversion}
              {...this.props}
            ></ViolanceForm>
          </Row> */}

          {/* <Row className="bg-grey d-flex align-items-center p-2">
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Does the client have any known medical conditions?{" "}
                  </Form.Label>
                </Col>
                <Col md={4}>
                  <Form.Control
                    checked={!this.props.stateDiversion.isMedical}
                    disabled={this.props.stateDiversion.isCheckout}
                    value={false}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isMedical"
                    type="radio"
                    id="community-checkbox155"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox155">No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Form.Control
                    checked={this.props.stateDiversion.isMedical}
                    disabled={this.props.stateDiversion.isCheckout}
                    value={true}
                    onChange={(e) => this.props.onChange(e, "check")}
                    name="isMedical"
                    type="radio"
                    id="community-checkbox156"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox156">
                    Yes. If yes, you must complete the Client needs and risk
                    identification form
                  </label>
                </Col>
              </Row>
            </Col>

            <Col
              style={
                this.props.isMedicalCheck
                  ? // ? { border: "1px solid black", marginTop: "1rem" }
                    {}
                  : {}
              }
              md={12}
            >
              <>
                {(this.props.isMedicalCheck ||
                  this.props.stateDiversion.isMedical) && (
                  <ClientMedicalRiskForm
                    disabled={this.props.stateDiversion.isCheckout}
                    medObs={this.props.stateDiversion}
                    onFieldChange={this.props.onChange}
                  ></ClientMedicalRiskForm>
                )}
              </>
            </Col>
          </Row> */}
          {/* {this.props.clientTab ? (
            <>
              <Row>
                <ClientWellBeingForm
                  medObs={this.props.stateDiversion}
                  disabled={this.props.stateDiversion.isCheckout}
                  onFieldChange={this.props.onChange}
                ></ClientWellBeingForm>
              </Row>
              <Row className="d-flex align-items-center p-2">
                <ClientObservationForm
                  medObs={this.props.stateDiversion}
                  disabled={this.props.stateDiversion.isCheckout}
                  {...this.props}
                ></ClientObservationForm>
              </Row>
            </>
          ) : null} */}

          {/* <Row className="d-flex align-items-center p-2">
            <Col md={12}>
              <div className="client-record pl-0">
                <h6>CLIENT BACKGROUND INFORMATION</h6>
              </div>
            </Col>
            <Col md={12}>
              <BackgroundInformation
                stateDiversion={this.props.stateDiversion}
                {...this.props}
              ></BackgroundInformation>
            </Col>
          </Row> */}

          {/* <Row className="pl-2">
            <Col md={12}>
              <div className="client-record pl-0">
                {this.props.clientTab && <h6>CLIENT EXIT DETAILS</h6>}
              </div>
            </Col>
          </Row>
          {this.props.clientTab && (
            <Row className="p-2 bg-grey">
              <Col md={6}>
                <Row>
                  <Col md={8}>
                    <Form.Label className="text-label">
                      Has a referral been made on behalf of the client{" "}
                    </Form.Label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      checked={!this.props.stateDiversion.isReferral}
                      disabled={this.props.stateDiversion.isCheckout}
                      value={false}
                      onChange={(e) => {
                        this.props.onChange(e, "check");
                        this.setState({ show: false });
                      }}
                      name="isReferral"
                      type="radio"
                      id="community-checkbox63"
                      className="custom-checkbox"
                    />
                    <label htmlFor="community-checkbox63">No</label>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={8}>
                    <Form.Control
                      checked={this.props.stateDiversion.isReferral}
                      value={true}
                      disabled={this.props.stateDiversion.isCheckout}
                      onChange={(e) => {
                        this.props.onChange(e, "check");
                        this.setState({ show: true });
                      }}
                      name="isReferral"
                      type="radio"
                      id="community-checkbox64"
                      className="custom-checkbox"
                    />
                    <label htmlFor="community-checkbox64">
                      Yes. If yes, please ensure a Client referral and consent
                      from is complete.
                    </label>
                  </Col>
                  <Col md={4} className="pl-3">
                    {this.props.stateDiversion.isReferral && (
                      <Button
                        type="button"
                        size="sm"
                        disabled={this.props.stateDiversion.isCheckout}
                        onClick={(e) =>
                          this.setState({ show: true, isUpdate: true })
                        }
                        variant="outline-danger"
                        className="btn btn-sm mt-2"
                      >
                        {" "}
                        <EditIcon></EditIcon>{" "}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {this.props.clientTab && (
            <Row className="p-2 ">
              <Col md={6}>
                <Row>
                  <Col md={8}>
                    <Form.Label className="text-label">
                      Estimated time spent (minutes)
                    </Form.Label>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={5}>
                    <Form.Control
                      value={this.props.stateDiversion.estimatedTime}
                      name="estimatedTime"
                      onChange={(e) => this.props.onChange(e)}
                      type="number"
                      className="form-fields pt-0"
                      placeholder="Estimated Time"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )} */}

          {/* <Row>
            {this.props.clientTab && (
              <>
                <Col md={12} className="p-2">
                  <Row className="row d-flex align-items-center">
                    <Col md={4} className="pl-3">
                      <Form.Label className="text-label">
                        Has the client collected their personal belongings?
                      </Form.Label>
                    </Col>
                    <Col md={2}>
                      <Form.Control
                        checked={
                          this.props.stateDiversion.isBelongingTaken == 1
                        }
                        disabled={this.props.stateDiversion.isCheckout}
                        value={1}
                        name="isBelongingTaken"
                        onChange={(e) => this.props.onChange(e, "check")}
                        type="radio"
                        id="community-checkbox61"
                        className="custom-checkbox"
                      />
                      <label htmlFor="community-checkbox61">Yes</label>
                    </Col>
                    <Col md={2}>
                      <Form.Control
                        checked={
                          this.props.stateDiversion.isBelongingTaken == 2
                        }
                        disabled={this.props.stateDiversion.isCheckout}
                        value={2}
                        name="isBelongingTaken"
                        onChange={(e) => this.props.onChange(e, "check")}
                        type="radio"
                        id="community-checkbox62"
                        className="custom-checkbox"
                      />
                      <label htmlFor="community-checkbox62">No</label>
                    </Col>
                    <Col md={2}>
                      <Form.Control
                        checked={
                          this.props.stateDiversion.isBelongingTaken == 3
                        }
                        disabled={this.props.stateDiversion.isCheckout}
                        value={3}
                        name="isBelongingTaken"
                        onChange={(e) => this.props.onChange(e, "check")}
                        type="radio"
                        id="community-checkbox263"
                        className="custom-checkbox"
                      />
                      <label htmlFor="community-checkbox263">N/A</label>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            <Col md={12}>
              <Row className="p-2 bg-grey">
                {this.props.clientTab && (
                  <Col md={4}>
                    <Row>
                      <Col md={12}>
                        <Form.Label className="text-label">
                          How did the client leave the service?
                        </Form.Label>
                      </Col>
                    </Row>
                  </Col>
                )}
                {this.props.clientTab && (
                  <>
                    <Col md={4}>
                      <Form.Control
                        checked={this.props.stateDiversion.isLeave == 1}
                        disabled={this.props.stateDiversion.isCheckout}
                        value={1}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox65"
                        className="custom-checkbox"
                      />
                      <label htmlFor="community-checkbox65">
                        Transported to a safe place (please advise the location)
                      </label>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <LocationSearchInput
                          address={this.props.stateDiversion.leaveComment1}
                          onSelect={(address) =>
                            this.props.onChange({
                              target: { name: "leaveComment1", value: address },
                            })
                          }
                        ></LocationSearchInput>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Control
                        checked={this.props.stateDiversion.isLeave == 2}
                        disabled={this.props.stateDiversion.isCheckout}
                        value={2}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox66"
                        className="custom-checkbox"
                      />
                      <label htmlFor="community-checkbox66">
                        Transported via Ambulance
                      </label>
                    </Col>

                    <Col md={12}>
                      <Form.Control
                        checked={this.props.stateDiversion.isLeave == 5}
                        disabled={this.props.stateDiversion.isCheckout}
                        value={5}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox68"
                        className="custom-checkbox"
                      />
                      <label htmlFor="community-checkbox68">
                        Picked up by QPS
                      </label>
                    </Col>
                    <Col md={12}>
                      <Form.Control
                        checked={this.props.stateDiversion.isLeave == 4}
                        disabled={this.props.stateDiversion.isCheckout}
                        value={4}
                        onChange={(e) => this.props.onChange(e, "check")}
                        name="isLeave"
                        type="radio"
                        id="community-checkbox69"
                        className="custom-checkbox"
                      />
                      <label htmlFor="community-checkbox69">
                        Other (please provide details)
                      </label>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <CharacterCounter>
                          <Form.Control
                            value={this.props.stateDiversion.leaveComment2}
                            disabled={this.props.stateDiversion.isCheckout}
                            onChange={(e) => this.props.onChange(e)}
                            name="leaveComment2"
                            as="textarea"
                            rows={2}
                            maxLength={255}
                          />
                        </CharacterCounter>
                      </Form.Group>
                    </Col>
                  </>
                )}
                {!this.props.stateDiversion.isCheckout && (
                  <Col md={12}>
                    <div className="btn-sec full-width form-btn text-right">
                      {this.props.isSaveButtons && (
                        <div className="btn-sec  form-btn">
                          <button
                            type="submit"
                            className="main-btn default-btn btn btn-primary"
                          >
                            Check In
                          </button>
                        </div>
                      )}
                      {!this.props.isSaveButtons && (
                        <div className="btn-sec full-width form-btn text-right">
                          <button
                            type="submit"
                            className="main-btn default-btn btn btn-primary mr-2"
                          >
                            {" "}
                            Save{" "}
                          </button>
                          <button
                            type="button"
                            onClick={(e) =>
                              this.props.onhandleOnSubmit(e, "checkOut")
                            }
                            className="main-btn default-btn btn btn-primary"
                          >
                            {" "}
                            Checkout{" "}
                          </button>
                        </div>
                      )}
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
              <Modal
                className="form-consent"
                show={this.state.show}
                // onHide={close}
                aria-labelledby="contained-modal-title"
              >
                <Modal.Body>
                  <Container>
                    <Row className="d-flex align-items-center">
                      <Col md={12}>
                        <div className="form-consent-heading">
                          <Form.Label className="text-label">
                            Referral Form
                          </Form.Label>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-consent-body">
                          <p className="form-para">
                            Please make sure to consider that consent can only
                            be obtained if{" "}
                            <small>(All options must be ticked)</small>:
                          </p>
                        </div>
                      </Col>
                      <Col md={12} className="mb-1">
                        {/* <Form.Control
                          checked={this.props.stateDiversion.thingsToConsider}
                          value={!this.props.stateDiversion.thingsToConsider}
                          onChange={(e) => this.props.onChange(e, "check")}
                          name="thingsToConsider"
                          type="checkbox"
                          id="community-checkbox71"
                          className="custom-checkbox"
                        /> */}
                        <input
                          className={`${Styles.form_check_input} form-check-input my-0`}
                          style={checkBoxStyles}
                          checked={this.props.stateDiversion.thingsToConsider}
                          value={!this.props.stateDiversion.thingsToConsider}
                          onChange={(e) => this.props.onChange(e, "check")}
                          name="thingsToConsider"
                          type="checkbox"
                          id="community-checkbox71"
                        />
                        <label htmlFor="community-checkbox71" className="my-0">
                          The Client is sober and not intoxicated
                        </label>
                      </Col>
                      <Col md={12} className="mb-1">
                        <input
                          checked={this.props.stateDiversion.thingsToConsider2}
                          value={!this.props.stateDiversion.thingsToConsider2}
                          onChange={(e) => this.props.onChange(e, "check")}
                          name="thingsToConsider2"
                          type="checkbox"
                          id="community-checkbox72"
                          // className="custom-checkbox"
                          className={`${Styles.form_check_input} form-check-input my-0`}
                          style={customCheckBoxStyles}
                        />
                        <label htmlFor="community-checkbox72" className="my-0">
                          The Client is sound mind and their decision-making is
                          not impaired
                        </label>
                      </Col>
                      <Col md={12} className="mb-1">
                        <input
                          checked={this.props.stateDiversion.thingsToConsider3}
                          value={!this.props.stateDiversion.thingsToConsider3}
                          onChange={(e) => this.props.onChange(e, "check")}
                          name="thingsToConsider3"
                          type="checkbox"
                          id="community-checkbox73"
                          // className="custom-checkbox"
                          className={`${Styles.form_check_input} form-check-input my-0`}
                          style={checkBoxStyles}
                        />
                        <label htmlFor="community-checkbox73" className="my-0">
                          The Client has provided verbal consent
                        </label>
                      </Col>
                      <Col md={12}>
                        <div className="form-consent-heading pt-3">
                          <Form.Label className="text-label">
                            <small>The Client Agrees That:</small>
                          </Form.Label>
                        </div>
                      </Col>
                      <Col md={12} className="mb-1">
                        <div className="form-consent-body">
                          <input
                            checked={this.props.stateDiversion.agreeFor}
                            disabled={this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin")}
                            value={!this.props.stateDiversion.agreeFor}
                            onChange={(e) => this.props.onChange(e, "check")}
                            name="agreeFor"
                            type="checkbox"
                            id="community-checkbox70"
                            // className="custom-checkbox"
                            className={`${Styles.form_check_input} form-check-input my-0`}
                            style={checkBoxStyles}
                          />
                          <label htmlFor="community-checkbox70" className="my-0">
                            My personal details may be used so that the
                            following additional support can be provided to me:
                          </label>
                        </div>
                      </Col>
                      <Col md={4} className="mb-1">
                        <div className="form-consent-body">
                          <input
                            checked={
                              this.props.stateDiversion.isReferralSupport
                            }
                            value={!this.props.stateDiversion.isReferralSupport}
                            onChange={(e) => this.props.onChange(e, "check")}
                            name="isReferralSupport"
                            type="checkbox"
                            id="community-checkbox169"
                            // className="custom-checkbox"
                            className={`${Styles.form_check_input} form-check-input my-0`}
                            style={checkBoxStyles}
                          />
                          <label htmlFor="community-checkbox169" className="my-0">
                            A referral to a support service
                          </label>
                        </div>
                      </Col>
                      <Col md={4}>
                        <Autocomplete
                          className="select-form-consent"
                          id="filter-demo"
                          options={this.state.contacts}
                          getOptionLabel={(option) =>
                            `${option.firstName}  ${option.lastName}(${option.organizationName})`
                          }
                          // onInputChange={(event, newInputValue) => this.handleFilter(event, newInputValue)}
                          onChange={this.contactChange}
                          defaultValue={this.state.contacts.find(
                            (elem) =>
                              elem.id === this.props.stateDiversion.referrelId
                          )}
                          disabled={
                            !this.props.stateDiversion.isReferralSupport
                          }
                          style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contact Form"
                              variant="outlined"
                            />
                          )}
                        />
                      </Col>
                      <Col md={12} className="pl-0">
                        <div className="form-consent-body detail-form-consent mt-2">
                          <CharacterCounter>
                            <Form.Control
                              disabled={
                                !this.props.stateDiversion.isReferralSupport ||
                                (this.props.stateDiversion.isCheckout && (!this.state.user.role || this.state.user.role !== "Admin"))
                              }
                              value={
                                this.props.stateDiversion.isReferralSupport &&
                                  this.props.stateDiversion.agreeForDetail3
                                  ? this.props.stateDiversion.agreeForDetail3
                                  : ""
                              }
                              onChange={(e) => this.props.onChange(e)}
                              name="agreeForDetail3"
                              as="textarea"
                              rows={3}
                              maxLength={255}
                            />
                          </CharacterCounter>
                        </div>
                      </Col>
                    </Row>

                    <Row
                      className="d-flex align-items-center pt-2"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {/* <Col md={12}>
                        <Button
                          onClick={this.handleClose}
                          className="main-btn default-btn btn-right"
                        >
                          {" "}
                          Close
                        </Button>

                        <Button
                          onClick={this.handleSave}
                          className="main-btn default-btn btn-right"
                        >
                          {" "}
                          Save
                        </Button>
                      </Col> */}
                      <div className="text-end" style={{ textAlign: "right" }}>
                        <button
                          className={`${Styles.btn} ${Styles.btn_yellow}`}
                          onClick={this.handleClose}
                        >
                          Close
                        </button>
                        <button
                          className={`${Styles.btn} ${Styles.btn_red}`}
                          type="button"
                          onClick={this.handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </Row>
                  </Container>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
        </CustomForm>
      </div>
    );
  }
}

export default UserDetailDiversion;
