import React from "react";
import clsx from "clsx";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Search from "../../components/search/search";
import Select from "react-dropdown-select";

import { connect } from "react-redux";
import store from "../../store";
import ActiveUserCommunity from "../../components/diversion-centre/active-user-community";
import TableHeadDiversion from "./table-head-diversion";
import UserDetailDiversion from "../../components/diversion-centre/user-detail-diversion";

import Styles from "../shared/css/default.module.css";

import {
  CreateDiversionCenterAction,
  updateDiversionCenterAction,
  getSingleDiversionCenter
} from "../../actions/diversion-center";
import {
  IS_CLICKED_DIVERSION,
  SEARCH_CLIENT_RES,
  DIVERSION_SEARCH_RESULT,
  DIVERSION_CLIENT,
  DIVERSION_TAB,
  DIVERSIONS,
  DIVERSION_SAVE,
  IS_MEDICAL_OBSERVATION,
  IS_ILLICIT_SUBSTANCE,
  MEDICAL_OBSERVATION,
  FULL_TIME_FORMAT,
  CLIENT_OBSERVATION,
  IS_CLIENT_OBSERVATION,
  IS_CLIENT_BACKGROUND,
  CLIENT_BACKGROUND
} from "../../helpers/constants";
import "./diversion-centre.scss";
import moment from "moment";
import { SearchClientsAction } from "../../actions/client-action";
import { toast, getUser } from "../../helpers/utility";
import { GetContactsAction } from "../../actions/contact-action";
import { GetLocationAction } from "../../actions/location-action";

const className = 'DiversionCentreForm';

class DiversionCentreForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "actives",
      isSaveButtons: false,
      selectedClientId: "",
      search: "",
      isMedicalCheck: false,
      isIllicitSubstancesFoundCheck: false,
      locations: [],
      concentClickedData: {
        thingsToConsider: false,
        thingsToConsider2: false,
        thingsToConsider3: false,
      },
      diversionClickedData: {
        isAlter: false,
        // isBelonging: false,
        isBreakingTheCycle: false,
        isMedical: false,
        isIllicitSubstancesFound: false,
        isIllicitSubstancesConfiscated: false,
        isArrangements: 0,
        isAggression: false,
        isAssistance: false,
        isReferral: false,
        isLeave: 0,
        bedNumber: 0,
        estimatedTime: 0,
        isAmbulance: false,
        isOnMedication: false,
        thingsToConsider: false,
        thingsToConsider2: false,
        thingsToConsider3: false,
        medicationName: "",
        medicationDate: "",
        medicationTime: "",
      },
      activeTab: 1,
    };
    this.searchChanged = this.searchChanged.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleDropDown = this.handleDropDown.bind(this);
    this.tabChangeHandler = this.tabChangeHandler.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangeConcent = this.onChangeConcent.bind(this);
  }

  componentDidMount() {
    const signature = className + '.componentDidMount: ';
    // If true, allow a client to be automatically selected
    let selectClient = false;
    if (this.props.match.params.key) {
      this.tabChangeHandler(this.props.match.params.key);
      if (this.props.match.params.key == "new") {
        console.log(signature + `Opening Create Form`);
        this.handleTab(2);
        selectClient = true;
      }
      if (this.props.match.params.key == "edit" && this.props.match.params.id) {
        console.log(signature + `Opening Edit Form`);
        // Triggers a DIVERSION_SAVE, which writes to props.diversion
        store.dispatch(getSingleDiversionCenter(this.props.match.params.id));
      }
    }

    if (selectClient && this.props.match.params.id && this.props.searchClients.length > 0) {
      console.log(signature + `Setting Selected Client[${this.props.match.params.id}]`);
      this.setState({ selectedClientId: this.props.match.params.id });
      setTimeout(() => {
        this.onRowSelect(false, this.props.match.params.id);
      }, 1000);
    } else {
      store.dispatch(SearchClientsAction(`from=diversion&query=&page=1`));
    }

    if (this.props.locations && this.props.locations.length > 0) {
      let locations = this.props.locations.filter(
        (elem) => elem.diversionCenter
      );
      console.log("Updated Diversion Centre Locations", locations);
      this.setState({
        locations: locations.map(
          (elem) =>
            (elem = { label: elem.name, value: elem.id, name: elem.name })
        ),
      });
    }

    store.dispatch(GetLocationAction());
    store.dispatch(GetContactsAction());
  }

  handleSelect(key) {
    this.setState({ key: key });
  }

  onClose() {
    let data = this.state.diversionClickedData;
    data.isReferral = false;
    this.setState({ diversionClickedData: data });
  }

  onChangeConcent(e, loc, id) {
    let concentClickedData = this.state.concentClickedData;
    concentClickedData[e.target.name] = eval(e.target.value);
    this.setState({ concentClickedData });
  }

  onSave() {
    this.setState({
      diversionClickedData: {
        ...this.state.diversionClickedData,
        ...this.state.concentClickedData,
      },
    });
  }

  onRowSelect(e, id) {
    const signature = className + '.onRowSelect: ';
    console.log(signature + `Selecting Client[${this.props.match.params.id}]`);
    this.setState({ selectedClientId: id });
    let selectedClient = this.props.searchClients.filter(
      (elem) => elem.id === id
    );

    if (
      selectedClient?.length > 0 &&
      selectedClient[0].DiversionCenters.length > 0
    ) {
      this.setState(
        {
          diversionClickedData: {
            ...selectedClient[0].DiversionCenters[0],
            Client: {
              id: selectedClient[0].id,
              name: selectedClient[0].name,
              firstName: selectedClient[0].firstName,
              lastName: selectedClient[0].lastName,
              dob: selectedClient[0].dob,
              phone: selectedClient[0].phone,
            },
          },
          isSaveButtons: false,
        },
        () => {
          store.dispatch({
            type: DIVERSION_TAB,
            payload: { ...this.state.diversionClickedData },
          });
          store.dispatch({ type: IS_CLICKED_DIVERSION, payload: true });

          this.tabChangeHandler("client");
          this.handleTab("3");
        }
      );
    } else {
      let diversionClickedData = {};
      diversionClickedData["clientId"] = id;
      diversionClickedData["timeIn"] = new Date();
      this.setState({ diversionClickedData, isSaveButtons: true }, () => {
        store.dispatch({ type: IS_CLICKED_DIVERSION, payload: true });
      });
    }
  }

  handleOnSubmit(e, flag) {
    const signature = className + '.handleOnSubmit: ';
    e.preventDefault();
    var diversionClickedData = this.state.diversionClickedData;
    diversionClickedData["serviceLocation"] = getUser().location;
    if (this.props.isMedicalObservation) {
      diversionClickedData["medicalObservation"] =
        this.props.medicalObservations;
    } else {
      diversionClickedData["medicalObservation"] = [];
    }
    if (this.props.wellbiengObservations) {
      diversionClickedData["wellbiengObservation"] =
        this.props.wellbiengObservations;
    } else {
      diversionClickedData["wellbiengObservation"] = [];
    }
    if (this.props.domesticViolance) {
      diversionClickedData["domesticViolance"] = this.props.domesticViolance;
    } else {
      diversionClickedData["domesticViolance"] = [];
    }
    if (this.props.clientBackgrounds) {
      let checkEmptyObj = [];
      this.props.clientBackgrounds.map((obj) => {
        if (obj.information && obj.information !== "") {
          checkEmptyObj.push(obj);
        }
      });
      diversionClickedData["clientBackgrounds"] = checkEmptyObj;
    } else {
      diversionClickedData["clientBackgrounds"] = [];
    }
    if (this.props.clientObservations) {
      diversionClickedData["ClientObservationForms"] =
        this.props.clientObservations;
    } else {
      toast("Please provide CLIENT OBSERVATION RECORD", "error");
      return false;
    }
    if (
      diversionClickedData.isAlter &&
      (!diversionClickedData.alterComment ||
        diversionClickedData.alterComment == "")
    ) {
      toast("Please provide  altercations prior comments", "error");
      return false;
    } else {
      if (!diversionClickedData.isAlter) {
        diversionClickedData["alterComment"] = "";
        this.setState({ diversionClickedData });
      }
    }
    if (
      diversionClickedData.isOnMedication &&
      (!diversionClickedData.medicationName ||
        diversionClickedData.medicationName == "")
    ) {
      toast("Please provide Medication name", "error");
      return false;
    } else {
      if (!diversionClickedData.isOnMedication) {
        diversionClickedData["medicationName"] = "";
        this.setState({ diversionClickedData });
      }
    }
    if (flag === "checkOut") {
      if (
        diversionClickedData.isLeave === 1 &&
        (!diversionClickedData.leaveComment1 ||
          diversionClickedData.leaveComment1 == "")
      ) {
        console.log(signature + 'Validation Failed on LeaveComment1');
        toast("Please indicate the safe location", "error");
        return false;
      } else {
        if (
          diversionClickedData.isLeave === 4 ||
          diversionClickedData.isLeave === 3 ||
          diversionClickedData.isLeave === 2
        ) {
          diversionClickedData["leaveComment1"] = "";
          this.setState({ diversionClickedData });
        }
      }
      if (
        diversionClickedData.isLeave === 4 &&
        (!diversionClickedData.leaveComment2 ||
          diversionClickedData.leaveComment2 == "")
      ) {
        toast("Please provide the details", "error");
        return false;
      } else {
        if (
          diversionClickedData.isLeave === 1 ||
          diversionClickedData.isLeave === 3 ||
          diversionClickedData.isLeave === 2
        ) {
          diversionClickedData["leaveComment2"] = "";
          this.setState({ diversionClickedData });
        }
      }
    }
    if (flag === "checkOut" && !diversionClickedData.isLeave) {
      toast("Please provide client leave service", "error");
      return false;
    }
    // if (
    //   flag === "checkOut" &&
    //   (!diversionClickedData.estimatedTime ||
    //     diversionClickedData.estimatedTime < 1)
    // ) {
    //   toast("Please provide client Estimated Time", "error");
    //   return false;
    // }
    if (flag === "checkOut") {
      var startTime = this.props.diversionTab.timeIn;
      console.log("Start Time:- ", startTime);
      var endTime = moment(new Date());
      var duration = moment.duration(endTime.diff(startTime));
      var minutes = duration.asMinutes();
      var finalMinutes = Math.round(minutes);
      diversionClickedData["estimatedTime"] = finalMinutes;
    }
    if (!diversionClickedData.isReferral) {
      diversionClickedData["agreeFor"] = false;
      diversionClickedData["isReferralSupport"] = false;
      diversionClickedData["agreeForDetail1"] = "";
      diversionClickedData["agreeForDetail2"] = "";
      diversionClickedData["agreeForDetail3"] = "";
      diversionClickedData["agreeForService"] = 0;
      diversionClickedData["clientName"] = "";
      diversionClickedData["thingsToConsider"] = false;
      diversionClickedData["thingsToConsider2"] = false;
      diversionClickedData["thingsToConsider3"] = false;
    }

    if (!diversionClickedData.accessComment) {
      toast("Please indicate how the client accessed the service", "error");
      return false;
    }

    if (
      diversionClickedData.ClientObservationForms &&
      diversionClickedData.ClientObservationForms.length
    ) {
      //
      let obForm = diversionClickedData.ClientObservationForms;
      let valid = true;
      obForm.map((elem) => {
        if (
          elem.clientStatusChange == 1 &&
          (!elem.statusComments || elem.statusComments == "")
        ) {
          valid = false;
        }
      });

      if (!valid) {
        toast("Please provide CLIENT OBSERVATION RECORD comments", "error");
        return false;
      }
    }

    if (!this.state.isSaveButtons) {
      // return false;
      if (flag == "checkOut") {
        diversionClickedData["isCheckout"] = true;
        diversionClickedData["checkoutAt"] = new Date();
        store.dispatch(updateDiversionCenterAction(diversionClickedData));
      } else {
        store.dispatch(updateDiversionCenterAction(diversionClickedData));
      }
      // store.dispatch({ type: PETROL_CLIENT, payload: {} })
    } else {
      // return false;
      store.dispatch(CreateDiversionCenterAction(diversionClickedData));
    }
    this.setState({
      diversionClickedData: {
        isAlter: false,
        // isBelonging: false,
        isBreakingTheCycle: false,
        isMedical: false,
        isIllicitSubstancesFound: false,
        isIllicitSubstancesConfiscated: false,
        isAssistance: false,
        isViolence: false,
        isReferral: false,
        bedNumber: 0,
        estimatedTime: 0,
        isLeave: 0,
        isAmbulance: false,
        isArrangements: 0,
        isAggression: false,
        isOnMedication: false,
        medicationName: "",
        medicationDate: "",
        medicationTime: "",
      },
    });
    store.dispatch({ type: MEDICAL_OBSERVATION, payload: [] });
    store.dispatch({ type: CLIENT_OBSERVATION, payload: [] });
    store.dispatch({ type: CLIENT_BACKGROUND, payload: [] });
    store.dispatch({ type: IS_MEDICAL_OBSERVATION, payload: false });
    // store.dispatch({ type: IS_ILLICIT_SUBSTANCE, payload: false });
    store.dispatch({ type: IS_CLICKED_DIVERSION, payload: false });
    store.dispatch({ type: IS_CLIENT_BACKGROUND, payload: false });
    store.dispatch({ type: IS_CLIENT_OBSERVATION, payload: false });
    store.dispatch({ type: DIVERSION_TAB, payload: {} });
    this.setState({ isSaveButtons: false });
  }

  handleDropDown(value, name) {
    let division = this.state.diversionClickedData;
    if (value[0]) {
      division[name] = value[0].value;
      this.setState({ diversionClickedData: division });
    }
  }

  handleTab(e) {
    const signature = className + '.handleTab: ';
    switch (e) {
      case "1":
        break;
      case "2":
        if (
          !this.props.match.params.id &&
          this.props.searchClients.length > 1
        ) {
          store.dispatch({ type: IS_CLICKED_DIVERSION, payload: false });
        }

        break;
      case "3":
        break;
      default:
        break;
    }

    console.log(signature + `Setting ActiveTab[${e}]`);
    this.setState({ activeTab: e });
  }

  onChange(e, loc, id) {
    let diversionClickedData = this.state.diversionClickedData;
    if (loc === "check") {
      diversionClickedData[e.target.name] = eval(e.target.value);
      if (e.target.name == "isMedical") {
        this.setState({ isMedicalCheck: eval(e.target.value) });
        store.dispatch({
          type: IS_MEDICAL_OBSERVATION,
          payload: eval(e.target.value),
        });
      }
    } else {
      diversionClickedData[e.target.name] = e.target.value;
    }
    if (loc === "check") {
      diversionClickedData[e.target.name] = eval(e.target.value);
      if (e.target.name == "isIllicitSubstancesFound") {
        this.setState({ isIllicitSubstancesFoundCheck: eval(e.target.value) });
        // store.dispatch({
        //   type: IS_ILLICIT_SUBSTANCE,
        //   payload: eval(e.target.value),
        // });
      }
    } else {
      diversionClickedData[e.target.name] = e.target.value;
    }
    this.setState({ diversionClickedData });
  }

  tabChangeHandler(key) {
    switch (key) {
      case "actives":
        break;
      case "new":
        if (
          !this.props.match.params.id &&
          this.props.searchClients.length > 1
        ) {
          store.dispatch({ type: IS_CLICKED_DIVERSION, payload: false });
        }

        break;
      case "client":
        break;
      default:
        break;
    }
    this.setState({ key });
  }

  componentDidUpdate(prevProp) {
    const signature = className + '.componentDidUpdate: ';

    if (this.props.newSearch !== prevProp.newSearch) {
      this.setState({ selectedClientId: "" });
    }
    if (
      this.props.newSearch !== prevProp.newSearch &&
      this.props.match.params.id
    ) {
    }
    if (
      this.props.diversionTab &&
      this.props.diversionTab !== prevProp.diversionTab
    ) {
      console.log(signature + `Upading DiversionClickedData`);
      this.setState({ diversionClickedData: this.props.diversionTab });
    }

    if (
      JSON.stringify(prevProp.diversion) !==
      JSON.stringify(this.props.diversion)
    ) {
      const fromId = prevProp.diversion ? prevProp.diversion.id : null;
      const toId = this.props.diversion ? this.props.diversion.id : null;
      console.log(signature + `Handling Change of Diversion From[${fromId}] To[${toId}]`);
      if (this.props.match.params.key == "edit" && this.props.match.params.id && toId && fromId !== toId) {
        console.log(signature + `Opening existing Diversion[${toId}]`);
        store.dispatch({
          type: DIVERSION_TAB,
          payload: { ...this.props.diversion },
        });
        this.setState({ activeTab: 3 });
      } else {
        if (toId) {
          console.log(signature + `Returning to Dashboard after Saving Diversion[${toId}]`);
          store.dispatch({
            type: DIVERSION_SAVE,
            payload: {},
          });
        } else {
          this.setState({ key: "actives" });
          this.setState({ activeTab: 1 });
        }
      }
    }

    if (
      JSON.stringify(prevProp.contacts) !== JSON.stringify(this.props.contacts)
    ) {
      console.log(signature + `Updating Contact List. Found Total[${this.props.contacts.length}] contacts`);
      this.setState({ contacts: this.props.contacts });
    }

    if (
      JSON.stringify(prevProp.locations) !==
      JSON.stringify(this.props.locations)
    ) {
      let locations = this.props.locations.filter(
        (elem) => elem.diversionCenter
      );
      console.log("Updated Diversion Centre Locations", locations);
      this.setState({
        locations: locations.map(
          (elem) =>
            (elem = { label: elem.name, value: elem.id, name: elem.name })
        ),
      });
    }
  }

  searchChanged(search) {
    this.setState({ search });
  }

  componentWillUnmount() {
    store.dispatch({
      type: DIVERSION_SAVE,
      payload: {},
    });
    store.dispatch({ type: IS_CLICKED_DIVERSION, payload: false });
    store.dispatch({ type: DIVERSION_TAB, payload: {} });
  }

  render() {
    // return (
    //   <div className="diversion-centre-section">
    //     <div className="tabs-section full-width mt-4">
    //       {/* <div className={`${Styles.ml_main} mt-4`}>
    //       <div className={Styles.bg_yellow}>
    //         <ul className={`nav ${Styles.nav_tabs}`} role="tablist">
    //           <li className={Styles.nav_item}>
    //             <a
    //               className={`nav-link ${Styles.active}`}
    //               data-bs-toggle="tab"
    //               href="#tab1"
    //             >
    //               Active
    //             </a>
    //           </li>
    //           <li className="nav-item">
    //             <a className="nav-link" data-bs-toggle="tab" href="#tab2">
    //               Add New
    //             </a>
    //           </li>
    //           <li className={Styles.nav_item}>
    //             <a
    //               className="nav-link active"
    //               data-bs-toggle="tab"
    //               href="#tab3"
    //             >
    //               Latoya Jackson
    //             </a>
    //           </li>
    //         </ul>
    //       </div> */}
    //       <Tabs
    //         defaultActiveKey="actives"
    //         activeKey={this.state.key}
    //         onSelect={(key) => this.tabChangeHandler(key)}
    //         id="uncontrolled-tab-example"
    //         // className={Styles.tab_container}
    //       >
    //         <Tab
    //           eventKey="actives"
    //           title={<span>Active </span>}
    //           // className="tab-table"
    //         >
    //           <Row>
    //             <Col md={12}>
    //               {this.state.key === "actives" && (
    //                 <ActiveUserCommunity
    //                   from={"diversion"}
    //                   setKey={(key) => this.setState({ key })}
    //                   {...this.props}
    //                 />
    //               )}
    //             </Col>
    //           </Row>
    //         </Tab>
    //         {/* <div className="bg-yellow ">
    //           <ul className="nav nav-tabs" role="tablist">
    //             <li className="nav-item">
    //               <a className="nav-link" data-bs-toggle="tab" href="#tab1">
    //                 Active
    //               </a>
    //             </li>
    //             <li className="nav-item">
    //               <a className="nav-link" data-bs-toggle="tab" href="#tab2">
    //                 Add New
    //               </a>
    //             </li>
    //             <li className="nav-item">
    //               <a
    //                 className="nav-link active"
    //                 data-bs-toggle="tab"
    //                 href="#tab3"
    //               >
    //                 Latoya Jackson
    //               </a>
    //             </li>
    //           </ul>
    //         </div> */}

    //         <Tab
    //           eventKey="new"
    //           title={<span>Add New</span>}
    //           className="tab-table comunity-user"
    //         >
    //           {this.state.key === "new" && (
    //             <Search
    //               from={"diversion"}
    //               searchChanged={this.searchChanged}
    //               {...this.props}
    //             />
    //           )}
    //           <Row>
    //             <Col md={12}>
    //               {this.state.key === "new" && (
    //                 <TableHeadDiversion
    //                   search={this.state.search}
    //                   isOnlyHead={this.props.isClickedDiversionClients}
    //                   handleDropDown={this.handleDropDown}
    //                   stateDiversion={this.state.diversionClickedData}
    //                   onChange={this.onChange}
    //                   clientId={this.state.selectedClientId}
    //                   onRowSelect={this.onRowSelect}
    //                   diversionLocations={this.state.locations || []}
    //                   {...this.props}
    //                 />
    //               )}
    //             </Col>
    //           </Row>
    //           <Row>
    //             <Col md={12}>
    //               {this.state.key === "new" &&
    //                 this.props.isClickedDiversionClients && (
    //                   <UserDetailDiversion
    //                     handleDropDown={this.handleDropDown}
    //                     isSaveButtons={this.state.isSaveButtons}
    //                     contacts={this.props.contacts}
    //                     concentClickedData={this.state.concentClickedData}
    //                     onSave={this.onSave}
    //                     onChangeConcent={this.onChangeConcent}
    //                     changeState={this.onClose}
    //                     isIllicitSubstancesFoundCheck={
    //                       this.state.isIllicitSubstancesFoundCheck
    //                     }
    //                     isMedicalCheck={this.state.isMedicalCheck}
    //                     stateDiversion={this.state.diversionClickedData}
    //                     onhandleOnSubmit={this.handleOnSubmit}
    //                     onChange={this.onChange}
    //                     {...this.props}
    //                   />
    //                 )}
    //             </Col>
    //           </Row>
    //         </Tab>
    //         {this.props.diversionTab.id && (
    //           <Tab
    //             eventKey="client"
    //             title={<span>{this.props.diversionTab.Client.name}</span>}
    //             className="tab-table comunity-user"
    //           >
    //             {/* {this.state.key === 'client' && <Search from={'diversion'} {...this.props} />} */}
    //             <Row>
    //               <Col md={12}>
    //                 {this.state.key === "client" && (
    //                   <TableHeadDiversion
    //                     clientTab={true}
    //                     isOnlyHead={this.props.isClickedDiversionClients}
    //                     handleDropDown={this.handleDropDown}
    //                     stateDiversion={this.props.diversionTab}
    //                     onChange={this.onChange}
    //                     onRowSelect={this.onRowSelect}
    //                     clientId={this.props.diversionTab.Client.id}
    //                     diversionLocations={this.state.locations}
    //                     {...this.props}
    //                   />
    //                 )}
    //               </Col>
    //             </Row>
    //             <Row>
    //               <Col md={12}>
    //                 {this.state.key === "client" && (
    //                   <UserDetailDiversion
    //                     clientTab={true}
    //                     handleDropDown={this.handleDropDown}
    //                     contacts={this.props.contacts}
    //                     isSaveButtons={false}
    //                     concentClickedData={this.state.concentClickedData}
    //                     onSave={this.onSave}
    //                     onChangeConcent={this.onChangeConcent}
    //                     isIllicitSubstancesFoundCheck={
    //                       this.state.isIllicitSubstancesFoundCheck
    //                     }
    //                     isMedicalCheck={this.state.isMedicalCheck}
    //                     changeState={this.onClose}
    //                     stateDiversion={this.props.diversionTab}
    //                     onhandleOnSubmit={this.handleOnSubmit}
    //                     onChange={this.onChange}
    //                     {...this.props}
    //                   />
    //                 )}
    //                 {/* <UserDetailCommunity {...this.props} /> */}
    //               </Col>
    //             </Row>
    //           </Tab>
    //         )}
    //       </Tabs>
    //     </div>
    //   </div>
    // );

    return (
      <div className="diversion-centre-section">
        {/* <div className="tabs-section full-width mt-4">
          <Tabs
            defaultActiveKey="actives"
            activeKey={this.state.key}
            onSelect={(key) => this.tabChangeHandler(key)}
            id="uncontrolled-tab-example"
            // className={Styles.tab_container}
          >
            <Tab
              eventKey="actives"
              title={<span>Active </span>}
              // className="tab-table"
            >
              <Row>
                <Col md={12}>
                  {this.state.key === "actives" && (
                    <ActiveUserCommunity
                      from={"diversion"}
                      setKey={(key) => this.setState({ key })}
                      {...this.props}
                    />
                  )}
                </Col>
              </Row>
            </Tab>

            <Tab
              eventKey="new"
              title={<span>Add New</span>}
              className="tab-table comunity-user"
            >
              {this.state.key === "new" && (
                <Search
                  from={"diversion"}
                  searchChanged={this.searchChanged}
                  {...this.props}
                />
              )}
              <Row>
                <Col md={12}>
                  {this.state.key === "new" && (
                    <TableHeadDiversion
                      search={this.state.search}
                      isOnlyHead={this.props.isClickedDiversionClients}
                      handleDropDown={this.handleDropDown}
                      stateDiversion={this.state.diversionClickedData}
                      onChange={this.onChange}
                      clientId={this.state.selectedClientId}
                      onRowSelect={this.onRowSelect}
                      diversionLocations={this.state.locations || []}
                      {...this.props}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {this.state.key === "new" &&
                    this.props.isClickedDiversionClients && (
                      <UserDetailDiversion
                        handleDropDown={this.handleDropDown}
                        isSaveButtons={this.state.isSaveButtons}
                        contacts={this.props.contacts}
                        concentClickedData={this.state.concentClickedData}
                        onSave={this.onSave}
                        onChangeConcent={this.onChangeConcent}
                        changeState={this.onClose}
                        isIllicitSubstancesFoundCheck={
                          this.state.isIllicitSubstancesFoundCheck
                        }
                        isMedicalCheck={this.state.isMedicalCheck}
                        stateDiversion={this.state.diversionClickedData}
                        onhandleOnSubmit={this.handleOnSubmit}
                        onChange={this.onChange}
                        {...this.props}
                      />
                    )}
                </Col>
              </Row>
            </Tab>
            {this.props.diversionTab.id && (
              <Tab
                eventKey="client"
                title={<span>{this.props.diversionTab.Client.name}</span>}
                className="tab-table comunity-user"
              >
                <Row>
                  <Col md={12}>
                    {this.state.key === "client" && (
                      <TableHeadDiversion
                        clientTab={true}
                        isOnlyHead={this.props.isClickedDiversionClients}
                        handleDropDown={this.handleDropDown}
                        stateDiversion={this.props.diversionTab}
                        onChange={this.onChange}
                        onRowSelect={this.onRowSelect}
                        clientId={this.props.diversionTab.Client.id}
                        diversionLocations={this.state.locations}
                        {...this.props}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {this.state.key === "client" && (
                      <UserDetailDiversion
                        clientTab={true}
                        handleDropDown={this.handleDropDown}
                        contacts={this.props.contacts}
                        isSaveButtons={false}
                        concentClickedData={this.state.concentClickedData}
                        onSave={this.onSave}
                        onChangeConcent={this.onChangeConcent}
                        isIllicitSubstancesFoundCheck={
                          this.state.isIllicitSubstancesFoundCheck
                        }
                        isMedicalCheck={this.state.isMedicalCheck}
                        changeState={this.onClose}
                        stateDiversion={this.props.diversionTab}
                        onhandleOnSubmit={this.handleOnSubmit}
                        onChange={this.onChange}
                        {...this.props}
                      />
                    )}
                  </Col>
                </Row>
              </Tab>
            )}
          </Tabs>
        </div> */}

        <div className={`${Styles.ml_main} mt-4`}>
          <div className="container-fluid px-0">
            <div className={`${Styles.tab_block} ${Styles.diversion_tab}`}>
              <div className={Styles.bg_yellow}>
                <ul
                  className={`nav nav-tabs ${Styles.nav} ${Styles.nav_tabs}`}
                  role="tablist"
                >
                  <li
                    className={Styles.nav_item}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => this.handleTab(e.target.id)}
                  >
                    <a
                      className={
                        this.state.activeTab == 1
                          ? `${Styles.nav_link} ${Styles.active}`
                          : `${Styles.nav_link}`
                      }
                      id="1"
                      data-bs-toggle="tab"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Active
                    </a>
                  </li>
                  <li
                    className={Styles.nav_item}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => this.handleTab(e.target.id)}
                  >
                    <a
                      className={
                        this.state.activeTab == 2
                          ? `${Styles.nav_link} ${Styles.active}`
                          : `${Styles.nav_link}`
                      }
                      data-bs-toggle="tab"
                      id="2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Add New
                    </a>
                  </li>
                  {this.props.diversionTab.id && (
                    <li
                      className={Styles.nav_item}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => this.handleTab(e.target.id)}
                    >
                      <a
                        className={
                          this.state.activeTab == 3
                            ? `${Styles.nav_link} ${Styles.active}`
                            : `${Styles.nav_link}`
                        }
                        data-bs-toggle="tab"
                        id="3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {this.props.diversionTab.Client.name}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {this.state.activeTab == 1 && (
            <ActiveUserCommunity
              from={"diversion"}
              setKey={() => this.setState({ activeTab: 3 })}
              {...this.props}
            />
          )}
          {this.state.activeTab == 2 && (
            <Search
              from={"diversion"}
              searchChanged={this.searchChanged}
              {...this.props}
            />
          )}
          {this.state.activeTab == 2 && (
            <TableHeadDiversion
              search={this.state.search}
              isOnlyHead={this.props.isClickedDiversionClients}
              handleDropDown={this.handleDropDown}
              stateDiversion={this.state.diversionClickedData}
              onChange={this.onChange}
              clientId={this.state.selectedClientId}
              onRowSelect={this.onRowSelect}
              diversionLocations={this.state.locations || []}
              {...this.props}
            />
          )}
          {this.state.activeTab == 2 &&
            this.props.isClickedDiversionClients && (
              <UserDetailDiversion
                handleDropDown={this.handleDropDown}
                isSaveButtons={this.state.isSaveButtons}
                contacts={this.props.contacts}
                concentClickedData={this.state.concentClickedData}
                onSave={this.onSave}
                onChangeConcent={this.onChangeConcent}
                changeState={this.onClose}
                isIllicitSubstancesFoundCheck={
                  this.state.isIllicitSubstancesFoundCheck
                }
                isMedicalCheck={this.state.isMedicalCheck}
                stateDiversion={this.state.diversionClickedData}
                onhandleOnSubmit={this.handleOnSubmit}
                onChange={this.onChange}
                {...this.props}
              />
            )}
          {this.props.diversionTab.id && this.state.activeTab == 3 && (
            <>
              <TableHeadDiversion
                clientTab={true}
                isOnlyHead={this.props.isClickedDiversionClients}
                handleDropDown={this.handleDropDown}
                stateDiversion={this.props.diversionTab}
                onChange={this.onChange}
                onRowSelect={this.onRowSelect}
                clientId={this.props.diversionTab.Client.id}
                diversionLocations={this.state.locations}
                {...this.props}
              />
              <UserDetailDiversion
                clientTab={true}
                handleDropDown={this.handleDropDown}
                contacts={this.props.contacts}
                isSaveButtons={false}
                concentClickedData={this.state.concentClickedData}
                onSave={this.onSave}
                onChangeConcent={this.onChangeConcent}
                isIllicitSubstancesFoundCheck={
                  this.state.isIllicitSubstancesFoundCheck
                }
                isMedicalCheck={this.state.isMedicalCheck}
                changeState={this.onClose}
                stateDiversion={this.props.diversionTab}
                onhandleOnSubmit={this.handleOnSubmit}
                onChange={this.onChange}
                {...this.props}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const stateMap = (state) => {
  return {
    searchClients: state.client.searchClients,
    searchResult: state.client.searchResult,
    totalClients: state.client.totalClients,
    newSearch: state.client.newSearch,
    clickedDiversionClients: state.diversionCenter.clickedDiversionClients,
    isClickedDiversionClients: state.diversionCenter.isClickedDiversionClients,
    diversion: state.diversionCenter.diversion,
    contacts: state.contact.contacts,
    diversionTab: state.diversionCenter.diversionTab,

    isMedicalObservation: state.medicalObservation.isMedicalObservation,

    medicalObservations: state.medicalObservation.medicalObservations,

    isWellbiengObservation: state.wellbiengObservation.isWellbiengObservation,
    wellbiengObservations: state.wellbiengObservation.wellbiengObservations,

    isDomesticViolance: state.domesticViolance.isDomesticViolance,
    domesticViolance: state.domesticViolance.domesticViolance,

    isClientObservation: state.clientObservation.isClientObservation,
    clientObservations: state.clientObservation.clientObservations,

    isClientBackground: state.clientBackground.isClientBackground,
    clientBackgrounds: state.clientBackground.clientBackgrounds,

    locations: state.location.locations,
  };
};

export default connect(stateMap)(DiversionCentreForm);
