import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  CLIENT_BACKGROUND,
  IS_CLIENT_BACKGROUND,
} from "../../helpers/constants";
import CancelIcon from "@material-ui/icons/Cancel";
import "../diversion-centre/diversion-centre.scss";
import { connect } from "react-redux";
import store from "../../store";
import CharacterCounter from "../../containers/character-counter/character-counter";

import Styles from "../shared/css/default.module.css";

class BackgroundInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propsLen: 0,
      clientBackground: [
        {
          information: "",
        },
      ],
    };
    this.addMore = this.addMore.bind(this);
    this.removeMedical = this.removeMedical.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, loc, id) {
    let name = e.target.name;
    let stateData = this.state;
    stateData.clientBackground[id].information = e.target.value;
    this.setState({ ...stateData }, () => {
      store.dispatch({
        type: CLIENT_BACKGROUND,
        payload: this.state.clientBackground,
      });
      store.dispatch({
        type: IS_CLIENT_BACKGROUND,
        payload: true,
      });
    });
  }

  addMore() {
    let violancesObjs = this.state.clientBackground;
    violancesObjs.push({
      information: "",
    });
    this.setState({ clientBackground: violancesObjs });
  }

  removeMedical(index) {
    let clientBackground = this.state.clientBackground;
    clientBackground.splice(index, 1);
    this.setState({ clientBackground });
  }

  componentDidMount() {
    if (this.props.stateDiversion) {
      let allProps = this.props.stateDiversion;

      this.setState(
        {
          clientBackground:
            allProps.ClientBackgroundInformations &&
            allProps.ClientBackgroundInformations.length
              ? allProps.ClientBackgroundInformations
              : [{ information: "" }],
          propsLen:
            allProps.ClientBackgroundInformations &&
            allProps.ClientBackgroundInformations.length
              ? allProps.ClientBackgroundInformations.length
              : 0,
        },
        () => {
          store.dispatch({
            type: CLIENT_BACKGROUND,
            payload: this.state.clientBackground,
          });
          store.dispatch({
            type: IS_CLIENT_BACKGROUND,
            payload: true,
          });
        }
      );
    }
  }

  //   render() {
  //     return (
  //       <>
  //         {this.state.clientBackground.map((bg, index) => (
  //           <Row key={"clientBackground" + index}>
  //             <Col md={8}>
  //               <Form.Group controlId="exampleForm.ControlTextarea1">
  //                 <CharacterCounter>
  //                   <Form.Control
  //                     value={bg.information}
  //                     disabled={
  //                       this.props.stateDiversion.isCheckout ||
  //                       index <= this.state.propsLen - 1
  //                     }
  //                     onChange={(e) => this.onChange(e, "information", index)}
  //                     name="backgroundInfo"
  //                     as="textarea"
  //                     rows={2}
  //                     maxLength={512}
  //                   />
  //                 </CharacterCounter>
  //               </Form.Group>
  //             </Col>
  //             <Col md={1}>
  //               {this.state.clientBackground.length !== 1 && !bg.id && (
  //                 <CancelIcon
  //                   onClick={() => {
  //                     this.removeMedical(index);
  //                   }}
  //                   className="float-right"
  //                 ></CancelIcon>
  //               )}
  //             </Col>
  //             <Col md={3}>
  //               {index == this.state.clientBackground.length - 1 && (
  //                 <button
  //                   disabled={this.props.disabled}
  //                   type="button"
  //                   onClick={this.addMore}
  //                   className="main-btn default-btn btn btn-primary"
  //                 >
  //                   ADD MORE
  //                 </button>
  //               )}
  //             </Col>
  //           </Row>
  //         ))}
  //       </>
  //     );
  //   }

  render() {
    return (
      //   <>
      //     {this.state.clientBackground.map((bg, index) => (
      //       <Row key={"clientBackground" + index}>
      //         <Col md={8}>
      //           <Form.Group controlId="exampleForm.ControlTextarea1">
      //             <CharacterCounter>
      //               <Form.Control
      //                 value={bg.information}
      //                 disabled={
      //                   this.props.stateDiversion.isCheckout ||
      //                   index <= this.state.propsLen - 1
      //                 }
      //                 onChange={(e) => this.onChange(e, "information", index)}
      //                 name="backgroundInfo"
      //                 as="textarea"
      //                 rows={2}
      //                 maxLength={512}
      //               />
      //             </CharacterCounter>
      //           </Form.Group>
      //         </Col>
      //         <Col md={1}>
      //           {this.state.clientBackground.length !== 1 && !bg.id && (
      //             <CancelIcon
      //               onClick={() => {
      //                 this.removeMedical(index);
      //               }}
      //               className="float-right"
      //             ></CancelIcon>
      //           )}
      //         </Col>
      //         <Col md={3}>
      //           {index == this.state.clientBackground.length - 1 && (
      //             <button
      //               disabled={this.props.disabled}
      //               type="button"
      //               onClick={this.addMore}
      //               className="main-btn default-btn btn btn-primary"
      //             >
      //               ADD MORE
      //             </button>
      //           )}
      //         </Col>
      //       </Row>
      //     ))}
      //   </>

      <>
        {this.state.clientBackground.map((bg, index) => (
          <div key={"clientBackground" + index}>
            <textarea
              className={Styles.form_control}
              style={{ width: "100%" }}
              value={bg.information}
              disabled={
                this.props.stateDiversion.isCheckout ||
                index <= this.state.propsLen - 1
              }
              onChange={(e) => this.onChange(e, "information", index)}
              name="backgroundInfo"
              as="textarea"
              rows={2}
              maxLength={512}
            ></textarea>
            {index == this.state.clientBackground.length - 1 && (
              <div className="text-end" style={{ textAlign: "right" }}>
                {this.state.clientBackground.length !== 1 && !bg.id && (
                  <a
                    className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_red} ${Styles.mb_0}`}
                    style={{ marginBottom: "0" }}
                    onClick={() => {
                      this.removeMedical(index);
                    }}
                  >
                    Remove
                  </a>
                )}
                <a
                  className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow} ${Styles.mb_0}`}
                  style={{ marginBottom: "0" }}
                  disabled={this.props.disabled}
                  type="button"
                  onClick={this.addMore}
                >
                  Add More
                </a>
              </div>
            )}
          </div>
        ))}
      </>
    );
  }
}

const stateMap = (state) => {
  return {
    isClientBackground: state.clientBackground.isClientBackground,
    clientBackgrounds: state.clientBackground.clientBackgrounds,
  };
};

export default connect(stateMap)(BackgroundInformation);
