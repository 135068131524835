import React from "react";
import clsx from "clsx";
import { Container, Row, Col, Button } from "react-bootstrap";
// import CustomForm from 'react-validation/build/form';
import CustomForm from "react-validation/build/form";
import Form from "../shared/form-control";
import Card from "react-bootstrap/Card";
import Select from "react-dropdown-select";
import "react-phone-number-input/style.css";
import "./admin.scss";
import { required } from "../../helpers/form-validation";
import { LOCATION, ROLE, TO_ADMIN_LIST } from "../../helpers/constants";
import { connect } from "react-redux";
import { CreateAction } from "../../actions/user/register";
import store from "../../store";
import { toast } from "../../helpers/utility";
import InputMask from "react-input-mask";
import {
  GetUserAction,
  UpdateUserAction,
  archiveUserAction,
  InviteAgainAction,
  restoreUserAction,
} from "../../actions/user/profile";
import CharacterCounter from "../../containers/character-counter/character-counter";

import Styles from "../shared/css/default.module.css";

class AdminForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    // this.handleCheck = this.handleCheck.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      user: {
        email: "",
        firstName: "",
        lastName: "",
        location: "Brisbane",
        dob: "",
        role: "SupportOfficer",
        phone: "",
      },
    };
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      store.dispatch(GetUserAction(this.props.match.params.id));
    }
    if (
      this.props.match.params.id &&
      this.props.user.id === this.props.match.params.id
    ) {
      this.setState({ user: { ...this.props.user } });
    } else {
    }
  }
  handleChange(e) {
    let user = this.state.user;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  }
  handleSelect(value) {
    let user = this.state.user;
    if (value[0]) {
      user[value[0].name] = value[0].value;
      this.setState({ user });
    }
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.form.validateAll();
    if (this.form.getChildContext()._errors.length === 0) {
      if (this.props.match.params.id) {
        store.dispatch(UpdateUserAction(this.state.user)); //set in session storage
      } else {
        store.dispatch(CreateAction(this.state.user)); //set in session storage
      }
    }
  }

  handleOnArchive(e) {
    e.preventDefault();
    if (this.props.match.params.id) {
      store.dispatch(archiveUserAction(this.props.match.params.id));
    }
  }

  handleOnRestore(e) {
    e.preventDefault();
    if (this.props.match.params.id) {
      store.dispatch(restoreUserAction(this.props.match.params.id));
      toast("User Restored");
      this.props.history.push(TO_ADMIN_LIST);
    }
  }

  handleOnInvite(e) {
    e.preventDefault();
    //   console.log(this.state.user)
    store.dispatch(InviteAgainAction(this.state.user));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id && this.props.match.params.id === undefined) {
      let user = {
        email: "",
        firstName: "",
        lastName: "",
        location: "Brisbane",
        dob: "",
        role: "SupportOfficer",
        phone: "",
      };
      this.setState({
        user: { ...this.state.user, ...user },
      });
    }
    if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
      this.setState({ user: { ...this.props.user } });
    }
    if (this.props.updated !== prevProps.updated) {
      this.props.history.push(TO_ADMIN_LIST);
      toast("User Updated");
    }
    if (
      !this.props.match.params.id &&
      this.props.users.length !== prevProps.users.length
    ) {
      toast("User Saved");
      this.props.history.push(TO_ADMIN_LIST);
    }
    if (this.props.archived !== prevProps.archived) {
      toast("User Archived");
      this.props.history.push(TO_ADMIN_LIST);
    }
  }

  render() {
    function ArchiveButton(props) {
      const showArchiveButton =
        !!props.userId && (!props.user || !props.user.deletedAt);
      const self = props.self;
      if (showArchiveButton) {
        return (
          <button
            type="submit"
            // className="main-btn default-btn btn btn-primary"
            className={`${Styles.btn} ${Styles.btn_red}`}
            onClick={self.handleOnArchive.bind(self)}
          >
            Archive
          </button>
        );
      }
      return null;
    }

    function RestoreButton(props) {
      const showRestoreButton = props.user && !!props.user.deletedAt;
      const self = props.self;
      if (showRestoreButton) {
        return (
          <button
            type="submit"
            // className="main-btn default-btn btn btn-primary"
            className={`${Styles.btn} ${Styles.btn_red}`}
            onClick={self.handleOnRestore.bind(self)}
          >
            Restore
          </button>
        );
      }
      return null;
    }

    function SignupState(props) {
      const user = props.user;

      if (!user.id) {
        return null;
      }
      if (!!user.deletedAt) {
        return null;
      }

      if (/\d/.test(user.confirmSignup)) {
        return (
          <>
            <img
              src={require("../../assets/images/grey-checkbox.svg")}
              className="mr-2 pb-1"
              alt="invited"
            />
            <label htmlFor="test6" className="mb-0">
              {" "}
              Invited
            </label>
          </>
        );
      }

      return (
        <>
          <img
            src={require("../../assets/images/orange-checkbox.svg")}
            className="mr-2 pb-1"
            alt="invite accepted"
          />
          <label htmlFor="test6" className="mb-0">
            {" "}
            Invite Accepted
          </label>
        </>
      );
    }
    function InviteAgain(props) {
      const user = props.user;

      if (!user.id) {
        return null;
      }
      if (!!user.deletedAt) {
        return null;
      }
      const self = props.self;
      if (/\d/.test(user.confirmSignup)) {
        return (
          <button
            type="submit"
            className="main-btn default-btn btn btn-primary"
            onClick={self.handleOnInvite.bind(self)}
          >
            Send Invite
          </button>
        );
      }
      return null;
    }

    // return (
    //   <div className="admin-form full-width mt-4">
    //     <Card>
    //       <Card.Header className="text-center">
    //         {this.props.match.params.id ? "View Form" : "Add New User"}{" "}
    //       </Card.Header>
    //       <Card.Body>
    //         <CustomForm
    //           ref={(c) => {
    //             this.form = c;
    //           }}
    //           onSubmit={this.handleOnSubmit}
    //         >
    //           <Form.Row className="d-flex align-items-center bg-grey p-3">
    //             <Col md={6}>
    //               <Row>
    //                 <Col md={5}>
    //                   <Form.Label className="pl-3">First Name </Form.Label>
    //                 </Col>
    //                 <Col md={7}>
    //                   <CharacterCounter>
    //                     <Form.Control
    //                       type="text"
    //                       validations={[required]}
    //                       value={
    //                         this.state.user.firstName
    //                           ? this.state.user.firstName
    //                           : ""
    //                       }
    //                       name="firstName"
    //                       onChange={this.handleChange}
    //                       placeholder=" First Name"
    //                       className="form-field-border custom-width"
    //                       maxLength={255}
    //                     />
    //                   </CharacterCounter>
    //                 </Col>
    //               </Row>
    //             </Col>
    //             <Col md={6}>
    //               <Row>
    //                 <Col md={5}>
    //                   <Form.Label className="text-label">Last Name</Form.Label>
    //                 </Col>
    //                 <Col md={7}>
    //                   <CharacterCounter>
    //                     <Form.Control
    //                       type="text"
    //                       validations={[required]}
    //                       value={
    //                         this.state.user.lastName
    //                           ? this.state.user.lastName
    //                           : ""
    //                       }
    //                       name="lastName"
    //                       onChange={this.handleChange}
    //                       placeholder=" Last Name"
    //                       className="form-field-border custom-width"
    //                       maxLength={255}
    //                     />
    //                   </CharacterCounter>
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Form.Row>
    //           <Form.Row className="d-flex align-items-center p-3">
    //             <Col md={6}>
    //               <Row>
    //                 <Col md={5}>
    //                   <Form.Label className="pl-3">Email </Form.Label>
    //                 </Col>
    //                 <Col md={7}>
    //                   <CharacterCounter>
    //                     <Form.Control
    //                       type="email"
    //                       validations={[required]}
    //                       value={
    //                         this.state.user.email ? this.state.user.email : ""
    //                       }
    //                       name="email"
    //                       onChange={this.handleChange}
    //                       placeholder="Email"
    //                       className="form-field-border custom-width"
    //                       maxLength={255}
    //                     />
    //                   </CharacterCounter>
    //                 </Col>
    //               </Row>
    //             </Col>

    //             <Col md={6}>
    //               <Row>
    //                 <Col md={5}>
    //                   <Form.Label className="text-label">Role </Form.Label>
    //                 </Col>
    //                 <Col md={7}>
    //                   <Select
    //                     className="selection-select arrow-down custom-width"
    //                     options={ROLE}
    //                     values={ROLE.filter(
    //                       (elem) => elem.value === this.state.user.role
    //                     )}
    //                     name="role"
    //                     onChange={(value) => this.handleSelect(value)}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Form.Row>
    //           <Form.Row className="d-flex align-items-center bg-grey p-3">
    //             <Col md={6}>
    //               <Row>
    //                 <Col md={5}>
    //                   <Form.Label className="pl-3">Phone </Form.Label>
    //                 </Col>
    //                 <Col md={7}>
    //                   <InputMask
    //                     alwaysShowMask={false}
    //                     className="form-field-border custom-width form-control"
    //                     value={this.state.user.phone}
    //                     name="phone"
    //                     onChange={this.handleChange}
    //                     type="text"
    //                     fullWidth
    //                     mask="99 9999 9999"
    //                     maskChar="_"
    //                   />
    //                 </Col>
    //               </Row>
    //             </Col>
    //             <Col md={6}>
    //               <Row>
    //                 <Col md={5}>
    //                   <Form.Label className="pl-3">Location</Form.Label>
    //                 </Col>
    //                 <Col md={7}>
    //                   <Select
    //                     className="selection-select arrow-down custom-width"
    //                     options={LOCATION}
    //                     values={LOCATION.filter(
    //                       (elem) => elem.value === this.state.user.location
    //                     )}
    //                     name="location"
    //                     onChange={(value) => this.handleSelect(value)}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Form.Row>
    //           <Row className="p-3">
    //             <Col md={6} className="d-flex align-items-end">
    //               <SignupState user={this.state.user} />
    //             </Col>
    //             <Col md={6} className="text-right">
    //               <InviteAgain user={this.state.user} self={this} />
    //               <ArchiveButton
    //                 userId={this.props.match.params.id}
    //                 user={this.state.user}
    //                 self={this}
    //               />
    //               <RestoreButton user={this.state.user} self={this} />
    //               <button
    //                 type="submit"
    //                 className="main-btn default-btn btn btn-primary"
    //               >
    //                 {this.props.match.params.id ? "Update" : "Invite"}
    //               </button>
    //             </Col>
    //           </Row>
    //         </CustomForm>
    //       </Card.Body>
    //     </Card>
    //   </div>
    // );

    return (
      //   <div className="admin-form full-width mt-4">
      //     <Card>
      //       <Card.Header className="text-center">
      //         {this.props.match.params.id ? "View Form" : "Add New User"}{" "}
      //       </Card.Header>
      //       <Card.Body>
      //         <CustomForm
      //           ref={(c) => {
      //             this.form = c;
      //           }}
      //           onSubmit={this.handleOnSubmit}
      //         >
      //           <Form.Row className="d-flex align-items-center bg-grey p-3">
      //             <Col md={6}>
      //               <Row>
      //                 <Col md={5}>
      //                   <Form.Label className="pl-3">First Name </Form.Label>
      //                 </Col>
      //                 <Col md={7}>
      //                   <CharacterCounter>
      //                     <Form.Control
      //                       type="text"
      //                       validations={[required]}
      //                       value={
      //                         this.state.user.firstName
      //                           ? this.state.user.firstName
      //                           : ""
      //                       }
      //                       name="firstName"
      //                       onChange={this.handleChange}
      //                       placeholder=" First Name"
      //                       className="form-field-border custom-width"
      //                       maxLength={255}
      //                     />
      //                   </CharacterCounter>
      //                 </Col>
      //               </Row>
      //             </Col>
      //             <Col md={6}>
      //               <Row>
      //                 <Col md={5}>
      //                   <Form.Label className="text-label">Last Name</Form.Label>
      //                 </Col>
      //                 <Col md={7}>
      //                   <CharacterCounter>
      //                     <Form.Control
      //                       type="text"
      //                       validations={[required]}
      //                       value={
      //                         this.state.user.lastName
      //                           ? this.state.user.lastName
      //                           : ""
      //                       }
      //                       name="lastName"
      //                       onChange={this.handleChange}
      //                       placeholder=" Last Name"
      //                       className="form-field-border custom-width"
      //                       maxLength={255}
      //                     />
      //                   </CharacterCounter>
      //                 </Col>
      //               </Row>
      //             </Col>
      //           </Form.Row>
      //           <Form.Row className="d-flex align-items-center p-3">
      //             <Col md={6}>
      //               <Row>
      //                 <Col md={5}>
      //                   <Form.Label className="pl-3">Email </Form.Label>
      //                 </Col>
      //                 <Col md={7}>
      //                   <CharacterCounter>
      //                     <Form.Control
      //                       type="email"
      //                       validations={[required]}
      //                       value={
      //                         this.state.user.email ? this.state.user.email : ""
      //                       }
      //                       name="email"
      //                       onChange={this.handleChange}
      //                       placeholder="Email"
      //                       className="form-field-border custom-width"
      //                       maxLength={255}
      //                     />
      //                   </CharacterCounter>
      //                 </Col>
      //               </Row>
      //             </Col>

      //             <Col md={6}>
      //               <Row>
      //                 <Col md={5}>
      //                   <Form.Label className="text-label">Role </Form.Label>
      //                 </Col>
      //                 <Col md={7}>
      //                   <Select
      //                     className="selection-select arrow-down custom-width"
      //                     options={ROLE}
      //                     values={ROLE.filter(
      //                       (elem) => elem.value === this.state.user.role
      //                     )}
      //                     name="role"
      //                     onChange={(value) => this.handleSelect(value)}
      //                   />
      //                 </Col>
      //               </Row>
      //             </Col>
      //           </Form.Row>
      //           <Form.Row className="d-flex align-items-center bg-grey p-3">
      //             <Col md={6}>
      //               <Row>
      //                 <Col md={5}>
      //                   <Form.Label className="pl-3">Phone </Form.Label>
      //                 </Col>
      //                 <Col md={7}>
      //                   <InputMask
      //                     alwaysShowMask={false}
      //                     className="form-field-border custom-width form-control"
      //                     value={this.state.user.phone}
      //                     name="phone"
      //                     onChange={this.handleChange}
      //                     type="text"
      //                     fullWidth
      //                     mask="99 9999 9999"
      //                     maskChar="_"
      //                   />
      //                 </Col>
      //               </Row>
      //             </Col>
      //             <Col md={6}>
      //               <Row>
      //                 <Col md={5}>
      //                   <Form.Label className="pl-3">Location</Form.Label>
      //                 </Col>
      //                 <Col md={7}>
      //                   <Select
      //                     className="selection-select arrow-down custom-width"
      //                     options={LOCATION}
      //                     values={LOCATION.filter(
      //                       (elem) => elem.value === this.state.user.location
      //                     )}
      //                     name="location"
      //                     onChange={(value) => this.handleSelect(value)}
      //                   />
      //                 </Col>
      //               </Row>
      //             </Col>
      //           </Form.Row>
      //           <Row className="p-3">
      //             <Col md={6} className="d-flex align-items-end">
      //               <SignupState user={this.state.user} />
      //             </Col>
      //             <Col md={6} className="text-right">
      //               <InviteAgain user={this.state.user} self={this} />
      //               <ArchiveButton
      //                 userId={this.props.match.params.id}
      //                 user={this.state.user}
      //                 self={this}
      //               />
      //               <RestoreButton user={this.state.user} self={this} />
      //               <button
      //                 type="submit"
      //                 className="main-btn default-btn btn btn-primary"
      //               >
      //                 {this.props.match.params.id ? "Update" : "Invite"}
      //               </button>
      //             </Col>
      //           </Row>
      //         </CustomForm>
      //       </Card.Body>
      //     </Card>
      //   </div>

      <div className={`${Styles.ml_main} ${Styles.mt_40}`}>
        <CustomForm
          ref={(c) => {
            this.form = c;
          }}
          onSubmit={this.handleOnSubmit}
        >
          <div className="container-fluid px-0">
            <div
              className="bg-white pb-100 overflow-hidden"
              style={{ paddingBottom: "170px" }}
            >
              <div className={`${Styles.tab_block} report-block`}>
                <div className={Styles.bg_yellow} style={{ lineHeight: 2.5 }}>
                  <ul className={`nav ${Styles.nav_tabs}`} role="tablist">
                    <li className={Styles.nav_item}>
                      <a
                        className={`${Styles.nav_link} ${Styles.active}`}
                        data-bs-toggle="tab"
                        href="#tab1"
                        style={{ paddingLeft: "45px", paddingRight: "45px" }}
                      >
                        {this.props.match.params.id
                          ? "View Form"
                          : "Add New User"}{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div id="tab1" className="tab-pane active">
                    <div className={Styles.report_form}>
                      <div className="row gx-4">
                        <div
                          className="col-md-4 col-sm-12 mb-30"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>First Name </label>
                          <input
                            type="text"
                            validations={[required]}
                            value={
                              this.state.user.firstName
                                ? this.state.user.firstName
                                : ""
                            }
                            name="firstName"
                            onChange={this.handleChange}
                            placeholder=" First Name"
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            maxLength={255}
                            style={{ backgroundImage: "none" }}
                          />
                        </div>
                        <div
                          className="col-md-4 col-sm-6 mb-30 mb-sm-0"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>Last Name </label>
                          <input
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            maxLength={255}
                            style={{ backgroundImage: "none" }}
                            type="text"
                            validations={[required]}
                            value={
                              this.state.user.lastName
                                ? this.state.user.lastName
                                : ""
                            }
                            name="lastName"
                            onChange={this.handleChange}
                            placeholder=" Last Name"
                          />
                        </div>
                        <div
                          className="col-md-4 col-sm-6 mb-30"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>Email </label>
                          <input
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            maxLength={255}
                            style={{ backgroundImage: "none" }}
                            type="email"
                            validations={[required]}
                            value={
                              this.state.user.email ? this.state.user.email : ""
                            }
                            name="email"
                            onChange={this.handleChange}
                            placeholder="Email"
                          />
                        </div>
                        <div
                          className="col-md-4 col-sm-6 mb-30 mb-sm-0"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>Role </label>
                          <Select
                            //   className="selection-select arrow-down custom-width"
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            options={ROLE}
                            values={ROLE.filter(
                              (elem) => elem.value === this.state.user.role
                            )}
                            name="role"
                            onChange={(value) => this.handleSelect(value)}
                            style={{
                              paddingLeft: "14px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingRight: "14px",
                              backgroundImage: "none",
                            }}
                          />
                        </div>
                        <div
                          className="col-md-4 col-sm-6 mb-30"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>Phone </label>
                          <InputMask
                            //   className={Styles.form_control}
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            style={{ width: "100%", backgroundImage: "none" }}
                            alwaysShowMask={false}
                            value={this.state.user.phone}
                            name="phone"
                            onChange={this.handleChange}
                            type="text"
                            fullWidth
                            mask="99 9999 9999"
                            maskChar="_"
                          />
                        </div>
                        <div
                          className="col-md-4 col-sm-6 mb-30"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>Location </label>
                          <Select
                            //   className="selection-select arrow-down custom-width"
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            options={LOCATION}
                            values={LOCATION.filter(
                              (elem) => elem.value === this.state.user.location
                            )}
                            name="location"
                            onChange={(value) => this.handleSelect(value)}
                            style={{
                              paddingLeft: "14px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingRight: "14px",
                              backgroundImage: "none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${Styles.report_btn} text-end`}
                      style={{ textAlign: "end" }}
                    >
                      <InviteAgain user={this.state.user} self={this} />
                      <ArchiveButton
                        userId={this.props.match.params.id}
                        user={this.state.user}
                        self={this}
                      />
                      <RestoreButton user={this.state.user} self={this} />
                      <button
                        className={`${Styles.btn} ${Styles.btn_yellow}`}
                        //   onClick={this.downloadReport}
                        type="submit"
                      >
                        {this.props.match.params.id ? "Update" : "Invite"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomForm>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    user: state.user.admin,
    updated: state.user.updated,
    archived: state.user.archived,
    restored: state.user.restored || false,
  };
};
export default connect(stateMap)(AdminForm);
