import {
  createDiversionCenter,
  getDiversionCenter,
  updateDiversionCenter,
  getDiversion,
} from "../api/diversion-center-api";
import { DIVERSIONS, DIVERSION_SAVE } from "../helpers/constants";
require("es6-promise").polyfill();

export function CreateDiversionCenterAction(data) {
  return async function (dispatch) {
    const res = await createDiversionCenter(data);
    dispatch({
      type: DIVERSION_SAVE,
      payload: res.data.data.diversionCenter,
    });
  };
}

export function updateDiversionCenterAction(data) {
  return async function (dispatch) {
    const res = await updateDiversionCenter(data);
    dispatch({
      type: DIVERSION_SAVE,
      payload: res.data.data.diversionCenter,
    });
  };
}
export function getDiversionCentersAction(data) {
  return async function (dispatch) {
    const res = await getDiversionCenter(data);
    dispatch({
      type: DIVERSIONS,
      payload: res.data.data.diversionCenter,
    });
  };
}
export function getSingleDiversionCenter(id) {
  return async function (dispatch) {
    try {
      const res = await getDiversion(id);
      dispatch({
        type: DIVERSION_SAVE,
        payload: res.data.data.diversion[0],
      });
    } catch (error) {
      console.log("error here", error);
    }
  };
}
