import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import ProfileForm from "../../components/profile/profile";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";

import Styles from "../../components/shared/css/default.module.css";

export default class MainProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //   isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
      isSidebarOpen: false,
    };
  }
  render() {
    return (
      //   <div className="admin-content">
      <div
        id={Styles["wrapper"]}
        className={this.state.isSidebarOpen ? Styles.toggled : ""}
      >
        <Sidebar
          isSidebarOpen={this.state.isSidebarOpen}
          {...this.props}
        ></Sidebar>
        <Header
          isSideBarHide={true}
          handleSidebar={() =>
            this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
          }
          {...this.props}
        />
        <ProfileForm {...this.props} />
      </div>
    );
  }
}
