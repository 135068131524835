import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import {
  CLIENT_OBSERVATION,
  IS_CLIENT_OBSERVATION,
  FULL_TIME_FORMAT,
  DATE_FORMAT,
} from "../../helpers/constants";

import { getUser } from "../../helpers/utility";

import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import "../diversion-centre/diversion-centre.scss";
import { connect } from "react-redux";
import store from "../../store";
import CharacterCounter from "../../containers/character-counter/character-counter";

import Styles from "../shared/css/default.module.css";

class ClientObservationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cTime: "",
      propsLen: 0,
      observations: [
        {
          clientCheck: moment().format().trim(),
          clientAwake: 1,
          clientStatusChange: 2,
          statusComments: "",
        },
      ],
    };
    this.addMore = this.addMore.bind(this);
    this.removeMedical = this.removeMedical.bind(this);
    this.onChange = this.onChange.bind(this);

    this.options4 = [
      { label: "Awake", value: 1 },
      { label: "Sleep", value: 2 },
    ];

    this.options5 = [
      { label: "Yes", value: 1 },
      { label: "No", value: 2 },
    ];
  }

  handleDropDown(e, name, index) {
    let observations = this.state.observations;
    if (e[0]) {
      observations[index][name] = e[0].value;

      this.setState({ observations }, () => {
        store.dispatch({
          type: CLIENT_OBSERVATION,
          payload: this.state.observations,
        });
        store.dispatch({
          type: IS_CLIENT_OBSERVATION,
          payload: true,
        });
      });
    }
  }

  onChange(e, loc) {
    let name = e.target.name;
    let stateData = this.state;
    stateData.observations[loc][name] = e.target.value;
    this.setState({ ...stateData }, () => {
      store.dispatch({
        type: CLIENT_OBSERVATION,
        payload: this.state.observations,
      });
      store.dispatch({
        type: IS_CLIENT_OBSERVATION,
        payload: true,
      });
    });
  }

  addMore() {
    let observationsObjs = this.state.observations;
    observationsObjs.push({
      clientCheck: moment().format().trim(),
      clientAwake: 1,
      clientStatusChange: 2,
      statusComments: "",
    });
    this.setState({ observations: observationsObjs });
  }

  removeMedical(index) {
    let observations = this.state.observations;
    observations.splice(index, 1);
    if (index < this.state.propsLen) {
      this.setState({ propsLen: this.state.propsLen - 1 });
    }
    this.setState({ observations });
  }

  componentDidMount() {
    if (this.props.medObs) {
      let allProps = this.props.medObs;

      const observations =
        allProps.ClientObservationForms &&
        allProps.ClientObservationForms.length
          ? allProps.ClientObservationForms
          : [
              {
                clientCheck: moment().format().trim(),
                clientAwake: 1,
                clientStatusChange: 2,
                statusComments: "",
              },
            ];

      observations.forEach((observation) => {
        if (!observation.statusComments) observation.statusComments = "";
      });

      this.setState(
        {
          observations,
          propsLen:
            allProps.ClientObservationForms &&
            allProps.ClientObservationForms.length
              ? allProps.ClientObservationForms.length
              : 0,
          cTime: moment().format(FULL_TIME_FORMAT).trim(),
        },
        () => {
          store.dispatch({
            type: CLIENT_OBSERVATION,
            payload: this.state.observations,
          });
          store.dispatch({
            type: IS_CLIENT_OBSERVATION,
            payload: true,
          });
        }
      );
    }
  }

  //   render() {
  //     return (
  //       <>
  //         {this.state.observations.map((observation, index) => (
  //           <React.Fragment key={"Observation" + index}>
  //             <Col md={11}>
  //               <div className="client-record">
  //                 {index == 0 && (
  //                   <h6>
  //                     CLIENT OBSERVATION <br></br>RECORD
  //                   </h6>
  //                 )}
  //                 <Row className="d-flex align-items-center">
  //                   <Col className="observation-colum">
  //                     {index == 0 && (
  //                       <Form.Label className="observation-label">
  //                         Did you check on them?
  //                       </Form.Label>
  //                     )}
  //                     <Form.Label className="observation-label form-fields text-center pt-0">
  //                       <span style={{ color: "#b3b3b3" }}>
  //                         {moment(observation.clientCheck)
  //                           .format(DATE_FORMAT)
  //                           .trim()}
  //                       </span>{" "}
  //                       &nbsp;{" "}
  //                       {moment(observation.clientCheck)
  //                         .format(FULL_TIME_FORMAT)
  //                         .trim()}
  //                     </Form.Label>
  //                     {/* <Form.Control name="clientCheck" type="time" disabled value={moment(observation.clientCheck).format(FULL_TIME_FORMAT).trim()} placeholder="Enter Time" className="form-fields observation-input text-center pt-0" /> */}
  //                   </Col>
  //                   <Col>
  //                     {index == 0 && (
  //                       <Form.Label className="observation-label">
  //                         Were they awake or asleep?
  //                       </Form.Label>
  //                     )}
  //                     <Select
  //                       className="selection-select observation-input arrow-down custom-width"
  //                       placeholder=""
  //                       disabled={index <= this.state.propsLen - 1}
  //                       options={this.options4}
  //                       values={[
  //                         ...this.options4.filter(
  //                           (elem) =>
  //                             elem.value ==
  //                             (eval(observation.clientAwake)
  //                               ? eval(observation.clientAwake)
  //                               : "")
  //                         ),
  //                       ]}
  //                       name="clientAwake"
  //                       onChange={(e) =>
  //                         this.handleDropDown(e, "clientAwake", index)
  //                       }
  //                     />
  //                   </Col>
  //                   <Col>
  //                     {index == 0 && (
  //                       <Form.Label className="observation-label text-center">
  //                         Status Change?
  //                       </Form.Label>
  //                     )}
  //                     <Select
  //                       className="selection-select observation-input arrow-down custom-width"
  //                       placeholder="Yes"
  //                       disabled={index <= this.state.propsLen - 1}
  //                       options={this.options5}
  //                       values={[
  //                         ...this.options5.filter(
  //                           (elem) =>
  //                             elem.value ==
  //                             (eval(observation.clientStatusChange)
  //                               ? eval(observation.clientStatusChange)
  //                               : "")
  //                         ),
  //                       ]}
  //                       name="clientStatusChange"
  //                       onChange={(e) =>
  //                         this.handleDropDown(e, "clientStatusChange", index)
  //                       }
  //                     />
  //                   </Col>
  //                   <Col>
  //                     {index == 0 && (
  //                       <Form.Label className="observation-label d-block">
  //                         Comments
  //                         <br />
  //                         <small>(Mandatory when status changed)</small>
  //                       </Form.Label>
  //                     )}
  //                     <CharacterCounter>
  //                       <Form.Control
  //                         name="statusComments"
  //                         value={observation.statusComments}
  //                         disabled={index <= this.state.propsLen - 1}
  //                         onChange={(e) => this.onChange(e, index)}
  //                         className="form-fields observation-input text-center pt-0"
  //                         placeholder="Status Comments Here..."
  //                         maxLength={255}
  //                       />
  //                     </CharacterCounter>
  //                   </Col>
  //                 </Row>
  //               </div>
  //             </Col>
  //             <Col md={1}>
  //               {this.state.observations.length !== 1 && !observation.id && (
  //                 <CancelIcon
  //                   onClick={() => this.removeMedical(index)}
  //                   className="float-right"
  //                 ></CancelIcon>
  //               )}
  //             </Col>
  //             <Col md={12}>
  //               <div className="btn-sec full-width text-left form-btn">
  //                 {index === this.state.observations.length - 1 && (
  //                   <button
  //                     type="button"
  //                     onClick={this.addMore}
  //                     disabled={this.props.disabled}
  //                     className="main-btn default-btn btn-width btn btn-primary"
  //                   >
  //                     ADD MORE
  //                   </button>
  //                 )}
  //               </div>
  //             </Col>
  //           </React.Fragment>
  //         ))}
  //       </>
  //     );
  //   }

  render() {
    return (
      <>
        {/* {this.state.observations.map((observation, index) => (
          <React.Fragment key={"Observation" + index}>
            <Col md={11}>
              <div className="client-record">
                {index == 0 && (
                  <h6>
                    CLIENT OBSERVATION <br></br>RECORD
                  </h6>
                )}
                <Row className="d-flex align-items-center">
                  <Col className="observation-colum">
                    {index == 0 && (
                      <Form.Label className="observation-label">
                        Did you check on them?
                      </Form.Label>
                    )}
                    <Form.Label className="observation-label form-fields text-center pt-0">
                      <span style={{ color: "#b3b3b3" }}>
                        {moment(observation.clientCheck)
                          .format(DATE_FORMAT)
                          .trim()}
                      </span>{" "}
                      &nbsp;{" "}
                      {moment(observation.clientCheck)
                        .format(FULL_TIME_FORMAT)
                        .trim()}
                    </Form.Label>
                    
                  </Col>
                  <Col>
                    {index == 0 && (
                      <Form.Label className="observation-label">
                        Were they awake or asleep?
                      </Form.Label>
                    )}
                    <Select
                      className="selection-select observation-input arrow-down custom-width"
                      placeholder=""
                      disabled={index <= this.state.propsLen - 1}
                      options={this.options4}
                      values={[
                        ...this.options4.filter(
                          (elem) =>
                            elem.value ==
                            (eval(observation.clientAwake)
                              ? eval(observation.clientAwake)
                              : "")
                        ),
                      ]}
                      name="clientAwake"
                      onChange={(e) =>
                        this.handleDropDown(e, "clientAwake", index)
                      }
                    />
                  </Col>
                  <Col>
                    {index == 0 && (
                      <Form.Label className="observation-label text-center">
                        Status Change?
                      </Form.Label>
                    )}
                    <Select
                      className="selection-select observation-input arrow-down custom-width"
                      placeholder="Yes"
                      disabled={index <= this.state.propsLen - 1}
                      options={this.options5}
                      values={[
                        ...this.options5.filter(
                          (elem) =>
                            elem.value ==
                            (eval(observation.clientStatusChange)
                              ? eval(observation.clientStatusChange)
                              : "")
                        ),
                      ]}
                      name="clientStatusChange"
                      onChange={(e) =>
                        this.handleDropDown(e, "clientStatusChange", index)
                      }
                    />
                  </Col>
                  <Col>
                    {index == 0 && (
                      <Form.Label className="observation-label d-block">
                        Comments
                        <br />
                        <small>(Mandatory when status changed)</small>
                      </Form.Label>
                    )}
                    <CharacterCounter>
                      <Form.Control
                        name="statusComments"
                        value={observation.statusComments}
                        disabled={index <= this.state.propsLen - 1}
                        onChange={(e) => this.onChange(e, index)}
                        className="form-fields observation-input text-center pt-0"
                        placeholder="Status Comments Here..."
                        maxLength={255}
                      />
                    </CharacterCounter>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={1}>
              {this.state.observations.length !== 1 && !observation.id && (
                <CancelIcon
                  onClick={() => this.removeMedical(index)}
                  className="float-right"
                ></CancelIcon>
              )}
            </Col>
            <Col md={12}>
              <div className="btn-sec full-width text-left form-btn">
                {index === this.state.observations.length - 1 && (
                  <button
                    type="button"
                    onClick={this.addMore}
                    disabled={this.props.disabled}
                    className="main-btn default-btn btn-width btn btn-primary"
                  >
                    ADD MORE
                  </button>
                )}
              </div>
            </Col>
          </React.Fragment>
        ))} */}

        {/* {this.state.observations.map((observation, index) => ( */}
        <div
          className={Styles.clientWellbeing_block}
          style={{ paddingBottom: "0", backgroundColor: "#fff" }}
          // key={"Observation" + index}
        >
          <div
            className={`${Styles.table_responsive} table-responsive`}
            style={{ paddingBottom: "46px", marginBottom: "0px" }}
          >
            <table className={Styles.table_noBg}>
              <thead>
                <tr>
                  {/* {index == 0 && <th>Did you check on them?</th>} */}
                  <th>Did you check on them?</th>
                  {/* {index == 0 && <th>Were they awake or asleep?</th>} */}
                  <th>Were they awake or asleep?</th>
                  {/* {index == 0 && <th>Status Change?</th>} */}
                  <th>Status Change?</th>
                  {/* {index == 0 && (
                    <th>
                      Comments <span>(Mandatory when status changed)</span>
                    </th>
                  )} */}
                  <th>
                    Comments <span>(Mandatory when status changed)</span>
                  </th>
                  <th>Staff member</th>
                </tr>
              </thead>
              <tbody>
                {this.state.observations.map((observation, index) => (
                  <tr>
                    <td>
                      {/* 9/08/22 <span>12:42</span> */}
                      {moment(observation.clientCheck)
                        .format(DATE_FORMAT)
                        .trim()}{" "}
                      <span style={{ marginLeft: "18px" }}>
                        {moment(observation.clientCheck)
                          .format(FULL_TIME_FORMAT)
                          .trim()}
                      </span>
                    </td>
                    <td>
                      {/* <select name="" id="" className={Styles.form_select}>
                        <option value="">Awake</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select> */}
                      <Select
                        className={`${Styles.form_select} ${Styles.select_dropdown}`}
                        style={{
                          width: "50%",
                          backgroundImage: "none",
                          marginLeft: "0px",
                        }}
                        placeholder=""
                        // disabled={index <= this.state.propsLen - 1}
                        options={this.options4}
                        values={[
                          ...this.options4.filter(
                            (elem) =>
                              elem.value ==
                              (eval(observation.clientAwake)
                                ? eval(observation.clientAwake)
                                : "")
                          ),
                        ]}
                        disabled={index <= this.state.propsLen - 1}
                        name="clientAwake"
                        onChange={(e) =>
                          this.handleDropDown(e, "clientAwake", index)
                        }
                      />
                    </td>
                    <td>
                      {/* <select name="" id="" className={Styles.form_select}>
                        <option value="">No</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select> */}
                      <Select
                        className={`${Styles.form_select} ${Styles.select_dropdown}`}
                        placeholder="Yes"
                        style={{
                          width: "80%",
                          backgroundImage: "none",
                          marginLeft: "0px",
                        }}
                        disabled={index <= this.state.propsLen - 1}
                        options={this.options5}
                        values={[
                          ...this.options5.filter(
                            (elem) =>
                              elem.value ==
                              (eval(observation.clientStatusChange)
                                ? eval(observation.clientStatusChange)
                                : "")
                          ),
                        ]}
                        name="clientStatusChange"
                        onChange={(e) =>
                          this.handleDropDown(e, "clientStatusChange", index)
                        }
                      />
                    </td>
                    <td>
                      {/* <select name="" id="" className={Styles.form_select}>
                        <option value="">Status Comments Here...</option>
                        <option value="">one</option>
                        <option value="">two</option>
                      </select> */}
                      {/* <Form.Control
                        name="statusComments"
                        value={observation.statusComments}
                        disabled={index <= this.state.propsLen - 1}
                        onChange={(e) => this.onChange(e, index)}
                        // className="form-fields observation-input text-center pt-0"
                        className={Styles.form_control}
                        placeholder="Status Comments Here..."
                        maxLength={255}
                      /> */}
                      <textarea
                        name="statusComments"
                        value={observation.statusComments}
                        disabled={index <= this.state.propsLen - 1}
                        onChange={(e) => this.onChange(e, index)}
                        // className="form-fields observation-input text-center pt-0"
                        className={Styles.form_control}
                        rows="1"
                        style={{
                          borderRadius: "0px",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #aeaeae",
                          fontSize: "12px",
                          marginBottom: "0px",
                        }}
                        placeholder="Status Comments Here..."
                        maxLength={255}
                      />
                    </td>
                    {/* <td>lorem ipsum dolor sit </td> */}
                    <td>{getUser().name}</td>

                    {/* {this.state.observations.length !== 1 &&
                      !observation.id &&
                      getUser().Role.admin && (
                        <td>
                          <CancelIcon
                            onClick={() => this.removeMedical(index)}
                            className="float-right"
                            style={{ cursor: "pointer" }}
                          ></CancelIcon>
                        </td>
                      )} */}
                    {getUser().Role.admin && (
                      <td>
                        <CancelIcon
                          onClick={() => this.removeMedical(index)}
                          // onClick={() => console.log("Index:-- ", index)}
                          className="float-right"
                          style={{ cursor: "pointer" }}
                        ></CancelIcon>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* {index === this.state.observations.length - 1 && ( */}
          <div className="">
            <a
              className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
              type="button"
              onClick={this.addMore}
              disabled={this.props.disabled}
            >
              Add More
            </a>
          </div>
          {/* )} */}
          {/* <h6 style={{ fontSize: "18px", fontWeight: "600" }}>
              Client Background Information
            </h6>
            <textarea
              className={Styles.form_control}
              style={{ width: "100%" }}
              id=""
              rows="5"
            ></textarea>
            <div className="text-end" style={{ textAlign: "right" }}>
              <a
                href="#"
                className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow} ${Styles.mb_0}`}
                style={{ marginBottom: "0" }}
              >
                Add More
              </a>
            </div> */}
        </div>
        {/* ))} */}
      </>
    );
  }
}

const stateMap = (state) => {
  return {
    isClientObservation: state.clientObservation.isClientObservation,
    clientObservations: state.clientObservation.clientObservations,
  };
};

export default connect(stateMap)(ClientObservationForm);
