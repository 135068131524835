/**
 * For some unknown reason this component refuses to correctly set the checked state of a number of tick boxes correctly
 * when the state updates. As a workaround the component has been forced to rebuild by using  key={Math.random()} which is
 * less than ideal. No other solution is known to ensure that the checkbox state updates when the value updates
 *
 * See: https://stackoverflow.com/questions/29808636/when-giving-unique-keys-to-components-is-it-okay-to-use-math-random-for-gener
 */

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-dropdown-select";
// import Select from "react-select";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { required } from "../../helpers/form-validation";
import {
  MEDICAL_OBSERVATION,
  IS_MEDICAL_OBSERVATION,
  WELLBIENG_OBSERVATION,
  IS_WELLBIENG_OBSERVATION,
} from "../../helpers/constants";
import CharacterCounter from "../../containers/character-counter/character-counter";

import CancelIcon from "@material-ui/icons/Cancel";

import "../diversion-centre/diversion-centre.scss";
import { connect } from "react-redux";
import store from "../../store";

import Styles from "../shared/css/default.module.css";

import moment from "moment";

class ClientMedicalRiskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAmbulance: false,
      isAggression: false,
      isOnMedication: false,
      medicationName: "",
      isArrangements: 0,
      medicationDate: "",
      medicationTime: "",
      risks: [
        {
          symptoms: 0,
        },
      ],
      isAssistance: false,
      observations: [
        {
          behaviour: 0,
        },
      ],
    };
    this.addMore = this.addMore.bind(this);
    this.removeMedical = this.removeMedical.bind(this);
    this.onChange = this.onChange.bind(this);

    this.addMoreBehaviour = this.addMoreBehaviour.bind(this);
    this.removeMedicalBehaviour = this.removeMedicalBehaviour.bind(this);

    this.options2 = [
      { label: "", value: "", selected: true, disabled: true },
      { label: "1. Cannot sit, stand or walk properly", value: 1 },
      { label: "2. Unconscious and/or unable to be woken", value: 2 },
      { label: "3. Becoming more vague and less sensible over time", value: 3 },
      {
        label:
          "4. Serious injury, including head injury, bleeding in particular from the mouth or ears",
        value: 4,
      },
      { label: "5. Other signs of injuries such as cuts or bruises", value: 5 },
      { label: "6. Cannot stop vomiting, or vomits up blood", value: 6 },
      { label: "7. Epileptic fit or fainting", value: 7 },
      { label: "8. Asthma attack", value: 8 },
      {
        label:
          "9. Difficult or noisy breathing, including cracking or wheezing",
        value: 9,
      },
      { label: "10. Chest, head, stomach, leg, arm pain", value: 10 },
      { label: "11. Diarrhea", value: 11 },
      {
        label:
          "12. Showing signs of recently taking drugs and/or inhaled fumes",
        value: 12,
      },
      {
        label:
          "13. Withdrawal symptoms – especially from alcohol (anxious, aggressive, irritable, cannot sleep, tremor’s)",
        value: 13,
      },
    ];

    this.options3 = [
      { label: "", value: "", selected: true, disabled: true },
      {
        label: "1. Threatening or looking for ways to hurt or kill themselves",
        value: 1,
      },
      {
        label: "2. Talking or writing about death, dying or suicide",
        value: 2,
      },
      {
        label:
          "3. Giving away possessions or saying goodbye to family and/or friends, and/or saying they have no reason for living or have no purpose in life",
        value: 3,
      },
      { label: "4. Expressing feelings of hopelessness", value: 4 },
      {
        label:
          "5. Rage, extreme anger or expressions of revenge against a person",
        value: 5,
      },
      { label: "6. Engaging in reckless or risky bahavious", value: 6 },
      {
        label:
          "7. Anxious, agitated and/or expressing feelings of being trapped, like there’s no way out",
        value: 7,
      },
      {
        label: "8. Has discussed the increased use of alcohol or other drugs",
        value: 8,
      },
      {
        label:
          "9. Has discussed withdrawing from friends, family or the community",
        value: 9,
      },
      {
        label:
          "10. Has discussed abnormal sleep patterns – not sleeping or not being able to sleep without waking constantly",
        value: 10,
      },
      {
        label:
          "11. Dramatic changes in mood, such as feelings of happiness after a long period of sadness or depression",
        value: 11,
      },
    ];
  }

  handleDropDown(e, name, index) {
    let risks = this.state.risks;
    if (e[0]) {
      risks[index][name] = e[0].value;

      this.setState({ risks }, () => {
        store.dispatch({
          type: MEDICAL_OBSERVATION,
          payload: this.state.risks,
        });
        store.dispatch({
          type: IS_MEDICAL_OBSERVATION,
          payload: true,
        });
      });
    }
    //
  }

  handleDropDownBehaviour(e, name, index) {
    let observations = this.state.observations;
    if (e[0]) {
      observations[index][name] = e[0].value;

      this.setState({ observations }, () => {
        store.dispatch({
          type: WELLBIENG_OBSERVATION,
          payload: this.state.observations,
        });
        store.dispatch({
          type: IS_WELLBIENG_OBSERVATION,
          payload: true,
        });
      });
    }
    //
  }

  onChange(e, loc, id) {
    this.props.onFieldChange(e, loc);
    let name = e.target.name;
    let stateData = this.state;

    if (loc === "check") {
      stateData[name] = eval(e.target.value);
      this.setState({ ...stateData });
    } else {
      stateData[name] = e.target.value;
      this.setState({ ...stateData });
    }
  }

  addMoreBehaviour() {
    let observationsObjs = this.state.observations;
    observationsObjs.push({
      behaviour: 0,
    });
    this.setState({ observations: observationsObjs });
  }

  addMore() {
    let risksObjs = this.state.risks;
    risksObjs.push({
      symptoms: 0,
    });
    this.setState({ risks: risksObjs });
  }

  removeMedical(index) {
    let risks = this.state.risks;
    risks.splice(index, 1);
    this.setState({ risks });
  }

  removeMedicalBehaviour(index) {
    let observations = this.state.observations;
    observations.splice(index, 1);
    this.setState({ observations });
  }

  // componentDidMount() {
  //   if (this.props.medObs) {
  //     let allProps = this.props.medObs;
  //     this.setState(
  //       {
  //         isAmbulance: allProps.isAmbulance,
  //         isOnMedication: allProps.isOnMedication,
  //         isAggression: allProps.isAggression,
  //         medicationName: allProps.medicationName || "",
  //         isArrangements: allProps.isArrangements,
  //         medicationDate: allProps.medicationDate || "",
  //         medicationTime: allProps.medicationTime || "",
  //         risks:
  //           allProps.MedicalObservations && allProps.MedicalObservations.length
  //             ? allProps.MedicalObservations
  //             : [{ symptoms: "" }],
  //       },
  //       () => {
  //         store.dispatch({
  //           type: MEDICAL_OBSERVATION,
  //           payload: this.state.risks,
  //         });
  //         store.dispatch({
  //           type: IS_MEDICAL_OBSERVATION,
  //           payload: true,
  //         });
  //       }
  //     );
  //   }
  // }

  componentDidMount() {
    if (this.props.medObs) {
      let allProps = this.props.medObs;
      this.setState(
        {
          isAmbulance: allProps.isAmbulance,
          isOnMedication: allProps.isOnMedication,
          isAggression: allProps.isAggression,
          medicationName: allProps.medicationName || "",
          isArrangements: allProps.isArrangements,
          medicationDate: allProps.medicationDate || "",
          medicationTime: allProps.medicationTime || "",
          risks:
            allProps.MedicalObservations && allProps.MedicalObservations.length
              ? allProps.MedicalObservations
              : [{ symptoms: "" }],

          isAssistance: allProps.isAssistance,
          observations:
            allProps.WellBeingObservations &&
            allProps.WellBeingObservations.length
              ? allProps.WellBeingObservations
              : [{ behaviour: "" }],
        },
        () => {
          store.dispatch({
            type: MEDICAL_OBSERVATION,
            payload: this.state.risks,
          });
          store.dispatch({
            type: IS_MEDICAL_OBSERVATION,
            payload: true,
          });
          store.dispatch({
            type: WELLBIENG_OBSERVATION,
            payload: this.state.observations,
          });
          store.dispatch({
            type: IS_WELLBIENG_OBSERVATION,
            payload: true,
          });
        }
      );
    }
  }

  // render() {
  //   return (
  //     <>
  //       <Col md={12} className="p-0">
  //         <Row className={"d-flex align-items-center p-2 bg-grey"}>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Does an ambulance need to be contacted?
  //                 </Form.Label>
  //               </Col>
  //               <Col md={4}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={!this.state.isAmbulance}
  //                   disabled={this.props.disabled}
  //                   value={false}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name={"isAmbulance"}
  //                   type="radio"
  //                   id={"community-checkbox153"}
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor={"community-checkbox153"}>No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={6}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={this.state.isAmbulance}
  //                   disabled={this.props.disabled}
  //                   value={true}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name={"isAmbulance"}
  //                   type="radio"
  //                   id={"community-checkbox154"}
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor={"community-checkbox154"}>Yes</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>

  //         <Row className={"d-flex align-items-center p-2 bg-grey"}>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Is the client on any medication?
  //                 </Form.Label>
  //               </Col>
  //               <Col md={4}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={!this.state.isOnMedication}
  //                   disabled={this.props.disabled}
  //                   value={false}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name={"isOnMedication"}
  //                   type="radio"
  //                   id={"community-checkbox151"}
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor={"community-checkbox151"}>No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={this.state.isOnMedication}
  //                   value={true}
  //                   disabled={this.props.disabled}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name={"isOnMedication"}
  //                   type="radio"
  //                   id={"community-checkbox152"}
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor={"community-checkbox152"}>
  //                   Yes. If yes name the medication.
  //                 </label>
  //               </Col>
  //               <Col md={12}>
  //                 <Form.Group controlId="exampleForm.ControlTextarea1">
  //                   <CharacterCounter>
  //                     <Form.Control
  //                       disabled={
  //                         !this.state.isOnMedication || this.props.disabled
  //                       }
  //                       value={this.state.medicationName}
  //                       onChange={(e) => this.onChange(e)}
  //                       name={"medicationName"}
  //                       as="textarea"
  //                       rows={2}
  //                       maxLength={255}
  //                     />
  //                   </CharacterCounter>
  //                 </Form.Group>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>

  //         <Row className={"d-flex align-items-center p-2 bg-grey"}>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Do arrangements need to be made for medication to be
  //                   provided to the client?
  //                 </Form.Label>
  //               </Col>
  //               <Col md={4}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={
  //                     !this.state.isArrangements ||
  //                     this.state.isArrangements == 0
  //                   }
  //                   disabled={this.props.disabled}
  //                   value={0}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name={"isArrangements"}
  //                   type="radio"
  //                   id={"community-checkbox257"}
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor={"community-checkbox257"}>No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={4}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={this.state.isArrangements == 1}
  //                   value={1}
  //                   disabled={this.props.disabled}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name={"isArrangements"}
  //                   type="radio"
  //                   id={"community-checkbox258"}
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor={"community-checkbox258"}>Unknown</label>
  //               </Col>
  //               <Col md={8}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={this.state.isArrangements == 2}
  //                   value={2}
  //                   disabled={this.props.disabled}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name={"isArrangements"}
  //                   type="radio"
  //                   id={"community-checkbox259"}
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor={"community-checkbox259"}>
  //                   Yes. If yes, arrange for client to be taken to a doctor.
  //                 </label>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>

  //         <Row className={"d-flex align-items-center p-2 bg-grey"}>
  //           <Col md={8}>
  //             <Row className="d-flex align-items-center">
  //               <Col md={6}>
  //                 <Form.Label className="text-label">
  //                   If applicable when did the client last take their
  //                   medication?
  //                 </Form.Label>
  //               </Col>
  //               <Col md={6}>
  //                 <Form.Control
  //                   max={moment().format("YYYY-MM-DD").toString().trim()}
  //                   type="date"
  //                   value={this.state.medicationDate}
  //                   name={"medicationDate"}
  //                   onChange={(e) => this.onChange(e)}
  //                   className="form-field-border custom-width"
  //                 />
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={4}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   type="time"
  //                   value={this.state.medicationTime}
  //                   name={"medicationTime"}
  //                   disabled={this.props.disabled}
  //                   onChange={(e) => this.onChange(e)}
  //                   className="form-field-border custom-width"
  //                 />
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>

  //         <Row className="d-flex align-items-center pl-0">
  //           <Col md={12}>
  //             <div className="client-record">
  //               <h6>GENERAL OBSERVATIONS</h6>
  //             </div>
  //           </Col>
  //         </Row>

  //         <Row className="bg-grey d-flex align-items-center p-2">
  //           <Col md={6}>
  //             <Row>
  //               <Col md={8}>
  //                 <Form.Label className="text-label">
  //                   Is the client currently showing any signs of aggression e.g.
  //                   spitting, swearing, hitting, punching?
  //                 </Form.Label>
  //               </Col>
  //               <Col md={4}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={!this.state.isAggression}
  //                   disabled={this.props.disabled}
  //                   value={false}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name="isAggression"
  //                   type="radio"
  //                   id="community-checkbox59"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox59">No</label>
  //               </Col>
  //             </Row>
  //           </Col>
  //           <Col md={6}>
  //             <Row>
  //               <Col md={12}>
  //                 <Form.Control
  //                   key={Math.random()}
  //                   checked={this.state.isAggression}
  //                   disabled={this.props.disabled}
  //                   value={true}
  //                   onChange={(e) => this.onChange(e, "check")}
  //                   name="isAggression"
  //                   type="radio"
  //                   id="community-checkbox60"
  //                   className="custom-checkbox"
  //                 />
  //                 <label htmlFor="community-checkbox60">
  //                   Yes. If yes, don not approach the client - First assess
  //                   whether a medical and/or police response is required.
  //                 </label>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>

  //         {this.state.risks.map((risk, index) => (
  //           <Row
  //             key={"medicalRisk" + index}
  //             className="d-flex align-items-center p-2"
  //           >
  //             <Col md={4}>
  //               <Row className="pl-2">
  //                 {index === 0 && (
  //                   <Form.Label className="text-label">
  //                     Does the client have any of the following symptoms?
  //                   </Form.Label>
  //                 )}
  //               </Row>
  //             </Col>
  //             <Col md={8}>
  //               <Row>
  //                 <Col md={12}>
  //                   <Row className="d-flex align-items-center pl-3">
  //                     <Col md={6}>
  //                       <Select
  //                         className="selection-select arrow-down custom-width font-bold"
  //                         placeholder="Cannot sit, stand or walk properly"
  //                         options={[
  //                           ...this.options2.filter(
  //                             (option2) =>
  //                               !this.state.risks
  //                                 .map((innerRisk) => {
  //                                   return innerRisk.symptoms;
  //                                 })
  //                                 .includes(option2.value)
  //                           ),
  //                         ]}
  //                         values={[
  //                           ...this.options2.filter(
  //                             (elem) =>
  //                               elem.value ==
  //                               (eval(risk.symptoms) ? eval(risk.symptoms) : "")
  //                           ),
  //                         ]}
  //                         name="symptoms"
  //                         onChange={(e) =>
  //                           this.handleDropDown(e, "symptoms", index)
  //                         }
  //                       />
  //                     </Col>
  //                     <Col md={4}>
  //                       <div className="btn-sec full-width text-left form-btn">
  //                         {index == this.state.risks.length - 1 && (
  //                           <button
  //                             disabled={!this.state.risks[index]["symptoms"]}
  //                             type="button"
  //                             onClick={this.addMore}
  //                             className="main-btn default-btn btn-width symtoms-btn btn btn-primary"
  //                           >
  //                             ADD MORE
  //                           </button>
  //                         )}
  //                       </div>
  //                     </Col>
  //                     <Col md={2}>
  //                       {this.state.risks.length > 1 && (
  //                         <CancelIcon
  //                           onClick={() => this.removeMedical(index)}
  //                           className="float-right"
  //                         ></CancelIcon>
  //                       )}
  //                     </Col>
  //                   </Row>
  //                 </Col>
  //               </Row>
  //             </Col>
  //           </Row>
  //         ))}
  //       </Col>
  //     </>
  //   );
  // }

  render() {
    const checkBoxStyles = {
      appearance: "none",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "#f4f4f4",
    };

    return (
      <>
        {/* <Col md={12} className="p-0">
          <Row className={"d-flex align-items-center p-2 bg-grey"}>
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Does an ambulance need to be contacted?
                  </Form.Label>
                </Col>
                <Col md={4}>
                  <Form.Control
                    key={Math.random()}
                    checked={!this.state.isAmbulance}
                    disabled={this.props.disabled}
                    value={false}
                    onChange={(e) => this.onChange(e, "check")}
                    name={"isAmbulance"}
                    type="radio"
                    id={"community-checkbox153"}
                    className="custom-checkbox"
                  />
                  <label htmlFor={"community-checkbox153"}>No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <Form.Control
                    key={Math.random()}
                    checked={this.state.isAmbulance}
                    disabled={this.props.disabled}
                    value={true}
                    onChange={(e) => this.onChange(e, "check")}
                    name={"isAmbulance"}
                    type="radio"
                    id={"community-checkbox154"}
                    className="custom-checkbox"
                  />
                  <label htmlFor={"community-checkbox154"}>Yes</label>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"d-flex align-items-center p-2 bg-grey"}>
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Is the client on any medication?
                  </Form.Label>
                </Col>
                <Col md={4}>
                  <Form.Control
                    key={Math.random()}
                    checked={!this.state.isOnMedication}
                    disabled={this.props.disabled}
                    value={false}
                    onChange={(e) => this.onChange(e, "check")}
                    name={"isOnMedication"}
                    type="radio"
                    id={"community-checkbox151"}
                    className="custom-checkbox"
                  />
                  <label htmlFor={"community-checkbox151"}>No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Form.Control
                    key={Math.random()}
                    checked={this.state.isOnMedication}
                    value={true}
                    disabled={this.props.disabled}
                    onChange={(e) => this.onChange(e, "check")}
                    name={"isOnMedication"}
                    type="radio"
                    id={"community-checkbox152"}
                    className="custom-checkbox"
                  />
                  <label htmlFor={"community-checkbox152"}>
                    Yes. If yes name the medication.
                  </label>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <CharacterCounter>
                      <Form.Control
                        disabled={
                          !this.state.isOnMedication || this.props.disabled
                        }
                        value={this.state.medicationName}
                        onChange={(e) => this.onChange(e)}
                        name={"medicationName"}
                        as="textarea"
                        rows={2}
                        maxLength={255}
                      />
                    </CharacterCounter>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"d-flex align-items-center p-2 bg-grey"}>
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Do arrangements need to be made for medication to be
                    provided to the client?
                  </Form.Label>
                </Col>
                <Col md={4}>
                  <Form.Control
                    key={Math.random()}
                    checked={
                      !this.state.isArrangements ||
                      this.state.isArrangements == 0
                    }
                    disabled={this.props.disabled}
                    value={0}
                    onChange={(e) => this.onChange(e, "check")}
                    name={"isArrangements"}
                    type="radio"
                    id={"community-checkbox257"}
                    className="custom-checkbox"
                  />
                  <label htmlFor={"community-checkbox257"}>No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <Form.Control
                    key={Math.random()}
                    checked={this.state.isArrangements == 1}
                    value={1}
                    disabled={this.props.disabled}
                    onChange={(e) => this.onChange(e, "check")}
                    name={"isArrangements"}
                    type="radio"
                    id={"community-checkbox258"}
                    className="custom-checkbox"
                  />
                  <label htmlFor={"community-checkbox258"}>Unknown</label>
                </Col>
                <Col md={8}>
                  <Form.Control
                    key={Math.random()}
                    checked={this.state.isArrangements == 2}
                    value={2}
                    disabled={this.props.disabled}
                    onChange={(e) => this.onChange(e, "check")}
                    name={"isArrangements"}
                    type="radio"
                    id={"community-checkbox259"}
                    className="custom-checkbox"
                  />
                  <label htmlFor={"community-checkbox259"}>
                    Yes. If yes, arrange for client to be taken to a doctor.
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"d-flex align-items-center p-2 bg-grey"}>
            <Col md={8}>
              <Row className="d-flex align-items-center">
                <Col md={6}>
                  <Form.Label className="text-label">
                    If applicable when did the client last take their
                    medication?
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Control
                    max={moment().format("YYYY-MM-DD").toString().trim()}
                    type="date"
                    value={this.state.medicationDate}
                    name={"medicationDate"}
                    onChange={(e) => this.onChange(e)}
                    className="form-field-border custom-width"
                  />
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <Form.Control
                    type="time"
                    value={this.state.medicationTime}
                    name={"medicationTime"}
                    disabled={this.props.disabled}
                    onChange={(e) => this.onChange(e)}
                    className="form-field-border custom-width"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="d-flex align-items-center pl-0">
            <Col md={12}>
              <div className="client-record">
                <h6>GENERAL OBSERVATIONS</h6>
              </div>
            </Col>
          </Row>

          <Row className="bg-grey d-flex align-items-center p-2">
            <Col md={6}>
              <Row>
                <Col md={8}>
                  <Form.Label className="text-label">
                    Is the client currently showing any signs of aggression e.g.
                    spitting, swearing, hitting, punching?
                  </Form.Label>
                </Col>
                <Col md={4}>
                  <Form.Control
                    key={Math.random()}
                    checked={!this.state.isAggression}
                    disabled={this.props.disabled}
                    value={false}
                    onChange={(e) => this.onChange(e, "check")}
                    name="isAggression"
                    type="radio"
                    id="community-checkbox59"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox59">No</label>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Form.Control
                    key={Math.random()}
                    checked={this.state.isAggression}
                    disabled={this.props.disabled}
                    value={true}
                    onChange={(e) => this.onChange(e, "check")}
                    name="isAggression"
                    type="radio"
                    id="community-checkbox60"
                    className="custom-checkbox"
                  />
                  <label htmlFor="community-checkbox60">
                    Yes. If yes, don not approach the client - First assess
                    whether a medical and/or police response is required.
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>

          {this.state.risks.map((risk, index) => (
            <Row
              key={"medicalRisk" + index}
              className="d-flex align-items-center p-2"
            >
              <Col md={4}>
                <Row className="pl-2">
                  {index === 0 && (
                    <Form.Label className="text-label">
                      Does the client have any of the following symptoms?
                    </Form.Label>
                  )}
                </Row>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={12}>
                    <Row className="d-flex align-items-center pl-3">
                      <Col md={6}>
                        <Select
                          className="selection-select arrow-down custom-width font-bold"
                          placeholder="Cannot sit, stand or walk properly"
                          options={[
                            ...this.options2.filter(
                              (option2) =>
                                !this.state.risks
                                  .map((innerRisk) => {
                                    return innerRisk.symptoms;
                                  })
                                  .includes(option2.value)
                            ),
                          ]}
                          values={[
                            ...this.options2.filter(
                              (elem) =>
                                elem.value ==
                                (eval(risk.symptoms) ? eval(risk.symptoms) : "")
                            ),
                          ]}
                          name="symptoms"
                          onChange={(e) =>
                            this.handleDropDown(e, "symptoms", index)
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <div className="btn-sec full-width text-left form-btn">
                          {index == this.state.risks.length - 1 && (
                            <button
                              disabled={!this.state.risks[index]["symptoms"]}
                              type="button"
                              onClick={this.addMore}
                              className="main-btn default-btn btn-width symtoms-btn btn btn-primary"
                            >
                              ADD MORE
                            </button>
                          )}
                        </div>
                      </Col>
                      <Col md={2}>
                        {this.state.risks.length > 1 && (
                          <CancelIcon
                            onClick={() => this.removeMedical(index)}
                            className="float-right"
                          ></CancelIcon>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Col> */}

        <div className={Styles.yes_block} style={{ backgroundColor: "#fff" }}>
          <div className={`${Styles.form_group} ${Styles.notice_block}`}>
            <div className="row">
              {this.state.observations.map((observation, index) => (
                <>
                  <div
                    className="col-xl-6 col-md-6"
                    key={"Observation" + index}
                  >
                    {index === 0 && (
                      <label>
                        If you notice the client is acting strangely or
                        particularly out tof character it is important that you
                        talk to them about it. Select any of the following
                        behaviours shown by the client.
                      </label>
                    )}
                  </div>
                  {/* <div className="col-xl-5 col-md-6"> */}
                  {/* {this.state.observations.map((observation, index) => ( */}
                  <div
                    // className="col-xl-5 col-md-6"
                    className="col-xl-5 col-md-5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {/* <select
                  name=""
                  id=""
                  className={`${Styles.form_select} ${Styles.select_small}`}
                >
                  <option value="">Please list all items below</option>
                  <option value="">one</option>
                  <option value="">two</option>
                </select>
                <a
                  href="#"
                  className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
                >
                  Add More
                </a> */}

                    <>
                      <div style={{ width: "80%" }} key={"Observation" + index}>
                        <Select
                          // className={`${Styles.form_select} ${Styles.select_small}`}
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                          placeholder="Threatening or looking for ways to..."
                          options={[
                            ...this.options3.filter(
                              (option3) =>
                                !this.state.observations
                                  .map((observation) => {
                                    return observation.behaviour;
                                  })
                                  .includes(option3.value)
                            ),
                          ]}
                          values={[
                            ...this.options3.filter(
                              (elem) =>
                                elem.value ==
                                (eval(observation.behaviour)
                                  ? eval(observation.behaviour)
                                  : "")
                            ),
                          ]}
                          name="behaviour"
                          onChange={(e) =>
                            this.handleDropDownBehaviour(e, "behaviour", index)
                          }
                          style={{
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            backgroundImage: "none",
                          }}
                        />
                      </div>
                      {this.state.observations.length > 1 && (
                        <div style={{ marginLeft: "10px" }}>
                          <CancelIcon
                            onClick={() => this.removeMedicalBehaviour(index)}
                            disabled={this.props.disabled}
                            // className="float-right"
                            sx={{ paddingLeft: "10px", fontSize: "3rem" }}
                          ></CancelIcon>
                        </div>
                      )}

                      {index == this.state.observations.length - 1 && (
                        // <a
                        //   href="#"
                        //   className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
                        // >
                        //   Add More
                        // </a>
                        <button
                          disabled={
                            !this.state.observations[index]["behaviour"] ||
                            this.props.disabled
                          }
                          type="button"
                          onClick={this.addMoreBehaviour}
                          className={`${Styles.btn} ${Styles.btn_small} ${Styles.btn_yellow}`}
                        >
                          ADD MORE
                        </button>
                      )}
                    </>
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className={`${Styles.form_group}`}>
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-5 col-md-5">
                <label for="">Does an ambulance need to be contacted?</label>
              </div>
              <div className="col-xl-6 col-lg-7 col-md-7">
                <div className={Styles.radio_custom}>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      key={Math.random()}
                      checked={!this.state.isAmbulance}
                      disabled={this.props.disabled}
                      value={false}
                      onChange={(e) => this.onChange(e, "check")}
                      name={"isAmbulance"}
                      type="radio"
                      id={"community-checkbox153"}
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      htmlFor="community-checkbox153"
                    >
                      No
                    </label>
                  </div>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      key={Math.random()}
                      checked={this.state.isAmbulance}
                      disabled={this.props.disabled}
                      value={true}
                      onChange={(e) => this.onChange(e, "check")}
                      name={"isAmbulance"}
                      type="radio"
                      id={"community-checkbox154"}
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      htmlFor="community-checkbox154"
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${Styles.form_group} ${Styles.medication_block}`}>
            {/* <div className="row align-items-center"> */}
            <div className="row">
              <div className="col-xl-6 col-lg-5 col-md-5">
                <label for="">Is the client on any medication?</label>
              </div>
              <div className="col-xl-6 col-lg-7 col-md-7">
                <div className={Styles.radio_custom}>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      key={Math.random()}
                      checked={!this.state.isOnMedication}
                      disabled={this.props.disabled}
                      value={false}
                      onChange={(e) => this.onChange(e, "check")}
                      name={"isOnMedication"}
                      type="radio"
                      id={"community-checkbox151"}
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      htmlFor="community-checkbox151"
                    >
                      No
                    </label>
                  </div>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      key={Math.random()}
                      checked={this.state.isOnMedication}
                      value={true}
                      disabled={this.props.disabled}
                      onChange={(e) => this.onChange(e, "check")}
                      name={"isOnMedication"}
                      type="radio"
                      id={"community-checkbox152"}
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginTop: 0, marginBottom: 0 }}
                      htmlFor="community-checkbox152"
                    >
                      Yes,If yes name the medication.
                    </label>
                  </div>
                </div>
                <div className={Styles.textarea_block}>
                  <textarea
                    className={Styles.form_control}
                    value={this.state.medicationName}
                    id=""
                    rows="3"
                    style={{ width: "100%" }}
                    disabled={!this.state.isOnMedication}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {/* Remaining --------****--------- */}
          {/* <div className={`${Styles.form_group} ${Styles.domestic_block}`}>
            <div className="row">
              <div className="col-xl-6 col-lg-5 col-md-5">
                <label for="">
                  Are there any “No contact” Domestic Violence orders in place?
                </label>
              </div>
              <div className="col-xl-6 col-lg-7 col-md-7">
                <div className={Styles.radio_custom}>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      type="radio"
                      name="radio-nocontact"
                      id="no-nocontact"
                      value="No"
                      checked
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      for="no-nocontact"
                    >
                      No
                    </label>
                  </div>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      type="radio"
                      name="radio-nocontact"
                      id="yes-nocontact"
                      value="Yes"
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      for="yes-nocontact"
                    >
                      Yes,If yes name the medication.
                    </label>
                  </div>
                </div>
                <div className={Styles.textarea_block}>
                  <textarea
                    className={Styles.form_control}
                    id=""
                    rows="3"
                    style={{ width: "100%" }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div> */}
          {/* --------****--------- */}

          <div className={`${Styles.form_group} ${Styles.arrangements_block}`}>
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-5 col-md-5">
                <label for="">
                  Do arrangements need to be made for medivation to be provided
                  to the client?
                </label>
              </div>
              <div className="col-xl-6 col-lg-7 col-md-7">
                <div className={`${Styles.radio_custom} d-lg-flex`}>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      key={Math.random()}
                      checked={
                        !this.state.isArrangements ||
                        this.state.isArrangements == 0
                      }
                      disabled={this.props.disabled}
                      value={0}
                      onChange={(e) => this.onChange(e, "check")}
                      name={"isArrangements"}
                      type="radio"
                      id={"community-checkbox257"}
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      htmlFor="community-checkbox257"
                    >
                      No
                    </label>
                  </div>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      key={Math.random()}
                      checked={this.state.isArrangements == 1}
                      value={1}
                      disabled={this.props.disabled}
                      onChange={(e) => this.onChange(e, "check")}
                      name={"isArrangements"}
                      type="radio"
                      id={"community-checkbox258"}
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      htmlFor="community-checkbox258"
                    >
                      Unknown
                    </label>
                  </div>
                  <div
                    className={`${Styles.form_check} form-check-inline ${Styles.form_check_inline}`}
                  >
                    <input
                      className={`${Styles.form_check_input} form-check-input`}
                      key={Math.random()}
                      checked={this.state.isArrangements == 2}
                      value={2}
                      disabled={this.props.disabled}
                      onChange={(e) => this.onChange(e, "check")}
                      name={"isArrangements"}
                      type="radio"
                      id={"community-checkbox259"}
                      style={checkBoxStyles}
                    />
                    <label
                      className={Styles.form_check_label}
                      style={{ marginBottom: "0", marginTop: "0" }}
                      htmlFor="community-checkbox259"
                    >
                      Yes, If yes, arrange for client to be taken to a doctor.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${Styles.form_group} ${Styles.appMedication_block}`}>
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-5 col-md-5">
                <label for="">
                  If applicable when did the client last take their medication?
                </label>
              </div>
              <div
                className="col-xl-6 col-lg-7 col-md-7"
                style={{ paddingBottom: "10px" }}
              >
                <div className="row g-3">
                  <div className="col-7">
                    <input
                      className={Styles.form_control}
                      style={{ width: "100%" }}
                      max={moment().format("YYYY-MM-DD").toString().trim()}
                      type="date"
                      value={this.state.medicationDate}
                      name={"medicationDate"}
                      onChange={(e) => this.onChange(e)}
                    />
                  </div>
                  <div className="col-5">
                    <input
                      className={Styles.form_control}
                      style={{ width: "100%" }}
                      type="time"
                      value={this.state.medicationTime}
                      name={"medicationTime"}
                      disabled={this.props.disabled}
                      onChange={(e) => this.onChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-end">
            <a href="#" className="btn btn-yellow">
              Save
            </a>
            <a href="#" className="btn btn-red">
              Checkout
            </a>
          </div> */}
        </div>
      </>
    );
  }
}

const stateMap = (state) => {
  return {
    isMedicalObservation: state.medicalObservation.isMedicalObservation,
    medicalObservations: state.medicalObservation.medicalObservations,
  };
};

export default connect(stateMap)(ClientMedicalRiskForm);
