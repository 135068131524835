import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import Dashboard from "../../components/dashboard/dashboard";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";
import Styles from "../../components/shared/css/default.module.css";
// import "../../components/shared/css/default.module.css";

export default class MainDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
      isSidebarOpen: false,
    };
  }
  render() {
    return (
      <>
        {/* <div className="dashboard-content" style={{ display: "flex" }}> */}
        <div
          id={Styles["wrapper"]}
          className={this.state.isSidebarOpen ? Styles.toggled : ""}
        >
          {/* <Header
            handleSidebar={() =>
              this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
            }
            {...this.props}
          /> */}
          <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
            {/* <Dashboard {...this.props} /> */}
          </Sidebar>
          <Header
            handleSidebar={() =>
              this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
            }
            {...this.props}
          />
          <Dashboard {...this.props} />
        </div>
      </>
    );
  }
}
