import React from "react";
import ReportFrom from "../../components/report-form/report-form";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";

import Styles from "../../components/shared/css/default.module.css";

import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";

export default class MainReportFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
      isSidebarOpen: false,
    };
  }
  render() {
    return (
      // <div className="admin-content" style={{ display: 'flex' }}>
      <div
        id={Styles["wrapper"]}
        className={this.state.isSidebarOpen ? Styles.toggled : ""}
      >
        <Sidebar
          isSidebarOpen={this.state.isSidebarOpen}
          {...this.props}
        ></Sidebar>
        <Header
          handleSidebar={() =>
            this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
          }
          {...this.props}
        />
        <ReportFrom {...this.props} />
      </div>
    );
  }
}
