import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TO_LOGIN } from "../../../helpers/constants";
import Form from "react-validation/build/form";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "./password-reset.scss";
import store from "../../../store";
import classNames from "classnames";
import { ResetPasswordRequest } from "../../../actions/user/profile";
import { connect } from "react-redux";

import Styles from "../../shared/css/default.module.css";

const styles = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#A91F2E",
      fontSize: "0.7365728900255755rem",
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem",
      },
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#A91F2E",
      fontSize: "0.7365728900255755rem",
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5728900255754475rem",
      [theme.breakpoints.down("767")]: {
        fontSize: "1.5rem",
      },
    },
  },
});

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      user: {
        email: "",
      },
      show: false,
    };
  }
  handleChange(e) {
    let user = this.state.user;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  }
  handleOnSubmit(e) {
    e.preventDefault();
    store.dispatch(ResetPasswordRequest(this.state.user)); //set in session storage
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  // render() {
  //   const { classes } = this.props;
  //   var alertClasses = classNames({
  //     'alert': true,
  //     'alert-danger': this.props.resetPassword.error ? this.props.resetPassword.error : '',
  //     'alert-success': !this.props.resetPassword.error
  //   });
  //   //
  //   return (
  //     <div className="reset-form">
  //       <div className="container">
  //         <Row className="align-items-center content-row">
  //           <Col md={6}>
  //             <img
  //               className="mb-4 logo-img"
  //               src={require("../../../assets/images/logo.svg")}
  //               alt="logo"
  //             />
  //           </Col>
  //           <Col md={6}>
  //             <div className="register-box">
  //               <h3 className="text-center mb-3">Forget Password</h3>
  //               <p className="text-center">
  //                 Just fill in your email and we’ll send you a link to reset your password.
  //                         </p>
  //               {/* <Form ref={c => { this.form = c }} onSubmit={this.handleOnSubmit}> */}
  //               {Object.keys(this.props.resetPassword).length > 0 && this.props.resetPassword.error && <div className={alertClasses}> {this.props.resetPassword.message}</div>}
  //               {Object.keys(this.props.resetPassword).length > 0 && !this.props.resetPassword.error && <div className={alertClasses}> {this.props.resetPassword.message}</div>}
  //               <ValidatorForm
  //                 ref="form"
  //                 onSubmit={this.handleOnSubmit}
  //                 onError={errors => console.log(errors)}
  //               >
  //                 <Row>
  //                   <Col md={12} className="mb-3">
  //                     <TextValidator
  //                       label="Email"
  //                       // onChange={this.handleChange}
  //                       // name="email"
  //                       // value={email}
  //                       variant="outlined"
  //                       value={this.state.user.email}
  //                       name="email"
  //                       onChange={this.handleChange}
  //                       fullWidth
  //                       className={classes.root} id="outlined-basic"
  //                       validators={['required', 'isEmail']}
  //                       errorMessages={['this field is required', 'email is not valid']}
  //                     />
  //                     {/* <TextField className={classes.root} id="outlined-basic" label="Email" variant="outlined" fullWidth /> */}
  //                   </Col>
  //                 </Row>
  //                 <Row className="mt-4">
  //                   <Col md={12} className="register-btn">
  //                     <Button variant="primary" type="submit" size="lg" block>
  //                       RESET PASSWORD
  //                                     </Button>
  //                   </Col>
  //                 </Row>
  //                 <Row>
  //                   <Col md={12} className="text-center pt-4 color-alert">
  //                     <Link to={TO_LOGIN}>
  //                       LOGIN
  //                                     </Link>
  //                   </Col>
  //                 </Row>
  //               </ValidatorForm>
  //             </div>
  //           </Col>
  //         </Row>
  //       </div>
  //     </div>

  //   );
  // }

  render() {
    const { classes } = this.props;
    var alertClasses = classNames({
      alert: true,
      "alert-danger": this.props.resetPassword.error
        ? this.props.resetPassword.error
        : "",
      "alert-success": !this.props.resetPassword.error,
    });
    //
    return (
      <div className="reset-form">
        <div className="container">
          <Row className="align-items-center content-row">
            <Col md={6}>
              <img
                className="mb-4 logo-img"
                // src={require("../../../assets/images/logo.svg")}
                src={require("../../../assets/images/yumbametalogo.svg")}
                alt="logo"
              />
            </Col>
            <Col md={6}>
              <div className="register-box">
                <h3 className="text-center mb-3">Forget Password</h3>
                <p className="text-center">
                  Just fill in your email and we’ll send you a link to reset
                  your password.
                </p>
                {/* <Form ref={c => { this.form = c }} onSubmit={this.handleOnSubmit}> */}
                {Object.keys(this.props.resetPassword).length > 0 &&
                  this.props.resetPassword.error && (
                    <div className={alertClasses}>
                      {" "}
                      {this.props.resetPassword.message}
                    </div>
                  )}
                {Object.keys(this.props.resetPassword).length > 0 &&
                  !this.props.resetPassword.error && (
                    <div className={alertClasses}>
                      {" "}
                      {this.props.resetPassword.message}
                    </div>
                  )}
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleOnSubmit}
                  onError={(errors) => console.log(errors)}
                >
                  <Row>
                    <Col md={12} className="mb-3">
                      <TextValidator
                        label="Email"
                        // onChange={this.handleChange}
                        // name="email"
                        // value={email}
                        variant="outlined"
                        value={this.state.user.email}
                        name="email"
                        onChange={this.handleChange}
                        fullWidth
                        className={classes.root}
                        id="outlined-basic"
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                      />
                      {/* <TextField className={classes.root} id="outlined-basic" label="Email" variant="outlined" fullWidth /> */}
                    </Col>
                  </Row>
                  <Row className="mt-4" style={{ justifyContent: "center" }}>
                    {/* <Col md={12} className="register-btn">
                      <Button variant="primary" type="submit" size="lg" block>
                        RESET PASSWORD
                      </Button>
                    </Col> */}

                    <div
                      className={`${Styles.report_btn} text-end`}
                      style={{ textAlign: "end", padding: "0", width: "95%" }}
                    >
                      <button
                        type="submit"
                        className={`${Styles.btn} ${Styles.btn_red}`}
                        style={{
                          width: "100%",
                          paddingTop: "25px",
                          paddingBottom: "25px",
                        }}
                      >
                        RESET PASSWORD
                      </button>
                    </div>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center pt-4 color-alert">
                      <Link to={TO_LOGIN}>LOGIN</Link>
                    </Col>
                  </Row>
                </ValidatorForm>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    resetPassword: state.user.resetPassword,
  };
};
export default compose(
  connect(stateMap),
  withStyles(styles)
)(PasswordResetForm);
