import React from "react";
import clsx from "clsx";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import "./client-form.scss";
import { getUser } from "../../helpers/utility";

class ClientForm extends React.Component {
  render() {
    return (
      <div className="client-form full-width mt-4">
        <Form>
          <Row className="d-flex align-items-center bg-grey pl-2 pt-2 pb-2">
            <strong>Name</strong>
            <Col>
              <Form.Control
                className="form-fields text-center pt-0"
                placeholder="Kai Aaron"
              />
            </Col>
            <Col>
              <Form.Control
                className="form-fields text-center pt-0"
                placeholder="Smith"
              />
            </Col>
            <Col>
              <Form.Control
                className="form-fields text-center pt-0"
                placeholder="ALIAS"
              />
            </Col>
            <Col>
              <Form.Control
                className="form-fields text-center pt-0"
                placeholder="ALIAS"
              />
            </Col>
            <Col>
              <Form.Control
                className="form-fields text-center pt-0"
                placeholder="ALIAS"
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center pt-2 pb-2">
            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
              <strong className="form-label mt-2">DOB</strong>
            </Col>
            <Col xl={3} lg={3} md={6} sm={12} xs={12}>
              <Form.Control
                type="date"
                className="form-field-border custom-width"
              />
            </Col>
            <Col xl={2} lg={2} md={4} sm={12} xs={12}>
              <input type="checkbox" id="test1" className="custom-checkbox" />
              <label for="test1">Unknown</label>
              <div className="divider"></div>
            </Col>
            <Col xl={2} lg={2} md={4} sm={12} xs={12} className="text-center">
              <strong>Gender</strong>
            </Col>
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <Form.Group
                className="mb-0 select-field"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Control
                  className="form-field-border arrow-down"
                  as="select"
                >
                  <option>Male</option>
                  <option>Female</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="d-flex align-items-center bg-grey pl-2 pt-2 pb-2">
            <Col xl={2} lg={2} md={2} sm={12} xs={12} className="pl-0">
              <strong className="form-label mr-0">Cultural Identity</strong>
            </Col>
            <Col xl={2} lg={2} md={2} sm={3} xs={3}>
              <input type="checkbox" id="test2" className="custom-checkbox" />
              <label for="test2">Aboriginal</label>
            </Col>
            <Col xl={3} lg={3} md={2} sm={3} xs={3} className="pl-2">
              <input type="checkbox" id="test3" className="custom-checkbox" />
              <label for="test3">Torres Strait Islander</label>
            </Col>
            <Col xl={2} lg={2} md={2} sm={3} xs={3} className="pl-4">
              <input type="checkbox" id="test4" className="custom-checkbox" />
              <label for="test4">South Sea</label>
            </Col>
            <Col xl={3} lg={2} md={2} sm={3} xs={3}>
              <input type="checkbox" id="test5" className="custom-checkbox" />
              <label for="test5">Non-Indigenous</label>
            </Col>
          </Row>
          <Row className="d-flex align-items-center pt-2 pb-2">
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <strong className="form-label mt-2">Contact Details</strong>
              <Form.Control
                type="number"
                className="form-field-border custom-width"
                placeholder="Mobile"
              />
            </Col>
            <Col md={1}>
              <div className="divider"></div>
            </Col>
            <Col md={2} className="text-center">
              <strong>Email Address</strong>
            </Col>
            <Col md={3}>
              <Form.Control
                type="email"
                className="form-field-border"
                placeholder="Email Address"
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center bg-grey pt-2 pb-2">
            <Col md={6} className="search-address">
              <strong className="form-label mt-2">Address</strong>
              <Form.Group className="mb-0" controlId="formGridAddress1">
                <Form.Control
                  className="form-field-border custom-width search-icon"
                  placeholder="Search Google Maps"
                />
                {/* <SearchIcon className=""/> */}
              </Form.Group>
            </Col>
            <Col md={1}>
              <input type="checkbox" id="test6" className="custom-checkbox" />
              <label for="test6" className="mb-0">
                Homeless
              </label>
              <input type="checkbox" id="test7" className="custom-checkbox" />
              <label for="test7">Unknown</label>
            </Col>
            <Col md={1}>
              <div className="divider"></div>
            </Col>
            <Col md={2} className="pl-4">
              <input type="checkbox" id="test8" className="custom-checkbox" />
              <label for="test8">Bowman Johnson</label>
            </Col>
            <Col md={2}>
              <Form.Group
                className="mb-1 select-field"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Control className="form-field-border" as="select">
                  <option>Start Date</option>
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="mb-0 select-field"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Control className="form-field-border" as="select">
                  <option>End Date</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div class="btn-sec full-width text-center form-btn">
          <button
            type="submit"
            class="main-btn default-btn btn btn-primary save-btn"
          >
            Edit Client
          </button>
          {getUser().Role && getUser().Role.divertionCenter && (
            <button type="submit" class="main-btn default-btn btn btn-primary">
              Diversion Centre
            </button>
          )}
          {getUser().Role && getUser().Role.cellVisits && (
            <button
              type="submit"
              class="main-btn default-btn btn btn-primary save-btn"
            >
              Cell Visit
            </button>
          )}
          {getUser().Role && getUser().Role.communityPatrol && (
            <button type="submit" class="main-btn default-btn btn btn-primary">
              Community Patrol
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default ClientForm;
