import React from "react";
import { Row, Col } from "react-bootstrap";
import "./client-form.scss";
import CustomForm from "react-validation/build/form";
import Form from "../shared/form-control";
import { required, onlyPast, email } from "../../helpers/form-validation";
import {
  CreateClientAction,
  getClientAction,
  updateClientAction,
} from "../../actions/client-action";
import store from "../../store";
import { connect } from "react-redux";
import { toast } from "../../helpers/utility";
import {
  TO_CELL_VISIT,
  TO_COMMUNITY_PATROL,
  TO_DIVERSION_CENTRE,
  BOWMAN_JOHNSON_ADDRESS,
  CREATE_CLIENT_RES,
  SEARCH_RESULT,
  TO_CLIENTS,
  DATE_FORMAT,
  TO_CREATE_CLIENTS,
} from "../../helpers/constants";
import "react-phone-number-input/style.css";
import { geocodeByAddress } from "react-places-autocomplete";
import InputMask from "react-input-mask";
import { getUser } from "../../helpers/utility";
import CharacterCounter from "../../containers/character-counter/character-counter";

import moment from "moment";
import LocationSearchInput from "../shared/google-places";

import Styles from "../shared/css/default.module.css";

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.state = {
      client: {
        gender: "Male",
      },
      address: "",
      baseClient: {
        firstName: "",
        lastName: "",
        alias1: "",
        alias2: "",
        alias3: "",
        dob: "",
        isDob: false,
        highRisk: false,
        gender: "Male",
        culturalIdentity: "Aboriginal",
        email: "",
        address: "",
        isHomeless: false,
        isAddressTransitional: false,
        isAddressOutOfArea: false,
        isAddressUnkown: false,
        // isBowmanJohnson: false,
        // startDate: "",
        // endDate: "",
        // roomNo: "",
        isDobEstimated: false,
        estimatedDob: 0,
      },
    };
  }
  handlePhone(value) {
    let client = this.state.client;
    client["phone"] = value;
    this.setState({ client });
  }

  handleChange(e) {
    let client = this.state.client;
    client[e.target.name] = e.target.value;
    if (e.target.name === "address") {
      client["address"] = e.target.value;
    }
    // else if (e.target.name === "endDate") {
    //   client.address = client.backupAddress;
    //   client.isAddressUnkown = !!client.isAddressUnkownBackup;
    //   client.isHomeless = !!client.isHomelessBackup;
    //   // client.isBowmanJohnson = false;
    // }
    switch (e.target.name) {
      // case "endDate":
      //   if (e.target.value) {
      //     client.address = client.backupAddress;
      //     client.isAddressUnkown = !!client.isAddressUnkownBackup;
      //     client.isHomeless = !!client.isHomelessBackup;
      //     // client.isBowmanJohnson = false;
      //   } else {
      //     client.address = "";
      //     client.isAddressUnkown = false;
      //     client.isHomeless = false;
      //     // client.isBowmanJohnson = true;
      //   }
      //   break;
      case "estimatedDob":
        if (e.target.value) {
          // var estimatedDate =
          //   "1 / Jan / " + moment().year() + " - " + e.target.value;
          var estimatedDate = moment().year() - e.target.value + "-01-01";
          client["dob"] = estimatedDate;
        }
        break;
      default:
        break;
    }
    console.log("Client:-- ", this.state.client);
    this.setState({ client });
  }

  handleCheck(e) {
    let client = this.state.client;
    client[e.target.name] = e.target.checked;
    switch (e.target.name) {
      // case "isBowmanJohnson":
      //   if (e.target.checked) {
      //     client.address = BOWMAN_JOHNSON_ADDRESS;
      //     client.isAddressUnkown = false;
      //     client.isAddressOutOfArea = false;
      //     client.isAddressTransitional = false;
      //     client.isHomeless = false;
      //   } else client.address = client.backupAddress;
      //   break;
      case "isAddressUnkown":
        client.isHomelessBackup = e.target.checked;
        if (e.target.checked) {
          client.address = "";
          // client.isBowmanJohnson = false;
          client.isHomeless = false;
          client.isAddressTransitional = false;
          client.isAddressOutOfArea = false;
          // client.startDate = "";
          // client.endDate = "";
          // client.roomNo = "";
          client.isHomelessBackup = false;
        } else {
          client.address = client.backupAddress;
          client.isHomeless = client.isHomelessBackup;
        }
        break;
      case "isAddressOutOfArea":
        if (e.target.checked) {
          client.address = "";
          // client.isBowmanJohnson = false;
          client.isHomeless = false;
          client.isAddressTransitional = false;
          client.isAddressOutOfArea = true;
          // client.startDate = "";
          // client.endDate = "";
          // client.roomNo = "";
          client.isHomelessBackup = false;
          client.isAddressUnkown = false;
          client.isAddressUnkownBackup = false;
        }
        break;
      case "isAddressTransitional":
        if (e.target.checked) {
          // client.isBowmanJohnson = false;
          client.isHomeless = false;
          client.isAddressTransitional = true;
          client.isAddressOutOfArea = false;
          // client.startDate = "";
          // client.endDate = "";
          // client.roomNo = "";
          client.isHomelessBackup = false;
          client.isAddressUnkown = false;
          client.isAddressUnkownBackup = false;
        }
        break;
      case "isHomeless":
        client.isAddressUnkownBackup = e.target.checked;
        if (e.target.checked) {
          client.address = "";
          // client.isBowmanJohnson = false;
          client.isAddressUnkown = false;
          client.isAddressUnkownBackup = false;
          client.isAddressTransitional = false;
          client.isAddressOutOfArea = false;
          // client.startDate = "";
          // client.endDate = "";
          // client.roomNo = "";
        } else {
          client.address = client.backupAddress;
          client.isAddressUnkown = client.isAddressUnkownBackup;
        }
        break;
      case "isDob":
        if (e.target.checked) {
          client.dob = "";
          // client.isDobEstimated = true;
          client.isDobEstimated = false;
          client.isDob = true;
        } else {
          // client.dob = client.dobBackup
        }
        break;
      case "isDobEstimated":
        if (e.target.checked) {
          // client.estimatedDob = "";
          // this.state.client.isDob = false;
          client.isDob = false;
          client.isDobEstimated = true;
        } else {
          // client.dob = client.dobBackup
        }
        break;
      default:
        break;
    }
    this.setState({ client: { ...client } });
  }

  handleDobChange(e) {
    let client = this.state.client;
    client["isDobEstimated"] = false;
    client["isDob"] = false;
    switch (e.target.id) {
      case "test1":
        if (e.target.value) {
          client.dob = "";
          client.isDobEstimated = false;
          client.isDob = true;
        }
        break;
      case "test2":
        if (e.target.value) {
          client.isDobEstimated = true;
          client.isDob = false;
        }
      default:
        break;
    }
    this.setState({ client: { ...client } });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.form.validateAll();
    if (this.form.getChildContext()._errors.length === 0) {
      // if (this.state.client.isBowmanJohnson && !this.state.client.startDate) {
      //   toast("Start Date is required", "error");
      //   return;
      // }
      let client = this.state.client;
      if (client.isDob) {
        client["dob"] = null;
      }
      // if (!this.state.client.startDate) {
      //   client["startDate"] = null;
      // }
      // if (!this.state.client.endDate) {
      //   client["endDate"] = null;
      // }
      if (!this.state.client.isDob && !this.state.client.dob) {
        toast("Date of birth is required", "error");
        return;
      }
      if (
        !this.state.client.isAddressUnkown &&
        !this.state.client.isHomeless &&
        !this.state.client.address &&
        !this.state.client.isAddressOutOfArea
      ) {
        toast("Address is required", "error");
        return;
      }
      if (this.props.match.params.id) {
        store.dispatch(updateClientAction(client)); //set in session storage
      } else {
        store.dispatch(CreateClientAction(client)); //set in session storage
        // this.setState({ client: {} })
      }
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      store.dispatch(getClientAction(this.props.match.params.id));
    } else {
      store.dispatch({
        type: CREATE_CLIENT_RES,
        payload: {},
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updated !== prevProps.updated) {
      toast("Updated Successfully");
    }

    if (this.props.created !== prevProps.created) {
      toast("Client Successfully Created");
      setTimeout(() => {
        this.props.history.push(TO_CREATE_CLIENTS + "/" + this.props.client.id);
        window.location.reload();
      }, 500);
      // this.props.history.push(TO_CLIENTS)
    }

    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        client: { ...this.state.client, ...this.state.baseClient },
      });
    }

    if (
      JSON.stringify(prevProps.client) !== JSON.stringify(this.props.client)
    ) {
      if (this.props.match.params.id) {
        if (this.props.client.isDobEstimated) {
          this.setState({
            client: {
              ...this.props.client,
              dob: this.props.client.dob
                ? this.props.client.dob.split("T")[0]
                : "",
              estimatedDob:
                moment().year() - this.props.client.dob.split("-")[0],
              // endDate: this.props.client.endDate
              //   ? this.props.client.endDate.split("T")[0]
              //   : "",
              // startDate: this.props.client.startDate
              //   ? this.props.client.startDate.split("T")[0]
              //   : "",
            },
          });
        } else {
          this.setState({
            client: {
              ...this.props.client,
              dob: this.props.client.dob
                ? this.props.client.dob.split("T")[0]
                : "",
              // endDate: this.props.client.endDate
              //   ? this.props.client.endDate.split("T")[0]
              //   : "",
              // startDate: this.props.client.startDate
              //   ? this.props.client.startDate.split("T")[0]
              //   : "",
            },
          });
        }
      }
    }
    if (
      prevState.client.address !== this.state.client.address &&
      this.state.client.address
    ) {
      let client = this.state.client;
      client.isHomeless = false;
      client.isAddressUnkown = false;
      client.isAddressUnkownBackup = false;
      // client.isBowmanJohnson = false;
      // client.startDate = "";
      // client.endDate = "";
      // client.roomNo = "";
      this.setState({ client });
    }
  }

  handleChangeAddress(address) {
    this.setState({ address });
  }

  handleSelectAddress(address) {
    geocodeByAddress(address)
      .then((response) => response)
      .then((response) => {})
      .catch((error) => console.error("Error", error));
    this.setState({ address });
  }

  // render() {
  //   return (
  //     <div className="client-form full-width mt-4">
  //       <CustomForm
  //         ref={(c) => {
  //           this.form = c;
  //         }}
  //         autoComplete="false"
  //         onSubmit={this.handleOnSubmit}
  //       >
  //         <Row className="d-flex align-items-center bg-grey pl-2 pt-2 pb-2">
  //           <strong>Name</strong>
  //           <Col>
  //             <CharacterCounter>
  //               <Form.Control
  //                 validations={[required]}
  //                 value={this.state.client.firstName}
  //                 name="firstName"
  //                 onChange={this.handleChange}
  //                 className="form-fields pt-0"
  //                 placeholder=" First Name"
  //                 maxLength={255}
  //               />
  //             </CharacterCounter>
  //           </Col>
  //           <Col>
  //             <CharacterCounter>
  //               <Form.Control
  //                 validations={[required]}
  //                 value={this.state.client.lastName}
  //                 name="lastName"
  //                 onChange={this.handleChange}
  //                 className="form-fields pt-0"
  //                 placeholder="Last Name"
  //                 maxLength={255}
  //               />
  //             </CharacterCounter>
  //           </Col>
  //           <Col>
  //             <CharacterCounter>
  //               <Form.Control
  //                 value={this.state.client.alias1}
  //                 name="alias1"
  //                 onChange={this.handleChange}
  //                 className="form-fields pt-0"
  //                 placeholder="ALIAS"
  //                 maxLength={255}
  //               />
  //             </CharacterCounter>
  //           </Col>
  //           <Col>
  //             <CharacterCounter>
  //               <Form.Control
  //                 value={this.state.client.alias2}
  //                 name="alias2"
  //                 onChange={this.handleChange}
  //                 className="form-fields pt-0"
  //                 placeholder="ALIAS"
  //                 maxLength={255}
  //               />
  //             </CharacterCounter>
  //           </Col>
  //           <Col>
  //             <CharacterCounter>
  //               <Form.Control
  //                 value={this.state.client.alias3}
  //                 name="alias3"
  //                 onChange={this.handleChange}
  //                 className="form-fields pt-0"
  //                 placeholder="ALIAS"
  //                 maxLength={255}
  //               />
  //             </CharacterCounter>
  //           </Col>
  //         </Row>
  //         <Row className="d-flex align-items-center pt-2 pb-2">
  //           {this.state.client.isDobEstimated ? (
  //             <>
  //               <Col xl={2} lg={2} md={2} sm={12} xs={12}>
  //                 <strong className="form-label mt-2">Number</strong>
  //               </Col>
  //               <Col xl={3} lg={3} md={3} sm={12} xs={12}>
  //                 <InputMask
  //                   alwaysShowMask={false}
  //                   className="form-field-border custom-width form-control"
  //                   value={this.state.client.estimatedDob}
  //                   name="estimatedDob"
  //                   onChange={this.handleChange}
  //                   type="number"
  //                   min={0}
  //                 />
  //               </Col>
  //             </>
  //           ) : (
  //             <>
  //               <Col xl={2} lg={2} md={2} sm={12} xs={12}>
  //                 <strong className="form-label mt-2">DOB</strong>
  //               </Col>
  //               <Col xl={3} lg={3} md={3} sm={12} xs={12}>
  //                 <Form.Control
  //                   type="date"
  //                   max={moment().format("YYYY-MM-DD").toString().trim()}
  //                   value={this.state.client.dob}
  //                   name="dob"
  //                   validations={[onlyPast]}
  //                   onChange={this.handleChange}
  //                   disabled={this.state.client.isDob}
  //                   className="form-field-border custom-width"
  //                 />
  //               </Col>
  //             </>
  //           )}
  //           {/* <Col xl={2} lg={2} md={2} sm={12} xs={12}>
  //             <strong className="form-label mt-2">DOB</strong>
  //           </Col>
  //           <Col xl={3} lg={3} md={3} sm={12} xs={12}>
  //             <Form.Control
  //               type="date"
  //               max={moment().format("YYYY-MM-DD").toString().trim()}
  //               value={this.state.client.dob}
  //               name="dob"
  //               validations={[onlyPast]}
  //               onChange={this.handleChange}
  //               disabled={this.state.client.isDob}
  //               className="form-field-border custom-width"
  //             />
  //           </Col> */}
  //           <Col xl={2} lg={2} md={4} sm={12} xs={12}>
  //             <Col>
  //               <Form.Control
  //                 type="radio"
  //                 id="test2"
  //                 defaultChecked={this.state.client.isDobEstimated}
  //                 checked={this.state.client.isDobEstimated}
  //                 name="DobRadio"
  //                 onChange={(e) => this.handleDobChange(e)}
  //                 className="custom-checkbox"
  //                 value="isDobEstimated"
  //               />
  //               <label htmlFor="test2">Estimated</label>
  //               <Form.Control
  //                 type="radio"
  //                 id="test1"
  //                 defaultChecked={this.state.client.isDob}
  //                 checked={this.state.client.isDob}
  //                 name="DobRadio"
  //                 onChange={(e) => this.handleDobChange(e)}
  //                 className="custom-checkbox"
  //                 value="isDob"
  //               />
  //               <label htmlFor="test1">Unknown</label>
  //             </Col>
  //             <div className="divider"></div>
  //           </Col>
  //           <Col
  //             xl={2}
  //             lg={2}
  //             md={4}
  //             sm={12}
  //             xs={12}
  //             className="text-center margin-20"
  //           >
  //             <strong>Gender</strong>
  //           </Col>
  //           <Col xl={3} lg={3} md={3} sm={12} xs={12}>
  //             <Form.Group
  //               className="mb-0 select-field"
  //               controlId="exampleForm.ControlSelect1"
  //             >
  //               <Form.Control
  //                 value={this.state.client.gender}
  //                 name="gender"
  //                 onChange={this.handleChange}
  //                 className="form-field-border arrow-down"
  //                 as="select"
  //               >
  //                 <option>Male</option>
  //                 <option>Female</option>
  //                 <option>Transgender</option>
  //                 <option>Undisclosed</option>
  //               </Form.Control>
  //             </Form.Group>
  //           </Col>
  //         </Row>
  //         <Row className="d-flex align-items-center bg-grey pl-2 pt-2 pb-2">
  //           <Col xl={2} lg={2} md={2} sm={12} xs={12} className="pl-0">
  //             <strong className="form-label mr-0">Cultural Identity</strong>
  //           </Col>
  //           <Col xl={4} lg={4} md={4} sm={12} xs={12} className="pl-0">
  //             <Form.Group
  //               className="mb-0 select-field"
  //               controlId="exampleForm.ControlSelect1"
  //             >
  //               <Form.Control
  //                 value={this.state.client.culturalIdentity}
  //                 name="culturalIdentity"
  //                 onChange={this.handleChange}
  //                 className="form-field-border arrow-down"
  //                 as="select"
  //               >
  //                 <option value="Aboriginal">Aboriginal</option>
  //                 <option value="Torres Strait Islander">
  //                   Torres Strait Islander
  //                 </option>
  //                 <option value="South Sea">South Sea</option>
  //                 <option value="Aboriginal &amp; Torres Strait Islander">
  //                   Aboriginal &amp; Torres Strait Islander
  //                 </option>
  //                 <option value="Aboriginal, Torres Strait Islander and South Sea">
  //                   Aboriginal, Torres Strait Islander and South Sea
  //                 </option>
  //                 <option value="CALD">CALD</option>
  //                 <option value="Non-Indigenous">Non-Indigenous</option>
  //               </Form.Control>
  //             </Form.Group>
  //           </Col>
  //           <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
  //           <Col xl={3} lg={3} md={2} sm={12} xs={12} className="pl-4">
  //             <Form.Control
  //               type="checkbox"
  //               id="test12"
  //               defaultChecked={this.state.client.highRisk}
  //               checked={this.state.client.highRisk}
  //               name="highRisk"
  //               onChange={(e) => this.handleCheck(e)}
  //               className="custom-checkbox"
  //             />
  //             <label htmlFor="test12">High Risk Medical</label>
  //           </Col>
  //         </Row>
  //         <Row className="d-flex align-items-center pt-2 pb-2">
  //           <Col xl={2} lg={2} md={2} sm={12} xs={12}>
  //             <strong className="form-label mt-2">Contact Number</strong>
  //           </Col>
  //           <Col xl={4} lg={4} md={4} sm={12} xs={12}>
  //             <InputMask
  //               alwaysShowMask={false}
  //               className="form-field-border custom-width form-control"
  //               value={this.state.client.phone}
  //               name="phone"
  //               onChange={this.handleChange}
  //               type="text"
  //               mask="99 9999 9999"
  //               maskChar="_"
  //             />
  //           </Col>
  //           <Col md={1}>
  //             <div className="divider"></div>
  //           </Col>
  //           <Col md={2} className="text-center">
  //             <strong>Email Address</strong>
  //           </Col>
  //           <Col md={3}>
  //             <CharacterCounter>
  //               <Form.Control
  //                 type="text"
  //                 value={this.state.client.email}
  //                 name="email"
  //                 onChange={this.handleChange}
  //                 className="form-field-border"
  //                 placeholder="Email Address"
  //                 maxLength={255}
  //               />
  //             </CharacterCounter>
  //           </Col>
  //         </Row>
  //         <Row className="d-flex align-items-center bg-grey pt-2 pb-2">
  //           <Col md={4} className="search-address">
  //             <strong className="form-label mt-2">Address</strong>
  //             <Form.Group className="mb-0" controlId="formGridAddress1">
  //               {/* <LocationSearchInput
  //                 address={this.state.client.address}
  //                 onSelect={(address) =>
  //                   this.setState({ client: { ...this.state.client, address } })
  //                 }
  //               ></LocationSearchInput> */}
  //               <Form.Control
  //                 // validations={[required]}
  //                 value={this.state.client.address}
  //                 name="Address"
  //                 onChange={this.handleChange}
  //                 className="form-fields pt-0"
  //                 placeholder=" Address"
  //                 // maxLength={255}
  //               />
  //             </Form.Group>
  //           </Col>
  //           <Col md={2} className="d-flex flex-column">
  //             <Form.Control
  //               type="checkbox"
  //               checked={this.state.client.isAddressTransitional}
  //               name="isAddressTransitional"
  //               onChange={(e) => this.handleCheck(e)}
  //               id="isAddressTransitional"
  //               className="custom-checkbox"
  //             />
  //             <label htmlFor="isAddressTransitional" className="mb-0">
  //               Transitional Address
  //             </label>

  //             <Form.Control
  //               type="checkbox"
  //               checked={this.state.client.isAddressOutOfArea}
  //               name="isAddressOutOfArea"
  //               onChange={(e) => this.handleCheck(e)}
  //               id="isAddressOutOfArea"
  //               className="custom-checkbox"
  //             />
  //             <label htmlFor="isAddressOutOfArea" className="mb-0">
  //               Out of Area
  //             </label>

  //             <Form.Control
  //               type="checkbox"
  //               checked={this.state.client.isHomeless}
  //               name="isHomeless"
  //               onChange={(e) => this.handleCheck(e)}
  //               id="isHomeless"
  //               className="custom-checkbox"
  //             />
  //             <label htmlFor="isHomeless" className="mb-0">
  //               Homeless (Sleeping Rough)
  //             </label>

  //             <Form.Control
  //               type="checkbox"
  //               checked={this.state.client.isAddressUnkown}
  //               name="isAddressUnkown"
  //               onChange={(e) => this.handleCheck(e)}
  //               id="isAddressUnkown"
  //               className="custom-checkbox"
  //             />
  //             <label htmlFor="isAddressUnkown">Unknown</label>
  //           </Col>
  //           {/* <Col md={1}>
  //             <div className="divider"></div>
  //           </Col>
  //           <Col md={2} className="pl-3">
  //             <Form.Control
  //               type="checkbox"
  //               id="test8"
  //               value={!this.state.client.isBowmanJohnson}
  //               defaultChecked={this.state.client.isBowmanJohnson}
  //               checked={this.state.client.isBowmanJohnson}
  //               name="isBowmanJohnson"
  //               onChange={(e) => this.handleCheck(e)}
  //               className="custom-checkbox"
  //             />
  //             <label htmlFor="test8">Bowman Johnson</label>
  //           </Col>
  //           <Col md={3}>
  //             <Form.Group
  //               className="mb-1 bg-grey"
  //               controlId="exampleForm.ControlSelect1"
  //             >
  //               <label className="form-label mt-2">Check In</label>
  //               <Form.Control
  //                 type="date"
  //                 max={moment().format("YYYY-MM-DD").toString().trim()}
  //                 value={this.state.client.startDate}
  //                 name="startDate"
  //                 validations={[onlyPast]}
  //                 onChange={this.handleChange}
  //                 className="form-field-border"
  //               /> */}

  //           {/* <Form.Control className="form-field-border arrow-down" as="select" >
  //                                   <option>Start Date</option>
  //                               </Form.Control> */}

  //           {/* </Form.Group>
  //             <Form.Group
  //               className="mb-1 bg-grey"
  //               controlId="exampleForm.ControlSelect1"
  //             >
  //               <label className="form-label mt-2">Check Out</label>
  //               <Form.Control
  //                 type="date"
  //                 min={
  //                   this.state.client.startDate &&
  //                   moment(this.state.client.startDate)
  //                     .format("YYYY-MM-DD")
  //                     .toString()
  //                     .trim()
  //                 }
  //                 max={moment().format("YYYY-MM-DD").toString().trim()}
  //                 value={this.state.client.endDate}
  //                 validations={[onlyPast]}
  //                 name="endDate"
  //                 onChange={this.handleChange}
  //                 className="form-field-border"
  //               />
  //             </Form.Group>
  //             <Form.Group
  //               className="mb-1 bg-grey"
  //               controlId="exampleForm.ControlSelect1"
  //             >
  //               <label className="form-label mt-2">Room #</label>
  //               <CharacterCounter>
  //                 <Form.Control
  //                   type="text"
  //                   value={this.state.client.roomNo}
  //                   name="roomNo"
  //                   onChange={this.handleChange}
  //                   className="form-field-border"
  //                 />
  //               </CharacterCounter>
  //             </Form.Group>
  //           </Col> */}
  //         </Row>
  //         <div className="btn-sec full-width text-center form-btn pt-2">
  //           <button
  //             type="submit"
  //             className="main-btn orange-btn btn btn-primary mr-3 save-btn"
  //           >
  //             Save
  //           </button>
  //           {getUser().Role && getUser().Role.divertionCenter && (
  //             <button
  //               disabled={this.props.match.params.id ? false : true}
  //               onClick={() =>
  //                 this.props.history.push(
  //                   TO_DIVERSION_CENTRE + "/new/" + this.props.match.params.id
  //                 )
  //               }
  //               className="main-btn default-btn btn btn-primary"
  //             >
  //               Diversion Centre
  //             </button>
  //           )}
  //           {/* {getUser().Role && getUser().Role.cellVisits && (
  //             <button
  //               disabled={this.props.match.params.id ? false : true}
  //               onClick={() =>
  //                 this.props.history.push(
  //                   TO_CELL_VISIT + "/new/" + this.props.match.params.id
  //                 )
  //               }
  //               className="main-btn default-btn btn btn-primary save-btn"
  //             >
  //               Cell Visit
  //             </button>
  //           )} */}
  //           {/* {getUser().Role && getUser().Role.communityPatrol && (
  //             <button
  //               disabled={this.props.match.params.id ? false : true}
  //               onClick={() =>
  //                 this.props.history.push(
  //                   TO_COMMUNITY_PATROL + "/new/" + this.props.match.params.id
  //                 )
  //               }
  //               className="main-btn default-btn btn btn-primary"
  //             >
  //               Community Patrol
  //             </button>
  //           )} */}
  //         </div>
  //       </CustomForm>
  //     </div>
  //   );
  // }

  render() {
    const checkBoxStyles = {
      appearance: "none",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "#f4f4f4",
      marginTop: "0",
    };

    return (
      <CustomForm
        ref={(c) => {
          this.form = c;
        }}
        autoComplete="false"
        onSubmit={this.handleOnSubmit}
      >
        <div className={`${Styles.ml_main} ${Styles.mt_40}`}>
          <div className="container-fluid px-0">
            <div className={`bg-white overflow-hidden`}>
              <div className={Styles.diversion_form}>
                <div className="detail-block">
                  <div
                    className="row align-items-center"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="col-md-1 col-sm-auto">
                      <label style={{ marginBottom: "0" }}>Name</label>
                    </div>
                    <div className="col-sm">
                      <input
                        className={Styles.form_control}
                        type="text"
                        id="name"
                        style={{ width: "100%" }}
                        validations={[required]}
                        value={this.state.client.firstName}
                        name="firstName"
                        onChange={this.handleChange}
                        placeholder=" First Name"
                        maxLength={255}
                      />
                    </div>

                    <div className="col-sm">
                      <input
                        className={Styles.form_control}
                        type="text"
                        id="name"
                        style={{ width: "100%" }}
                        validations={[required]}
                        value={this.state.client.lastName}
                        name="lastName"
                        onChange={this.handleChange}
                        placeholder="Last Name"
                        maxLength={255}
                      />
                    </div>
                    <div className="col-sm">
                      <input
                        className={Styles.form_control}
                        type="text"
                        id="name"
                        style={{ width: "100%" }}
                        value={this.state.client.alias1}
                        name="alias1"
                        onChange={this.handleChange}
                        placeholder="ALIAS"
                        maxLength={255}
                      />
                    </div>
                  </div>
                  <div
                    className="row align-items-center"
                    style={{ marginBottom: "30px" }}
                  >
                    {this.state.client.isDobEstimated ? (
                      <>
                        <div className="col-md-1 col-sm-auto">
                          <label style={{ marginBottom: "0" }}>Age</label>
                        </div>
                        <div className="col">
                          <input
                            className={Styles.form_control}
                            style={{ width: "100%" }}
                            // alwaysShowMask={false}
                            value={
                              this.state.client.estimatedDob
                              // ? this.state.client.estimatedDate
                              // : 10
                            }
                            name="estimatedDob"
                            onChange={this.handleChange}
                            type="number"
                            min={10}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-1 col-sm-auto">
                          <label style={{ marginBottom: "0" }}>DoB</label>
                        </div>
                        <div className="col">
                          <input
                            className={Styles.form_control}
                            type="date"
                            style={{ width: "100%" }}
                            max={moment()
                              .format("YYYY-MM-DD")
                              .toString()
                              .trim()}
                            value={this.state.client.dob}
                            name="dob"
                            validations={[onlyPast]}
                            onChange={this.handleChange}
                            disabled={this.state.client.isDob}
                          />
                        </div>
                      </>
                    )}
                    {/* <div className="col-md-1 col-sm-auto">
                      <label for="age" style={{ marginBottom: "0" }}>
                        Age
                      </label>
                    </div>
                    <div className="col">
                      <input
                        className={Styles.form_control}
                        type="date"
                        id="age"
                        style={{ width: "100%" }}
                      />
                    </div> */}

                    <div className={`col-sm ${Styles.gender_block}`}>
                      <div>
                        <div
                          className={Styles.form_check}
                          style={{ marginBottom: "5px" }}
                        >
                          <input
                            className={`${Styles.form_check_input} form-check-input ${Styles.final_checkbox}`}
                            type="checkbox"
                            style={checkBoxStyles}
                            id="test2"
                            defaultChecked={this.state.client.isDobEstimated}
                            checked={this.state.client.isDobEstimated}
                            // name="DobRadio"
                            name="isDobEstimated"
                            // onChange={(e) => this.handleDobChange(e)}
                            onChange={(e) => this.handleCheck(e)}
                            value="isDobEstimated"
                          />
                          {/* <label className="form-check-label" htmlFor="test2"> */}
                          <label
                            className="form-check-label"
                            htmlFor="isDobEstimated"
                          >
                            Estimated
                          </label>
                        </div>
                        <div className={Styles.form_check}>
                          <input
                            className={`${Styles.form_check_input} form-check-input ${Styles.final_checkbox}`}
                            type="checkbox"
                            style={checkBoxStyles}
                            id="test1"
                            defaultChecked={this.state.client.isDob}
                            checked={this.state.client.isDob}
                            // name="DobRadio"
                            name="isDob"
                            // onChange={(e) => this.handleDobChange(e)}
                            onChange={(e) => this.handleCheck(e)}
                            value="isDob"
                          />
                          {/* <label className="form-check-label" htmlFor="test1"> */}
                          <label className="form-check-label" htmlFor="isDob">
                            Unknown
                          </label>
                        </div>
                      </div>

                      <div className="">
                        <label style={{ marginBottom: 0 }}>Gender</label>
                      </div>
                    </div>
                    <div className="col">
                      <select
                        id="gender"
                        className={Styles.form_select}
                        style={{ width: "100%" }}
                        value={this.state.client.gender}
                        name="gender"
                        onChange={this.handleChange}
                        as="select"
                      >
                        <option>Male</option>
                        <option>Female</option>
                        <option>Transgender</option>
                        <option>Undisclosed</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div
                  className={`${Styles.bg_lightGray} ${Styles.cultural_block}`}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <label style={{ marginBottom: "0" }}>
                        Cultural Identity
                      </label>
                    </div>
                    <div className="col-sm-5">
                      <select
                        id="cultural"
                        className={Styles.form_select}
                        style={{ width: "100%" }}
                        value={this.state.client.culturalIdentity}
                        name="culturalIdentity"
                        onChange={this.handleChange}
                        as="select"
                      >
                        {/* <option value="">Non-Indigenous</option>
                        <option value="">one</option>
                        <option value="">two</option> */}
                        <option value="Aboriginal">Aboriginal</option>
                        <option value="Torres Strait Islander">
                          Torres Strait Islander
                        </option>
                        <option value="South Sea">South Sea</option>
                        <option value="Aboriginal &amp; Torres Strait Islander">
                          Aboriginal &amp; Torres Strait Islander
                        </option>
                        <option value="Aboriginal, Torres Strait Islander and South Sea">
                          Aboriginal, Torres Strait Islander and South Sea
                        </option>
                        <option value="CALD">CALD</option>
                        <option value="Non-Indigenous">Non-Indigenous</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className={Styles.contact_block}>
                  <div className="row" style={{ marginBottom: "28px" }}>
                    <div className="col-md-6">
                      <div className="row align-items-center">
                        <div className="col-sm-auto">
                          <label style={{ marginBottom: "0" }}>
                            Contact Number
                          </label>
                        </div>
                        <div className="col-sm">
                          <InputMask
                            alwaysShowMask={false}
                            className={Styles.form_control}
                            style={{ width: "100%" }}
                            value={this.state.client.phone}
                            name="phone"
                            onChange={this.handleChange}
                            type="text"
                            mask="99 9999 9999"
                            maskChar="_"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row align-items-center">
                        <div className="col-sm-auto">
                          <label style={{ marginBottom: "0" }}>
                            Email Address
                          </label>
                        </div>
                        <div className="col-sm">
                          <input
                            className={Styles.form_control}
                            id="email-add"
                            placeholder="Email Address"
                            style={{ width: "100%" }}
                            type="text"
                            value={this.state.client.email}
                            name="email"
                            onChange={this.handleChange}
                            maxLength={255}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${Styles.bg_lightGray} ${Styles.address_block}`}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row" style={{ marginBottom: "24px" }}>
                        <div className="col-sm-auto">
                          <label style={{ marginBottom: "0" }}>Address</label>
                        </div>
                        <div className="col-sm">
                          <textarea
                            className={Styles.form_control}
                            id="address"
                            rows="4"
                            style={{ width: "100%" }}
                            // validations={[required]}
                            value={this.state.client.address}
                            name="address"
                            onChange={this.handleChange}
                            placeholder=" Address"
                            // maxLength={255}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={Styles.checkbox_mrg}>
                        <div className={Styles.form_check}>
                          <input
                            className={`${Styles.form_check_input} form-check-input ${Styles.final_checkbox}`}
                            style={checkBoxStyles}
                            type="checkbox"
                            checked={this.state.client.isAddressTransitional}
                            name="isAddressTransitional"
                            onChange={(e) => this.handleCheck(e)}
                            id="isAddressTransitional"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isAddressTransitional"
                            style={{ marginBottom: "0" }}
                          >
                            Transitional Address
                          </label>
                        </div>
                        <div className={Styles.form_check}>
                          <input
                            className={`${Styles.form_check_input} form-check-input ${Styles.final_checkbox}`}
                            type="checkbox"
                            style={checkBoxStyles}
                            checked={this.state.client.isAddressOutOfArea}
                            name="isAddressOutOfArea"
                            onChange={(e) => this.handleCheck(e)}
                            id="isAddressOutOfArea"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isAddressOutOfArea"
                            style={{ marginBottom: "0" }}
                          >
                            Out of Area
                          </label>
                        </div>
                        <div className={Styles.form_check}>
                          <input
                            className={`${Styles.form_check_input} form-check-input ${Styles.final_checkbox}`}
                            type="checkbox"
                            style={checkBoxStyles}
                            checked={this.state.client.isAddressUnkown}
                            name="isAddressUnkown"
                            onChange={(e) => this.handleCheck(e)}
                            id="isAddressUnkown"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isAddressUnkown"
                          >
                            Unknown
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className={`${Styles.btn} ${Styles.btn_yellow}`}
                  >
                    Save
                  </button>
                  {getUser().Role && getUser().Role.divertionCenter && (
                    <button
                      disabled={this.props.match.params.id ? false : true}
                      onClick={() =>
                        this.props.history.push(
                          TO_DIVERSION_CENTRE +
                            "/new/" +
                            this.props.match.params.id
                        )
                      }
                      style={
                        this.props.match.params.id
                          ? null
                          : { color: "#d1242a", background: "#fff" }
                      }
                      className={`${Styles.btn} ${Styles.btn_red}`}
                    >
                      Diversion Centre
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomForm>
    );
  }
}
const stateMap = (state) => {
  return {
    searchClients: state.client.searchClients,
    client: state.client.client,
    updated: state.client.updated,
    created: state.client.created,
  };
};
export default connect(stateMap)(UpdateForm);
