// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'

// Styles
const styles = {
  counter: {
    height: 11,
    position: 'absolute',
    bottom: -12,
    right: 0,
    fontSize: 11,
    lineHeight: '11px',
    color: '#000',
  },
  container: {
    position: 'relative'
  }
}

export default class CharacterCounter extends React.Component {

  state = {
    valueLength: null,
  }

  getInput() {
    if( !this.container ) {
      return null;
    }
    
    const firstChild = this.container.firstChild;

    if (this.isValidChild(firstChild)) {
      return firstChild;
    }

    const children = this.container.children;

    if (!children.length) {
      return null;
    }

    for (var i = 0; i < children.length; i++) {
      if (this.isValidChild(children[i])) {
        return children[i];
      }
    }

    return null;
  }

  isValidChild(child) {
    return !!['input', 'textarea'].find(tag => {
      return child.tagName.toLowerCase() === tag
    });
  }

  componentDidMount() {
    // Push it to the top of the stack giving the time for the elements to render
    setTimeout(() => {
      const input = this.getInput();

      if (!input) {
        return;
      }

      const inputRender = () => {
        this.setState({ valueLength: input && input.value && input.value.length || 0 });
      }
      input.addEventListener('keyup', inputRender);
      inputRender();
    }, 0);
  }

  render() {

    const { valueLength } = this.state
    const { children, style } = this.props;
    const maxLength = children.props.maxLength ? children.props.maxLength : false;

    return (
      <div ref={div => this.container = div} style={styles.container}>
        {children}
        <span className='form-control-character-counter' style={style ? Object.assign({}, styles.counter, style) : styles.counter}>
          {valueLength !== null && valueLength > 0 && maxLength &&
            <span className='character-count'>{valueLength}/{maxLength}</span>
          }
        </span>
      </div>
    )
  }
}

CharacterCounter.defaultProps = {
  style: {},
}

CharacterCounter.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
}
