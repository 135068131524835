import React from "react";
import { Row, Col } from "react-bootstrap";
import CustomForm from "react-validation/build/form";
import Form from "../shared/form-control";
import "./categoryForm.scss";
import { required } from "../../helpers/form-validation";
import { connect } from "react-redux";
import store from "../../store";
import {
  UpdateCategoryAction,
  CreateCategoryAction,
  ArchiveCategoryAction,
} from "../../actions/category-action";
import CharacterCounter from "../../containers/character-counter/character-counter";

import Styles from "../shared/css/default.module.css";

class CategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      category: {
        name: "",
      },
    };
  }
  componentDidMount() {
    if (this.props.category && Object.keys(this.props.category).length > 0) {
      this.setState({ category: this.props.category });
    }
  }

  handleChange = (e, checked) => {
    let category = this.state.category;
    category[e.target.name] = e.target.value;
    this.setState({ category });
  };

  archiveCategory = () => {
    if (this.state.category.id) {
      store.dispatch(ArchiveCategoryAction({ id: this.state.category.id }));
    }
  };

  handleOnSubmit(e) {
    e.preventDefault();
    this.form.validateAll();
    if (this.form.getChildContext()._errors.length === 0) {
      if (
        this.props.category &&
        Object.keys(this.props.category).length > 0 &&
        this.state.category.id
      ) {
        store.dispatch(UpdateCategoryAction(this.state.category)); //set in session storage
      } else {
        store.dispatch(CreateCategoryAction(this.state.category)); //set in session storage
        this.setState({
          category: {
            name: "",
          },
        });
      }
    }
  }

  //   render() {
  //     return (
  //       <div className="report-form">
  //         <Row>
  //           <Col md={12}>
  //             <CustomForm
  //               ref={(c) => {
  //                 this.form = c;
  //               }}
  //               autoComplete="false"
  //               onSubmit={this.handleOnSubmit}
  //             >
  //               <Row className="report-section d-flex align-items-center p-1 bg-grey">
  //                 <Col>
  //                   <Row className="d-flex align-items-center">
  //                     <Col md={6}>
  //                       <Form.Label className="text-label">Category</Form.Label>
  //                     </Col>
  //                     <Col md={6}>
  //                       <CharacterCounter>
  //                         <Form.Control
  //                           validations={[required]}
  //                           value={this.state.category.name}
  //                           name="name"
  //                           onChange={this.handleChange}
  //                           className="form-fields pt-0"
  //                           placeholder="Name"
  //                           maxLength={255}
  //                         />
  //                       </CharacterCounter>
  //                     </Col>
  //                   </Row>
  //                 </Col>
  //               </Row>
  //               <button
  //                 type="submit"
  //                 className="main-btn orange-btn btn btn-primary ml-3 save-btn mt-3 float-right"
  //               >
  //                 Save
  //               </button>
  //               {this.state.category.id && (
  //                 <button
  //                   type="button"
  //                   className="main-btn default-btn btn btn-primary ml-3 save-btn mt-3 float-right"
  //                   onClick={this.archiveCategory}
  //                 >
  //                   Archive
  //                 </button>
  //               )}
  //             </CustomForm>
  //           </Col>
  //         </Row>
  //       </div>
  //     );
  //   }

  render() {
    return (
      //   <div className="report-form">
      //     <Row>
      //       <Col md={12}>
      //         <CustomForm
      //           ref={(c) => {
      //             this.form = c;
      //           }}
      //           autoComplete="false"
      //           onSubmit={this.handleOnSubmit}
      //         >
      //           <Row className="report-section d-flex align-items-center p-1 bg-grey">
      //             <Col>
      //               <Row className="d-flex align-items-center">
      //                 <Col md={6}>
      //                   <Form.Label className="text-label">Category</Form.Label>
      //                 </Col>
      //                 <Col md={6}>
      //                   <CharacterCounter>
      //                     <Form.Control
      //                       validations={[required]}
      //                       value={this.state.category.name}
      //                       name="name"
      //                       onChange={this.handleChange}
      //                       className="form-fields pt-0"
      //                       placeholder="Name"
      //                       maxLength={255}
      //                     />
      //                   </CharacterCounter>
      //                 </Col>
      //               </Row>
      //             </Col>
      //           </Row>
      //           <button
      //             type="submit"
      //             className="main-btn orange-btn btn btn-primary ml-3 save-btn mt-3 float-right"
      //           >
      //             Save
      //           </button>
      //           {this.state.category.id && (
      //             <button
      //               type="button"
      //               className="main-btn default-btn btn btn-primary ml-3 save-btn mt-3 float-right"
      //               onClick={this.archiveCategory}
      //             >
      //               Archive
      //             </button>
      //           )}
      //         </CustomForm>
      //       </Col>
      //     </Row>
      //   </div>

      <div className="">
        <div className="" style={{ backgroundColor: "#ffffff" }}>
          <CustomForm
            ref={(c) => {
              this.form = c;
            }}
            autoComplete="false"
            onSubmit={this.handleOnSubmit}
          >
            <div className={`${Styles.ml_main} ${Styles.mt_40}`}>
              <div className="container-fluid px-0">
                <div className="tab-content">
                  <div id="tab1" className="tab-pane active">
                    <div className={Styles.report_form}>
                      <div className="row gx-4">
                        <div
                          className="col-md-6 col-sm-12 mb-30"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>Category </label>
                          <input
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            style={{ backgroundImage: "none" }}
                            type="text"
                            validations={[required]}
                            value={this.state.category.name}
                            name="name"
                            onChange={this.handleChange}
                            placeholder="Name"
                            maxLength={255}
                          />
                        </div>
                      </div>
                      {/* <div className="row gx-4">
                        <div
                          className=""
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            justifyContent: "center",
                          }}
                        >
                          <label style={{ display: "block" }}>
                            Diversion Center{" "}
                          </label>
                          <CustomSwitch
                            sx={{ m: 1 }}
                            // checked={elem.diversionCenter}
                            // onChange={(e) => this.handleChange(e, elem)}
                            name="diversionCenter"
                            checked={this.state.location.diversionCenter}
                            onChange={(e) => this.handleChange(e, true)}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div
                      className={`${Styles.report_btn} text-end`}
                      style={{ textAlign: "start" }}
                    >
                      <button
                        type="submit"
                        className={`${Styles.btn} ${Styles.btn_yellow}`}
                      >
                        Save
                      </button>
                      {this.state.category.id && (
                        <button
                          type="button"
                          className={`${Styles.btn} ${Styles.btn_red}`}
                          onClick={this.archiveCategory}
                        >
                          Archive
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomForm>
        </div>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    reportData: state.report.reportData,
    start: state.report.start,
    end: state.report.end,
  };
};

export default connect(stateMap)(CategoryForm);
