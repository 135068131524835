import React from "react";
import clsx from "clsx";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ActiveUserDiversion from "../../components/diversion-centre/active-user-community";
import ActiveUserCommunity from "../../components/community-patrol/active-user-community";
import CellVisitDashboard from "../../components/dashboard/cell-visit-dashboad";
import CommintyPortalDashboard from "../../components/dashboard/community-portal-dashboard";
import "./dashboard.scss";
import { getUser } from "../../helpers/utility";

import Styles from "../../components/shared/css/default.module.css";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      activeTab: 1,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(key) {
    this.setState({ key: key });
    this.setState({ activeTab: 3 });
  }
  // render() {
  //   return (
  //     <div className="dashboard-section full-width">
  //       <div className="tabs-section full-width mt-4">
  //         <Tabs
  //           defaultActiveKey="past-activities"
  //           id="uncontrolled-tab-example"
  //         >
  //           <Tab
  //             eventKey="past-activities"
  //             title={<span>Diversion Centre</span>}
  //             className="tab-table"
  //           >
  //             <Row>
  //               <Col md={12}>
  //                 <ActiveUserDiversion fromDashboard={true} {...this.props} />
  //               </Col>
  //             </Row>
  //           </Tab>
  //           {/* {getUser().Role && getUser().Role.cellVisits && (
  //             <Tab
  //               eventKey="medical-obsevations"
  //               title={<span>Cell Visits</span>}
  //               className="tab-table comunity-user"
  //             >
  //               <Row>
  //                 <Col md={12}>
  //                   <CellVisitDashboard fromDashboard={true} {...this.props} />
  //                 </Col>
  //               </Row>
  //             </Tab>
  //           )} */}
  //           {/* <Tab
  //             eventKey="general-observations"
  //             title={<span>Community Patrol</span>}
  //             className="tab-table comunity-user"
  //           >
  //             <Row>
  //               <Col md={12}>
  //                 <ActiveUserCommunity fromDashboard={true} {...this.props} />
  //               </Col>
  //             </Row>
  //           </Tab> */}
  //         </Tabs>
  //       </div>
  //     </div>
  //   );
  // }

  render() {
    return (
      <div className={`${Styles.ml_main} ${Styles.mt_40}`}>
        <div className="container-fluid px-0">
          <div className="bg-white" style={{ paddingBottom: "100px" }}>
            <div className={Styles.tab_block}>
              <div className={Styles.bg_yellow} style={{ lineHeight: 2.5 }}>
                <ul className={`nav ${Styles.nav_tabs}`} role="tablist">
                  <li>
                    <a
                      className={`${Styles.nav_link} ${Styles.active}`}
                      data-bs-toggle="tab"
                      href="#tab1"
                    >
                      Diversion Centre
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                <div id="tab1" className={`${Styles.tab_pane} active`}>
                  {/* <div className="table-responsive"> */}
                  {/* <table
                      className={`${Styles.table_custom} ${Styles.diversion_table}`}
                    >
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Mobile</th>
                          <th>DOB</th>
                          <th>Date</th>
                          <th>Time in</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Latoya</td>
                          <td>Jackson</td>
                          <td>04 1111 1111</td>
                          <td>03/03/03</td>
                          <td>06/09/22</td>
                          <td>12:42AM</td>
                          <td>
                            <img
                              src={require("../../assets/images2/check-circle.png")}
                              alt="check-circle"
                            />
                            <img
                              src={require("../../assets/images2/bell.png")}
                              alt="bell"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Angus</td>
                          <td>Langton</td>
                          <td>04 221 1111</td>
                          <td>29/11/73</td>
                          <td>25/08/22</td>
                          <td>12:49AM</td>
                          <td>
                            <img
                              src={require("../../assets/images2/check-circle.png")}
                              alt="check-circle"
                            />
                            <img
                              src={require("../../assets/images2/bell-red.png")}
                              alt="bell"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                  <ActiveUserDiversion fromDashboard={true} {...this.props} />
                  {/* </div> */}
                  {/* <div
                    className={`${Styles.diversion_block}`}
                    style={{ textAlign: "end" }}
                  >
                    <a
                      href="#"
                      className={`${Styles.btn} ${Styles.btn_yellow}`}
                    >
                      Add New
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
