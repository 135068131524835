import React from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from 'react-dropdown-select';
import CustomForm from 'react-validation/build/form';
import "./community-patrol.scss";
import ClientMedicalRiskForm from '../shared/client-medical-risk-form';
import EditIcon from '@material-ui/icons/Edit';
import ViolanceForm from '../shared/domestic-violance';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CharacterCounter from '../../containers/character-counter/character-counter';
import Modal from 'react-bootstrap/Modal'
import { toast } from '../../helpers/utility';
import LocationSearchInput from '../shared/google-places';
import { CLIENT_ACCESS } from '../../helpers/constants';

class AddNewCommunity extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.contactChange = this.contactChange.bind(this);

        this.state = {
            show: false,
            isUpdate: false,
            petrol: {},
            contacts: []
        };

        // React doesn't like nulls being used as values on inputs
        if (props.patrol.estimatedTime === null) {
            props.patrol.estimatedTime = 0;
        }

        if (props.patrol.belongingComment === null) {
            props.patrol.belongingComment = '';
        }

        if (props.patrol.clientComments === null) {
            props.patrol.clientComments = '';
        }
    }
    componentDidMount() {

        this.setState({ petrol: this.props.patrol })
        this.setState({ contacts: this.props.contacts })


    }
    handleSave() {
        if (this.props.patrol.thingsToConsider && this.props.patrol.thingsToConsider2 && this.props.patrol.thingsToConsider3) {
            // this.props.onSave();
            this.setState({ show: false })
        } else {
            toast("Please select all consent boxes ", "error")
        }
    }

    handleClose() {
        if (JSON.stringify(this.state.petrol) !== JSON.stringify(this.props.patrol)) {
            if (this.state.petrol.thingsToConsider || this.state.petrol.thingsToConsider2 || this.state.petrol.thingsToConsider3) {

                this.setState({ show: false, isUpdate: false })
            } else {

                this.props.changeState();
                this.setState({ show: false, isUpdate: false })
            }
        } else if (!this.state.isUpdate) {

            this.props.changeState();
            this.setState({ show: false, isUpdate: false })
        } else {
            // 
            this.setState({ show: false, isUpdate: false })
        }
    }
    handleFilter(e, newInputValue) {

    }
    contactChange(e, v) {

        if (v) this.props.onChange({ target: { name: 'referrelId', value: v.id } }, 'select')

        // this.props.onChange(e, 'loc', 'rby')
    }
    onChange(e) {
        this.props.onChange(e, 'loc', 'rby')
    }

    render() {

        var close = () => this.setState({ show: false });
        const options = [
            { label: "By MurriWatch", name: "serverRefusedBy", value: 1 },
            { label: "By Client", name: "serverRefusedBy", value: 2 },
        ];
        return (
            <div className="new-community full-width">
                <CustomForm ref={c => { this.form = c }} onSubmit={(e) => this.props.onhandleOnSubmit(e)}>

                    <Row className="d-flex align-items-center bg-grey p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Is this a Foot Patrol? </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.footPrint} disabled={this.props.patrol.isCheckout} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox90" name="footPrint" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox90">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control checked={this.props.patrol.footPrint} disabled={this.props.patrol.isCheckout} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox91" name="footPrint" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox91">Yes</label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="p-2">
                        <Col md={4}>
                            <Form.Label className="text-label">
                                How did the person access the service?
                            </Form.Label>
                        </Col>
                        <Col md={8}>
                            <Select className="selection-select arrow-down custom-width"
                                placeholder=""
                                options={CLIENT_ACCESS}
                                values={[...CLIENT_ACCESS.filter(elem => elem.value === (this.props.patrol.accessComment || ''))]}
                                name="accessComment"
                                onChange={(e) => this.props.handleDropDown(e, 'accessComment')}
                            />
                        </Col>
                    </Row>

                    <Row className="d-flex align-items-center p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Does the client have any belongings on them (Including medication)? </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.isBelonging} disabled={this.props.patrol.isCheckout} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox" name="isBelonging" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control checked={this.props.patrol.isBelonging} disabled={this.props.patrol.isCheckout} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox1" name="isBelonging" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox1">Yes. If yes, please ensure all items are stored securely.</label>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <CharacterCounter>
                                            <Form.Control
                                                disabled={(!this.props.patrol.isBelonging || this.props.patrol.isCheckout)}
                                                value={this.props.patrol.belongingComment}
                                                onChange={(e) => this.props.onChange(e)}
                                                name="belongingComment"
                                                maxLength={255}
                                                as="textarea"
                                                rows={2} />
                                        </CharacterCounter>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center bg-grey p-2">
                        <ViolanceForm {...this.props}></ViolanceForm>
                    </Row>
                    <Row className="d-flex align-items-center p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Does the client have any known medical conditions?</Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.isMedicalCondition} disabled={this.props.patrol.isCheckout} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox6" name="isMedicalCondition" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox6">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control checked={this.props.patrol.isMedicalCondition} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox7" name="isMedicalCondition" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox7 my-0">Yes. If yes, you must complete the Client needs and risk identification form</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} style={(this.props.isMedicalCheck || this.props.patrol.isMedicalCondition) ? { border: '1px solid black', marginTop: '1rem' } : {}}>
                            {
                                this.props.patrol && (this.props.isMedicalCheck || this.props.patrol.isMedicalCondition) && <ClientMedicalRiskForm medObs={this.props.patrol} disabled={this.props.patrol.isCheckout} onFieldChange={this.props.onChange}></ClientMedicalRiskForm>
                            }
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center bg-grey p-2">
                        <Col md={6}>
                            <Row className="d-flex align-items-center h-100">
                                <Col md={8}>
                                    <Form.Label className="text-label">Was service refused? </Form.Label>
                                </Col>
                                <Col md={2}>
                                    <Form.Control checked={!this.props.patrol.isServerRefused} disabled={this.props.patrol.isCheckout} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox8" name="isServerRefused" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox8">No</label><br></br>
                                    <Form.Control checked={this.props.patrol.isServerRefused} disabled={this.props.patrol.isCheckout} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox9" name="isServerRefused" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox9">Yes</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Select className="selection-select arrow-down custom-width"
                                        placeholder="By MurriWatch"
                                        options={options}
                                        values={[...options.filter(elem => elem.value === (eval(this.props.patrol.serverRefusedBy) ? eval(this.props.patrol.serverRefusedBy) : ''))]}
                                        name="serverRefusedBy"
                                        onChange={(e) => this.props.handleDropDown(e, 'serverRefusedBy')}
                                    />
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="client-record">
                                <h5>CLIENT RECORD</h5>
                                <p>
                                    <b className="action-taken">Actions taken -</b>  Physical observations; observations of client wellbeing; who, what, when, outcome.
                                </p>
                                <h6>Comments:</h6>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <CharacterCounter>
                                        <Form.Control
                                            value={this.props.patrol.clientComments}
                                            disabled={this.props.patrol.isCheckout}
                                            onChange={(e) => this.props.onChange(e)}
                                            as="textarea"
                                            rows={3}
                                            name="clientComments"
                                            maxLength={255} />
                                    </CharacterCounter>
                                </Form.Group>
                                {this.props.clientTab && <h6>CLIENT EXIT DETAILS</h6>}
                            </div>
                        </Col>
                    </Row>
                    {this.props.clientTab && <Row className="d-flex align-items-center bg-grey p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Has a referral been made on behalf of the client? </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.isReferral} disabled={this.props.patrol.isCheckout} value={false} onChange={(e) => { this.props.onChange(e, 'check'); this.setState({ show: false }) }} type="radio" name="isReferral" id="community-checkbox30" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox30">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Control checked={this.props.patrol.isReferral} disabled={this.props.patrol.isCheckout} value={true} onChange={(e) => { this.props.onChange(e, 'check'); this.setState({ show: true }) }} type="radio" name="isReferral" id="community-checkbox31" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox31">Yes. If yes, please ensure a Client referral and consent from is complete.</label>
                                </Col>
                                <Col md={4}>
                                    {this.props.patrol.isReferral && <Button type="button" size="sm" onClick={(e) => this.setState({ show: true, isUpdate: true })} variant="outline-danger" className="btn btn-sm mt-2"> <EditIcon></EditIcon> </Button>}

                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                    {this.props.clientTab && <Row className="p-2 ">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Estimated time spent (minutes)</Form.Label>
                                </Col>

                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={5}>
                                    <Form.Control value={this.props.patrol.estimatedTime} name="estimatedTime" onChange={(e) => this.props.onChange(e)} type="number" className="form-fields pt-0" placeholder="Estimated Time" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                    <Row className="p-2">
                        {this.props.clientTab && <>
                            <Col md={5}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Label className="text-label">Has the client collected their personal belongings?</Form.Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={7}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isBelongingTaken === 1} disabled={this.props.patrol.isCheckout} value={1} name="isBelongingTaken" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox61" className="custom-checkbox" />
                                        <label htmlFor="community-checkbox61">Yes</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isBelongingTaken === 2} disabled={this.props.patrol.isCheckout} value={2} name="isBelongingTaken" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox62" className="custom-checkbox" />
                                        <label htmlFor="community-checkbox62">No</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isBelongingTaken === 3} disabled={this.props.patrol.isCheckout} value={3} name="isBelongingTaken" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox63" className="custom-checkbox" />
                                        <label htmlFor="community-checkbox63">N/A</label>
                                    </Col>
                                </Row>
                            </Col>
                        </>}
                        {this.props.clientTab && <Col md={5}>
                            <Row>
                                <Col md={12}>
                                    <Form.Label className="text-label">How did the client leave the service?</Form.Label>
                                </Col>
                            </Row>
                        </Col>}
                        <Col md={12}>
                            <Row>
                                {this.props.clientTab && <>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isLeaveDiversion == 1} disabled={this.props.patrol.isCheckout} value={1} name="isLeaveDiversion" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox32" className="custom-checkbox" />
                                        <label htmlFor="community-checkbox32">Transported to a safe place (please advise the location)</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <LocationSearchInput address={this.props.patrol.leaveDiversionComment1} onSelect={(address) => this.props.onChange({ target: { name: 'leaveDiversionComment1', value: address } })}></LocationSearchInput>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isLeaveDiversion == 4} disabled={this.props.patrol.isCheckout} value={4} name="isLeaveDiversion" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox35" className="custom-checkbox" />
                                        <label htmlFor="community-checkbox35">Other (please provide details)</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group controlId="exampleForm.ControlTextarea2">
                                            <CharacterCounter>
                                                <Form.Control
                                                    disabled={this.props.patrol.isCheckout}
                                                    value={this.props.patrol.leaveDiversionComment2 ? this.props.patrol.leaveDiversionComment2 : ''}
                                                    onChange={(e) => this.props.onChange(e)}
                                                    name="leaveDiversionComment2"
                                                    as="textarea"
                                                    rows={2}
                                                    maxLength={255} />
                                            </CharacterCounter>
                                        </Form.Group>
                                    </Col>
                                </>}
                                {
                                    !this.props.patrol.isCheckout && (<Col md={12}>
                                        <div className="btn-sec full-width form-btn text-right">
                                            {
                                                this.props.isSaveButtons &&
                                                (<div className="btn-sec  form-btn">
                                                    <button type="submit" className="main-btn default-btn btn btn-primary">
                                                        Check In
                                                    </button>
                                                </div>)
                                            }
                                            {
                                                !this.props.isSaveButtons &&
                                                (<div className="btn-sec full-width form-btn text-right">
                                                    <button type="submit" className="main-btn default-btn btn btn-primary mr-2"> Save </button>
                                                    <button type="button" onClick={(e) => this.props.onhandleOnSubmit(e, 'checkOut')} className="main-btn default-btn btn btn-primary"> Checkout  </button>
                                                </div>)
                                            }
                                        </div>
                                    </Col>)
                                }

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Modal
                                className="form-consent"
                                show={this.state.show}
                                onHide={close}
                                aria-labelledby="contained-modal-title"
                            >
                                <Modal.Body>
                                    <Container>
                                        <Row className="d-flex align-items-center">
                                            <Col md={12}>
                                                <div className="form-consent-heading pt-3">
                                                    <Form.Label className="text-label">Referral Form</Form.Label>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-consent-body">
                                                    <p className="form-para">Please make sure to consider that consent can only be obtained if <small>(All options must be ticked)</small>:</p>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control checked={this.props.patrol.thingsToConsider} disabled={this.props.patrol.isCheckout} value={!this.props.patrol.thingsToConsider} onChange={(e) => this.props.onChange(e, 'check')} name="thingsToConsider" type="checkbox" id="community-checkbox71" className="custom-checkbox" />
                                                <label htmlFor="community-checkbox71">The Client is sober and not intoxicated</label>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control checked={this.props.patrol.thingsToConsider2} disabled={this.props.patrol.isCheckout} value={!this.props.patrol.thingsToConsider2} onChange={(e) => this.props.onChange(e, 'check')} name="thingsToConsider2" type="checkbox" id="community-checkbox72" className="custom-checkbox" />
                                                <label htmlFor="community-checkbox72">The Client is sound mind and their decision-making is not impaired</label>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control checked={this.props.patrol.thingsToConsider3} disabled={this.props.patrol.isCheckout} value={!this.props.patrol.thingsToConsider3} onChange={(e) => this.props.onChange(e, 'check')} name="thingsToConsider3" type="checkbox" id="community-checkbox73" className="custom-checkbox" />
                                                <label htmlFor="community-checkbox73">The Client has provided verbal consent</label>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-consent-heading pt-3">
                                                    <Form.Label className="text-label"><small>The Client Agrees That:</small></Form.Label>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-consent-body">
                                                    <Form.Control checked={this.props.patrol.agreeFor} disabled={this.props.patrol.isCheckout} value={!this.props.patrol.agreeFor} onChange={(e) => this.props.onChange(e, 'check')} name="agreeFor" type="checkbox" id="community-checkbox70" className="custom-checkbox" />
                                                    <label htmlFor="community-checkbox70">My personal details may be used so that the following additional support can be provided to me:</label>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-consent-body">
                                                    <Form.Control checked={this.props.patrol.isReferralSupport} disabled={this.props.patrol.isCheckout} value={!this.props.patrol.isReferralSupport} onChange={(e) => this.props.onChange(e, 'check')} name="isReferralSupport" type="checkbox" id="community-checkbox169" className="custom-checkbox" />
                                                    <label htmlFor="community-checkbox169">A referral to a support service</label>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Autocomplete
                                                    id="filter-demo"
                                                    options={this.state.contacts}
                                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}(${option.organizationName})`}
                                                    style={{ width: 300 }}
                                                    onChange={this.contactChange}
                                                    defaultValue={this.state.contacts.find(elem => elem.id === this.props.patrol.referrelId)}
                                                    disabled={!this.props.patrol.isReferralSupport}
                                                    renderInput={(params) => <TextField {...params} label="Contact Form" variant="outlined" />}
                                                />
                                            </Col>
                                            <Col md={10}>
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                    <CharacterCounter>
                                                        <Form.Control
                                                            disabled={!this.props.patrol.isReferralSupport || this.props.patrol.isCheckout}
                                                            value={(this.props.patrol.isReferralSupport && this.props.patrol.agreeForDetail3) ? this.props.patrol.agreeForDetail3 : ''}
                                                            onChange={(e) => this.props.onChange(e)}
                                                            name="agreeForDetail3"
                                                            as="textarea"
                                                            rows={2}
                                                            maxLength={255} />
                                                    </CharacterCounter>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row className="d-flex align-items-center">
                                            <Col md={6}>
                                                <Button onClick={this.handleClose} className="main-btn default-btn"> Close</Button>

                                                <Button onClick={this.handleSave} className="main-btn default-btn"> Save</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>

                </CustomForm>
            </div>
        )
    }
}

export default AddNewCommunity;