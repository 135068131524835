import React from "react";
import { Row, Col } from "react-bootstrap";
import CustomForm from "react-validation/build/form";
import Form from "../shared/form-control";
import "./locationForm.scss";
import { required } from "../../helpers/form-validation";
import Switch from "@material-ui/core/Switch";
import { connect } from "react-redux";
import store from "../../store";
import {
  UpdateLocationAction,
  CreateLocationAction,
} from "../../actions/location-action";
import CharacterCounter from "../../containers/character-counter/character-counter";

import { styled } from "@material-ui/styles";

import Styles from "../shared/css/default.module.css";

class LocationForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      location: {
        name: "",
        communityPatrol: false,
        cellVisit: false,
        diversionCenter: false,
      },
    };
  }
  componentDidMount() {
    if (this.props.location && Object.keys(this.props.location).length > 0) {
      this.setState({ location: this.props.location });
    }
  }
  handleChange = (e, checked) => {
    let location = this.state.location;
    if (checked) {
      location[e.target.name] = e.target.checked;
    } else {
      location[e.target.name] = e.target.value;
    }
    this.setState({ location });
  };
  handleOnSubmit(e) {
    e.preventDefault();
    this.form.validateAll();
    if (this.form.getChildContext()._errors.length === 0) {
      if (
        this.props.location &&
        Object.keys(this.props.location).length > 0 &&
        this.state.location.id
      ) {
        store.dispatch(UpdateLocationAction(this.state.location)); //set in session storage
      } else {
        store.dispatch(CreateLocationAction(this.state.location)); //set in session storage
        this.setState({
          location: {
            name: "",
            communityPatrol: false,
            cellVisit: false,
            diversionCenter: false,
          },
        });
      }
    }
  }
  // componentWillUnmount() {
  //     // store.dispatch({ type: REPORT_DATA, payload: { data: '', start: new Date(), end: new Date() } })
  // }

  //   render() {
  //     return (
  //       <div className="report-form">
  //         <div className="tabs-section full-width mt-4">
  //           <Row>
  //             <Col md={12}>
  //               <CustomForm
  //                 ref={(c) => {
  //                   this.form = c;
  //                 }}
  //                 autoComplete="false"
  //                 onSubmit={this.handleOnSubmit}
  //               >
  //                 <Row className="report-section d-flex align-items-center p-1 bg-grey">
  //                   <Col>
  //                     <Row className="d-flex align-items-center">
  //                       <Col md={6}>
  //                         <Form.Label className="text-label">Location</Form.Label>
  //                       </Col>
  //                       <Col md={6}>
  //                         <CharacterCounter>
  //                           <Form.Control
  //                             validations={[required]}
  //                             value={this.state.location.name}
  //                             name="name"
  //                             onChange={this.handleChange}
  //                             className="form-fields pt-0"
  //                             placeholder="Name"
  //                             maxLength={255}
  //                           />
  //                         </CharacterCounter>
  //                       </Col>
  //                     </Row>
  //                   </Col>
  //                 </Row>
  //                 <Row className="report-section d-flex align-items-center p-1">
  //                   <Col>
  //                     <Row className="d-flex align-items-center">
  //                       <Col md={6}>
  //                         <Form.Label className="text-label">
  //                           Diversion Center
  //                         </Form.Label>
  //                       </Col>
  //                       <Col md={6}>
  //                         <Switch
  //                           checked={this.state.location.diversionCenter}
  //                           onChange={(e) => this.handleChange(e, true)}
  //                           color="primary"
  //                           name="diversionCenter"
  //                           inputProps={{ "aria-label": "primary checkbox" }}
  //                         />
  //                       </Col>
  //                     </Row>
  //                   </Col>
  //                 </Row>
  //                 <Row className="report-section d-flex align-items-center p-1 bg-grey">
  //                   <Col>
  //                     <Row className="d-flex align-items-center">
  //                       <Col md={6}>
  //                         <Form.Label className="text-label">
  //                           Cell Visit
  //                         </Form.Label>
  //                       </Col>
  //                       <Col md={6}>
  //                         <Switch
  //                           checked={this.state.location.cellVisit}
  //                           onChange={(e) => this.handleChange(e, true)}
  //                           color="primary"
  //                           name="cellVisit"
  //                           inputProps={{ "aria-label": "primary checkbox" }}
  //                         />
  //                       </Col>
  //                     </Row>
  //                   </Col>
  //                 </Row>
  //                 <Row className="report-section d-flex align-items-center p-1">
  //                   <Col>
  //                     <Row className="d-flex align-items-center">
  //                       <Col md={6}>
  //                         <Form.Label className="text-label">
  //                           Community Patrol
  //                         </Form.Label>
  //                       </Col>
  //                       <Col md={6}>
  //                         <Switch
  //                           checked={this.state.location.communityPatrol}
  //                           onChange={(e) => this.handleChange(e, true)}
  //                           color="primary"
  //                           name="communityPatrol"
  //                           inputProps={{ "aria-label": "primary checkbox" }}
  //                         />
  //                       </Col>
  //                     </Row>
  //                   </Col>
  //                 </Row>
  //                 <button
  //                   type="submit"
  //                   className="main-btn orange-btn btn btn-primary ml-3 save-btn"
  //                 >
  //                   Save
  //                 </button>
  //               </CustomForm>
  //             </Col>
  //           </Row>
  //         </div>
  //       </div>
  //     );
  //   }

  render() {
    const CustomSwitch = styled((props) => (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    ))(() => ({
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            // backgroundColor: "#2ECA45",
            backgroundColor: "#fdd817",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color: "#ccc",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.7,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: "#E9E9EA",
        opacity: 1,
        // transition: theme.transitions.create(['background-color'], {
        //   duration: 500,
        // }),
      },
    }));

    return (
      //   <div className="report-form">
      //     <div className="tabs-section full-width mt-4">
      //       <Row>
      //         <Col md={12}>
      //           <CustomForm
      //             ref={(c) => {
      //               this.form = c;
      //             }}
      //             autoComplete="false"
      //             onSubmit={this.handleOnSubmit}
      //           >
      //             <Row className="report-section d-flex align-items-center p-1 bg-grey">
      //               <Col>
      //                 <Row className="d-flex align-items-center">
      //                   <Col md={6}>
      //                     <Form.Label className="text-label">Location</Form.Label>
      //                   </Col>
      //                   <Col md={6}>
      //                     <CharacterCounter>
      //                       <Form.Control
      //                         validations={[required]}
      //                         value={this.state.location.name}
      //                         name="name"
      //                         onChange={this.handleChange}
      //                         className="form-fields pt-0"
      //                         placeholder="Name"
      //                         maxLength={255}
      //                       />
      //                     </CharacterCounter>
      //                   </Col>
      //                 </Row>
      //               </Col>
      //             </Row>
      //             <Row className="report-section d-flex align-items-center p-1">
      //               <Col>
      //                 <Row className="d-flex align-items-center">
      //                   <Col md={6}>
      //                     <Form.Label className="text-label">
      //                       Diversion Center
      //                     </Form.Label>
      //                   </Col>
      //                   <Col md={6}>
      //                     <Switch
      //                       checked={this.state.location.diversionCenter}
      //                       onChange={(e) => this.handleChange(e, true)}
      //                       color="primary"
      //                       name="diversionCenter"
      //                       inputProps={{ "aria-label": "primary checkbox" }}
      //                     />
      //                   </Col>
      //                 </Row>
      //               </Col>
      //             </Row>
      //             <Row className="report-section d-flex align-items-center p-1 bg-grey">
      //               <Col>
      //                 <Row className="d-flex align-items-center">
      //                   <Col md={6}>
      //                     <Form.Label className="text-label">
      //                       Cell Visit
      //                     </Form.Label>
      //                   </Col>
      //                   <Col md={6}>
      //                     <Switch
      //                       checked={this.state.location.cellVisit}
      //                       onChange={(e) => this.handleChange(e, true)}
      //                       color="primary"
      //                       name="cellVisit"
      //                       inputProps={{ "aria-label": "primary checkbox" }}
      //                     />
      //                   </Col>
      //                 </Row>
      //               </Col>
      //             </Row>
      //             <Row className="report-section d-flex align-items-center p-1">
      //               <Col>
      //                 <Row className="d-flex align-items-center">
      //                   <Col md={6}>
      //                     <Form.Label className="text-label">
      //                       Community Patrol
      //                     </Form.Label>
      //                   </Col>
      //                   <Col md={6}>
      //                     <Switch
      //                       checked={this.state.location.communityPatrol}
      //                       onChange={(e) => this.handleChange(e, true)}
      //                       color="primary"
      //                       name="communityPatrol"
      //                       inputProps={{ "aria-label": "primary checkbox" }}
      //                     />
      //                   </Col>
      //                 </Row>
      //               </Col>
      //             </Row>
      //             <button
      //               type="submit"
      //               className="main-btn orange-btn btn btn-primary ml-3 save-btn"
      //             >
      //               Save
      //             </button>
      //           </CustomForm>
      //         </Col>
      //       </Row>
      //     </div>
      //   </div>

      <div className="">
        <div className="">
          <CustomForm
            ref={(c) => {
              this.form = c;
            }}
            autoComplete="false"
            onSubmit={this.handleOnSubmit}
          >
            <div
              className={`${Styles.ml_main} ${Styles.mt_40}`}
              style={{ backgroundColor: "#ffffff" }}
            >
              <div className="container-fluid px-0">
                <div className="tab-content">
                  <div id="tab1" className="tab-pane active">
                    <div className={Styles.report_form}>
                      <div className="row gx-4">
                        <div
                          className="col-md-6 col-sm-12 mb-30"
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <label>Location </label>
                          <input
                            className={`${Styles.form_select} ${Styles.select_dropdown}`}
                            style={{ backgroundImage: "none" }}
                            type="text"
                            validations={[required]}
                            value={this.state.location.name}
                            name="name"
                            onChange={this.handleChange}
                            placeholder="Name"
                            maxLength={255}
                          />
                        </div>
                      </div>
                      <div className="row gx-4">
                        <div
                          className=""
                          style={{
                            marginBottom: "30px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            justifyContent: "center",
                          }}
                        >
                          <label style={{ display: "block" }}>
                            Diversion Center{" "}
                          </label>
                          <CustomSwitch
                            sx={{ m: 1 }}
                            // checked={elem.diversionCenter}
                            // onChange={(e) => this.handleChange(e, elem)}
                            name="diversionCenter"
                            checked={this.state.location.diversionCenter}
                            onChange={(e) => this.handleChange(e, true)}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${Styles.report_btn} text-end`}
                      style={{ textAlign: "start" }}
                    >
                      <button
                        type="submit"
                        className={`${Styles.btn} ${Styles.btn_yellow}`}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomForm>
        </div>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    reportData: state.report.reportData,
    start: state.report.start,
    end: state.report.end,
  };
};

export default connect(stateMap)(LocationForm);
