import React from "react";
import clsx from "clsx";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CustomForm from "react-validation/build/form";
import Table from "react-bootstrap/Table";
import Select from "react-dropdown-select";

import Form from "../shared/form-control";
import { required } from "../../helpers/form-validation";
import PhoneInput from "react-phone-number-input/input";
import "./profile.scss";
import { getUser, setUser, logout, toast } from "../../helpers/utility";
import { LOCATION } from "../../helpers/constants";
import { UpdateProfileAction } from "../../actions/user/profile";
import store from "../../store";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import InputMask from "react-input-mask";

import Styles from "../shared/css/default.module.css";

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      currentUser: {},
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDropDown = this.handleDropDown.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    var userDOB = "";
    var currentUser = getUser();

    if (getUser().dob) {
      userDOB = getUser().dob.split("T");
      userDOB = userDOB[0];
    }
    currentUser.dob = userDOB;
    this.setState({ currentUser }, () => {});
  }

  handleDropDown(value, name) {
    let currentUser = this.state.currentUser;
    if (value[0]) {
      currentUser[name] = value[0].value;
      this.setState({ currentUser });
    }
  }

  onChange(e, loc, id) {
    let currentUser = this.state.currentUser;
    currentUser[e.target.name] = e.target.value;
    this.setState({ currentUser });
  }
  handlePhone(value) {
    let currentUser = this.state.currentUser;
    if (value) currentUser["phone"] = value;
    this.setState({ currentUser });
  }
  handleOnSubmit(e, flag) {
    e.preventDefault();

    if (flag === "logout") {
      logout();
    } else {
      this.form.validateAll();
      if (this.form.getChildContext()._errors.length === 0) {
        store.dispatch(UpdateProfileAction(this.state.currentUser));
      } else {
        //
      }
    }
  }

  handleSelect(key) {
    this.setState({ key: key });
  }

  //   render() {
  //     return (
  //       <div className="profile-section full-width">
  //         <Container>
  //           <Row>
  //             <Col md={12}>
  //               <Card>
  //                 <Card.Header className="text-center">My Profile </Card.Header>
  //                 <Card.Body>
  //                   <CustomForm
  //                     ref={(c) => {
  //                       this.form = c;
  //                     }}
  //                     onSubmit={(e) => this.handleOnSubmit(e)}
  //                   >
  //                     <Form.Row className="d-flex align-items-center bg-grey p-3">
  //                       <Col md={6}>
  //                         <Row>
  //                           <Col md={5}>
  //                             <Form.Label className="pl-3">
  //                               First Name{" "}
  //                             </Form.Label>
  //                           </Col>
  //                           <Col md={7}>
  //                             <Form.Control
  //                               validations={[required]}
  //                               onChange={(e) => this.onChange(e)}
  //                               value={this.state.currentUser.firstName}
  //                               type="text"
  //                               name="firstName"
  //                               placeholder=""
  //                               className="form-field-border custom-width"
  //                             />
  //                           </Col>
  //                         </Row>
  //                       </Col>
  //                       <Col md={6}>
  //                         <Row>
  //                           <Col md={5}>
  //                             <Form.Label className="text-label">
  //                               Last Name
  //                             </Form.Label>
  //                           </Col>
  //                           <Col md={7}>
  //                             <Form.Control
  //                               validations={[required]}
  //                               onChange={(e) => this.onChange(e)}
  //                               value={this.state.currentUser.lastName}
  //                               type="text"
  //                               name="lastName"
  //                               placeholder=" "
  //                               className="form-field-border custom-width"
  //                             />
  //                           </Col>
  //                         </Row>
  //                       </Col>
  //                     </Form.Row>
  //                     <Form.Row className="d-flex align-items-center p-3">
  //                       <Col md={6}>
  //                         <Row>
  //                           <Col md={5}>
  //                             <Form.Label className="pl-3">Email </Form.Label>
  //                           </Col>
  //                           <Col md={7}>
  //                             <Form.Control
  //                               validations={[required]}
  //                               disabled
  //                               value={this.state.currentUser.email}
  //                               type="email"
  //                               placeholder="kai.aaron@gmail.com"
  //                               className="form-field-border custom-width"
  //                             />
  //                           </Col>
  //                         </Row>
  //                       </Col>
  //                       <Col md={6}>
  //                         <Row>
  //                           <Col md={5}>
  //                             <Form.Label className="text-label">
  //                               Phone
  //                             </Form.Label>
  //                           </Col>
  //                           <Col md={7}>
  //                             <InputMask
  //                               alwaysShowMask={false}
  //                               placeholder="Mobile"
  //                               className="form-field-border custom-width form-control"
  //                               value={
  //                                 this.state.currentUser.phone
  //                                   ? this.state.currentUser.phone
  //                                   : ""
  //                               }
  //                               name="phone"
  //                               onChange={this.onChange}
  //                               type="text"
  //                               fullWidth
  //                               mask="99 9999 9999"
  //                               maskChar=""
  //                             />
  //                             {/* <Form.Control validations={[required]} onChange={(e) => this.onChange(e)} value={this.state.currentUser.phone} type="text" name="phone" className="form-field-border custom-width" /> */}
  //                           </Col>
  //                         </Row>
  //                       </Col>
  //                       {/* <Col md={6}>
  //                                                 <Row>
  //                                                     <Col md={5}>
  //                                                         <Form.Label className="text-label">DOB</Form.Label>
  //                                                     </Col>
  //                                                     <Col md={7}>
  //                                                         <Form.Control validations={[required]} onChange={(e) => this.onChange(e)} value={this.state.currentUser.dob} type="date" name="dob" className="form-field-border custom-width" />
  //                                                     </Col>
  //                                                 </Row>
  //                                             </Col> */}
  //                     </Form.Row>
  //                     <Form.Row className="d-flex align-items-center bg-grey p-3">
  //                       <Col md={6}>
  //                         <Row>
  //                           <Col md={5}>
  //                             <Form.Label className="pl-3">Role </Form.Label>
  //                           </Col>
  //                           <Col md={7}>
  //                             <Form.Control
  //                               validations={[required]}
  //                               value={this.state.currentUser.role}
  //                               type="text"
  //                               disabled
  //                               placeholder="Admin"
  //                               className="form-field-border custom-width"
  //                             />
  //                           </Col>
  //                         </Row>
  //                       </Col>
  //                       <Col md={6}>
  //                         <Row>
  //                           <Col md={5}>
  //                             <Form.Label className="text-label">
  //                               Location
  //                             </Form.Label>
  //                           </Col>
  //                           <Col md={7}>
  //                             <Select
  //                               disabled={
  //                                 this.state.currentUser.role === "Admin"
  //                                   ? false
  //                                   : true
  //                               }
  //                               className="selection-select arrow-down custom-width no-border"
  //                               placeholder="Select Location"
  //                               options={LOCATION}
  //                               values={[
  //                                 ...LOCATION.filter(
  //                                   (elem) =>
  //                                     elem.value ===
  //                                     (this.state.currentUser.location
  //                                       ? this.state.currentUser.location
  //                                       : "")
  //                                 ),
  //                               ]}
  //                               name="location"
  //                               onChange={(e) =>
  //                                 this.handleDropDown(e, "location")
  //                               }
  //                             />
  //                           </Col>
  //                         </Row>
  //                       </Col>
  //                     </Form.Row>

  //                     <Row className="p-3">
  //                       <Col md={6}></Col>
  //                       <Col md={6}>
  //                         <Row className="d-flex align-items-center">
  //                           <Col md={12}>
  //                             <div class="btn-sec full-width text-right form-btn">
  //                               <button
  //                                 type="submit"
  //                                 class="main-btn default-btn btn btn-primary"
  //                               >
  //                                 Update
  //                               </button>
  //                               <button
  //                                 type="button"
  //                                 onClick={(e) =>
  //                                   this.handleOnSubmit(e, "logout")
  //                                 }
  //                                 class="main-btn default-btn btn-green btn btn-primary"
  //                               >
  //                                 Logout
  //                               </button>
  //                             </div>
  //                           </Col>
  //                         </Row>
  //                       </Col>
  //                     </Row>
  //                   </CustomForm>
  //                 </Card.Body>
  //               </Card>
  //             </Col>
  //           </Row>
  //         </Container>
  //       </div>
  //     );
  //   }

  render() {
    return (
      //   <div className="profile-section full-width">
      //     <Container>
      //       <Row>
      //         <Col md={12}>
      //           <Card>
      //             <Card.Header className="text-center">My Profile </Card.Header>
      //             <Card.Body>
      //               <CustomForm
      //                 ref={(c) => {
      //                   this.form = c;
      //                 }}
      //                 onSubmit={(e) => this.handleOnSubmit(e)}
      //               >
      //                 <Form.Row className="d-flex align-items-center bg-grey p-3">
      //                   <Col md={6}>
      //                     <Row>
      //                       <Col md={5}>
      //                         <Form.Label className="pl-3">
      //                           First Name{" "}
      //                         </Form.Label>
      //                       </Col>
      //                       <Col md={7}>
      //                         <Form.Control
      //                           validations={[required]}
      //                           onChange={(e) => this.onChange(e)}
      //                           value={this.state.currentUser.firstName}
      //                           type="text"
      //                           name="firstName"
      //                           placeholder=""
      //                           className="form-field-border custom-width"
      //                         />
      //                       </Col>
      //                     </Row>
      //                   </Col>
      //                   <Col md={6}>
      //                     <Row>
      //                       <Col md={5}>
      //                         <Form.Label className="text-label">
      //                           Last Name
      //                         </Form.Label>
      //                       </Col>
      //                       <Col md={7}>
      //                         <Form.Control
      //                           validations={[required]}
      //                           onChange={(e) => this.onChange(e)}
      //                           value={this.state.currentUser.lastName}
      //                           type="text"
      //                           name="lastName"
      //                           placeholder=" "
      //                           className="form-field-border custom-width"
      //                         />
      //                       </Col>
      //                     </Row>
      //                   </Col>
      //                 </Form.Row>
      //                 <Form.Row className="d-flex align-items-center p-3">
      //                   <Col md={6}>
      //                     <Row>
      //                       <Col md={5}>
      //                         <Form.Label className="pl-3">Email </Form.Label>
      //                       </Col>
      //                       <Col md={7}>
      //                         <Form.Control
      //                           validations={[required]}
      //                           disabled
      //                           value={this.state.currentUser.email}
      //                           type="email"
      //                           placeholder="kai.aaron@gmail.com"
      //                           className="form-field-border custom-width"
      //                         />
      //                       </Col>
      //                     </Row>
      //                   </Col>
      //                   <Col md={6}>
      //                     <Row>
      //                       <Col md={5}>
      //                         <Form.Label className="text-label">
      //                           Phone
      //                         </Form.Label>
      //                       </Col>
      //                       <Col md={7}>
      //                         <InputMask
      //                           alwaysShowMask={false}
      //                           placeholder="Mobile"
      //                           className="form-field-border custom-width form-control"
      //                           value={
      //                             this.state.currentUser.phone
      //                               ? this.state.currentUser.phone
      //                               : ""
      //                           }
      //                           name="phone"
      //                           onChange={this.onChange}
      //                           type="text"
      //                           fullWidth
      //                           mask="99 9999 9999"
      //                           maskChar=""
      //                         />
      //                       </Col>
      //                     </Row>
      //                   </Col>
      //                 </Form.Row>
      //                 <Form.Row className="d-flex align-items-center bg-grey p-3">
      //                   <Col md={6}>
      //                     <Row>
      //                       <Col md={5}>
      //                         <Form.Label className="pl-3">Role </Form.Label>
      //                       </Col>
      //                       <Col md={7}>
      //                         <Form.Control
      //                           validations={[required]}
      //                           value={this.state.currentUser.role}
      //                           type="text"
      //                           disabled
      //                           placeholder="Admin"
      //                           className="form-field-border custom-width"
      //                         />
      //                       </Col>
      //                     </Row>
      //                   </Col>
      //                   <Col md={6}>
      //                     <Row>
      //                       <Col md={5}>
      //                         <Form.Label className="text-label">
      //                           Location
      //                         </Form.Label>
      //                       </Col>
      //                       <Col md={7}>
      //                         <Select
      //                           disabled={
      //                             this.state.currentUser.role === "Admin"
      //                               ? false
      //                               : true
      //                           }
      //                           className="selection-select arrow-down custom-width no-border"
      //                           placeholder="Select Location"
      //                           options={LOCATION}
      //                           values={[
      //                             ...LOCATION.filter(
      //                               (elem) =>
      //                                 elem.value ===
      //                                 (this.state.currentUser.location
      //                                   ? this.state.currentUser.location
      //                                   : "")
      //                             ),
      //                           ]}
      //                           name="location"
      //                           onChange={(e) =>
      //                             this.handleDropDown(e, "location")
      //                           }
      //                         />
      //                       </Col>
      //                     </Row>
      //                   </Col>
      //                 </Form.Row>

      //                 <Row className="p-3">
      //                   <Col md={6}></Col>
      //                   <Col md={6}>
      //                     <Row className="d-flex align-items-center">
      //                       <Col md={12}>
      //                         <div class="btn-sec full-width text-right form-btn">
      //                           <button
      //                             type="submit"
      //                             class="main-btn default-btn btn btn-primary"
      //                           >
      //                             Update
      //                           </button>
      //                           <button
      //                             type="button"
      //                             onClick={(e) =>
      //                               this.handleOnSubmit(e, "logout")
      //                             }
      //                             class="main-btn default-btn btn-green btn btn-primary"
      //                           >
      //                             Logout
      //                           </button>
      //                         </div>
      //                       </Col>
      //                     </Row>
      //                   </Col>
      //                 </Row>
      //               </CustomForm>
      //             </Card.Body>
      //           </Card>
      //         </Col>
      //       </Row>
      //     </Container>
      //   </div>

      <CustomForm
        ref={(c) => {
          this.form = c;
        }}
        onSubmit={(e) => this.handleOnSubmit(e)}
      >
        <div className={`${Styles.ml_main}`}>
          <div className={`${Styles.mt_40}`}>
            <div className="container-fluid px-0">
              <div
                className="bg-white pb-100 overflow-hidden"
                style={{ paddingBottom: "170px" }}
              >
                <div className={`${Styles.tab_block} report-block`}>
                  <div className="tab-content">
                    <div id="tab1" className="tab-pane active">
                      <div className={Styles.report_form}>
                        <div className="row gx-4">
                          <div
                            className="col-md-6 col-sm-12 mb-30"
                            style={{
                              marginBottom: "30px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <label>First Name </label>
                            <input
                              className={`${Styles.form_select} ${Styles.select_dropdown}`}
                              style={{ backgroundImage: "none" }}
                              validations={[required]}
                              onChange={(e) => this.onChange(e)}
                              value={this.state.currentUser.firstName}
                              type="text"
                              name="firstName"
                              placeholder=""
                            />
                          </div>
                          <div
                            className="col-md-6 col-sm-12 mb-30"
                            style={{
                              marginBottom: "30px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <label>Last Name </label>
                            <input
                              className={`${Styles.form_select} ${Styles.select_dropdown}`}
                              style={{ backgroundImage: "none" }}
                              validations={[required]}
                              onChange={(e) => this.onChange(e)}
                              value={this.state.currentUser.lastName}
                              type="text"
                              name="lastName"
                              placeholder=" "
                            />
                          </div>
                          <div
                            className="col-md-6 col-sm-12 mb-30"
                            style={{
                              marginBottom: "30px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <label>Email </label>
                            <input
                              className={`${Styles.form_select} ${Styles.select_dropdown}`}
                              style={{ backgroundImage: "none" }}
                              onChange={this.handleChange}
                              validations={[required]}
                              disabled
                              value={this.state.currentUser.email}
                              type="email"
                              placeholder="kai.aaron@gmail.com"
                            />
                          </div>
                          <div
                            className="col-md-6 col-sm-12 mb-30"
                            style={{
                              marginBottom: "30px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <label>Phone </label>
                            <InputMask
                              alwaysShowMask={false}
                              placeholder="Mobile"
                              className={`${Styles.form_select} ${Styles.select_dropdown}`}
                              style={{ width: "100%", backgroundImage: "none" }}
                              value={
                                this.state.currentUser.phone
                                  ? this.state.currentUser.phone
                                  : ""
                              }
                              name="phone"
                              onChange={this.onChange}
                              type="text"
                              fullWidth
                              mask="99 9999 9999"
                              maskChar=""
                            />
                          </div>
                          <div
                            className="col-md-6 col-sm-12 mb-30"
                            style={{
                              marginBottom: "30px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <label>Role </label>
                            <input
                              className={`${Styles.form_select} ${Styles.select_dropdown}`}
                              style={{ backgroundImage: "none" }}
                              onChange={this.handleChange}
                              maxLength={255}
                              validations={[required]}
                              value={this.state.currentUser.role}
                              type="text"
                              disabled
                              placeholder="Admin"
                            />
                          </div>
                          <div
                            className="col-md-6 col-sm-12 mb-30"
                            style={{
                              marginBottom: "30px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <label>Location </label>
                            <Select
                              disabled={
                                this.state.currentUser.role === "Admin"
                                  ? false
                                  : true
                              }
                              className={`${Styles.form_select} ${Styles.select_dropdown}`}
                              placeholder="Select Location"
                              options={LOCATION}
                              values={[
                                ...LOCATION.filter(
                                  (elem) =>
                                    elem.value ===
                                    (this.state.currentUser.location
                                      ? this.state.currentUser.location
                                      : "")
                                ),
                              ]}
                              name="location"
                              onChange={(e) =>
                                this.handleDropDown(e, "location")
                              }
                              style={{
                                paddingLeft: "14px",
                                paddingTop: "12px",
                                paddingBottom: "12px",
                                paddingRight: "14px",
                                backgroundImage: "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${Styles.report_btn} text-end`}
                        style={{ textAlign: "end" }}
                      >
                        <button
                          type="submit"
                          className={`${Styles.btn} ${Styles.btn_yellow}`}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          onClick={(e) => this.handleOnSubmit(e, "logout")}
                          className={`${Styles.btn} ${Styles.btn_red}`}
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomForm>
    );
  }
}
const stateMap = (state) => {
  return {
    userUpdate: state.user.user,
  };
};

export default connect(stateMap)(ProfileForm);
