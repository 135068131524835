import React from "react";
import { Row, Col } from "react-bootstrap";
import CustomForm from "react-validation/build/form";
import "./category.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { connect } from "react-redux";
import store from "../../store";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CategoryForm from "./categoryForm";
import {
  GetCategoryAction,
  UpdateCategoryAction,
} from "../../actions/category-action";

import Styles from "../shared/css/default.module.css";

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.state = {
      startDate: null,
      endDate: null,
      categories: [],
      category: {},
      key: "categories",
      activeTab: 1,
    };
  }
  componentDidMount() {
    store.dispatch(GetCategoryAction());
  }
  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      this.setState({ categories: this.props.categories, key: "categories" });
      this.handleOnSelect("categories");
    }

    if (
      JSON.stringify(prevProps.category) !== JSON.stringify(this.props.category)
    ) {
      store.dispatch(GetCategoryAction());
    }
  }

  componentWillUnmount() {
    // store.dispatch({ type: REPORT_DATA, payload: { data: '', start: new Date(), end: new Date() } })
  }

  insertItem(array, action) {
    return [...array.slice(0), action, ...array.slice(0, 0)];
  }

  handleChange = (e, params) => {
    let index = this.state.categories.findIndex(
      (elem) => elem.id === params.id
    );
    let category = params;
    category[e.target.name] = e.target.checked;
    let temp = this.state.categories;
    temp[index] = category;
    this.setState({ categories: [...temp] });
    store.dispatch(UpdateCategoryAction(category));
  };

  handleOnSelect = (key) => {
    if (key === "categories") {
      this.setState({ category: {}, key: key });
    } else {
      this.setState({ key: key });
    }
  };

  handleTab(e) {
    this.setState({ activeTab: e });
  }

  // render() {

  //   return (
  //     <div className="report-form">
  //       <div className="tabs-section full-width mt-4">
  //         <Tabs activeKey={this.state.key} onSelect={(k) => this.handleOnSelect(k)} id="controlled-tab-example">
  //           <Tab eventKey="categories" title={<span>Categories</span>} className="tab-table">
  //             {this.state.key === "categories" && <Row>
  //               <Col md={12}>
  //                 <CustomForm>
  //                   <TableContainer component={Paper}>
  //                     <Table aria-label="simple table">
  //                       <TableHead>
  //                         <TableRow>
  //                           <TableCell style={{ cursor: 'pointer' }} align="center">Name</TableCell>
  //                         </TableRow>
  //                       </TableHead>
  //                       <TableBody>

  //                         {this.state.categories && this.state.categories.map((elem, index) => (

  //                           <TableRow style={{ cursor: 'pointer' }} key={"Category" + index}>
  //                             <TableCell align="center" onClick={() => this.setState({ category: elem, key: 'AddNew' })} className={index % 2 === 0 ? "bg-grey" : ""}>
  //                               {elem.name}
  //                             </TableCell>
  //                           </TableRow>
  //                         ))}

  //                       </TableBody>
  //                     </Table>
  //                   </TableContainer>
  //                 </CustomForm>
  //               </Col>
  //             </Row>}
  //           </Tab>
  //           <Tab eventKey="AddNew" title={<span>{this.state.category.name ? this.state.category.name : "Add New"}</span>} className="tab-table">
  //             {this.state.key === "AddNew" && <CategoryForm category={this.state.category} />}
  //           </Tab>
  //         </Tabs>
  //       </div>
  //     </div>
  //   )
  // }

  render() {
    return (
      // <div className="report-form">
      //   <div className="tabs-section full-width mt-4">
      //     <Tabs
      //       activeKey={this.state.key}
      //       onSelect={(k) => this.handleOnSelect(k)}
      //       id="controlled-tab-example"
      //     >
      //       <Tab
      //         eventKey="categories"
      //         title={<span>Categories</span>}
      //         className="tab-table"
      //       >
      //         {this.state.key === "categories" && (
      //           <Row>
      //             <Col md={12}>
      //               <CustomForm>
      //                 <TableContainer component={Paper}>
      //                   <Table aria-label="simple table">
      //                     <TableHead>
      //                       <TableRow>
      //                         <TableCell
      //                           style={{ cursor: "pointer" }}
      //                           align="center"
      //                         >
      //                           Name
      //                         </TableCell>
      //                       </TableRow>
      //                     </TableHead>
      //                     <TableBody>
      //                       {this.state.categories &&
      //                         this.state.categories.map((elem, index) => (
      //                           <TableRow
      //                             style={{ cursor: "pointer" }}
      //                             key={"Category" + index}
      //                           >
      //                             <TableCell
      //                               align="center"
      //                               onClick={() =>
      //                                 this.setState({
      //                                   category: elem,
      //                                   key: "AddNew",
      //                                 })
      //                               }
      //                               className={index % 2 === 0 ? "bg-grey" : ""}
      //                             >
      //                               {elem.name}
      //                             </TableCell>
      //                           </TableRow>
      //                         ))}
      //                     </TableBody>
      //                   </Table>
      //                 </TableContainer>
      //               </CustomForm>
      //             </Col>
      //           </Row>
      //         )}
      //       </Tab>
      //       <Tab
      //         eventKey="AddNew"
      //         title={
      //           <span>
      //             {this.state.category.name
      //               ? this.state.category.name
      //               : "Add New"}
      //           </span>
      //         }
      //         className="tab-table"
      //       >
      //         {this.state.key === "AddNew" && (
      //           <CategoryForm category={this.state.category} />
      //         )}
      //       </Tab>
      //     </Tabs>
      //   </div>
      // </div>
      <div className={`${Styles.ml_main} ${Styles.mt_40}`}>
        <div className="container-fluid px-0">
          <div className={`${Styles.tab_block} ${Styles.diversion_tab}`}>
            <div className={Styles.bg_yellow}>
              <ul
                className={`nav nav-tabs ${Styles.nav} ${Styles.nav_tabs}`}
                role="tablist"
              >
                <li
                  className={Styles.nav_item}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    this.handleTab(e.target.id);
                    this.setState({ category: {} });
                  }}
                >
                  <a
                    className={
                      this.state.activeTab == 1
                        ? `${Styles.nav_link} ${Styles.active}`
                        : `${Styles.nav_link}`
                    }
                    id="1"
                    data-bs-toggle="tab"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Categories
                  </a>
                </li>
                <li
                  className={Styles.nav_item}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => this.handleTab(e.target.id)}
                >
                  <a
                    className={
                      this.state.activeTab == 2
                        ? `${Styles.nav_link} ${Styles.active}`
                        : `${Styles.nav_link}`
                    }
                    data-bs-toggle="tab"
                    id="2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.category.name
                      ? this.state.category.name
                      : "Add New"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.state.activeTab == 1 && (
          <div className="tab-content" style={{ paddingBottom: "50px" }}>
            <div id="tab1" className="tab-pane active">
              <div
                className="table-responsive"
                style={{ overflowX: "auto", position: "relative" }}
              >
                <table
                  className={`${Styles.table_custom}`}
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {this.state.categories &&
                      this.state.categories.map((elem, index) => (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          key={"Location" + index}
                        >
                          <TableCell
                            align="center"
                            onClick={() =>
                              this.setState({
                                category: elem,
                                key: "AddNew",
                                activeTab: 2,
                              })
                            }
                            // className={index % 2 === 0 ? "bg-grey" : ""}
                          >
                            {elem.name}
                          </TableCell>
                        </TableRow>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {this.state.activeTab == 2 && (
          <CategoryForm category={this.state.category} />
        )}
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    categories: (state.category && state.category.categories) || [],
    category: (state.category && state.category.category) || {},
  };
};

export default connect(stateMap)(Category);
