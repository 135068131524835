import React from 'react';
import { Row, Col } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import Search from "../../components/search/search";
import { connect } from "react-redux";
import ActiveUserCommunity from "../../components/community-patrol/active-user-community";
import TableHeadCommunity from "../../components/community-patrol/table-head-community";
import AddNewCommunity from "../../components/community-patrol/add-new-community";
import "./community-patrol.scss";
import store from '../../store';
import { CreateClientPatrolAction, updateClientPatrolAction } from '../../actions/community-patrol';
import { COMMUNITY_PATROL_TAB, IS_CLICKED_PATROL, IS_MEDICAL_OBSERVATION, MEDICAL_OBSERVATION } from '../../helpers/constants';
import { SearchClientsAction } from '../../actions/client-action';
import { toast, getUser } from '../../helpers/utility';
import { GetContactsAction } from '../../actions/contact-action';
import { GetLocationAction } from '../../actions/location-action';

class CommunityForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'actives',
            selectedClientId: '',
            isSaveButtons: false,
            isMedicalCheck: false,
            patrolClickedData: {},
            search: '',
            concentClickedData: {
                thingsToConsider: false,
                thingsToConsider2: false,
                thingsToConsider3: false,
            },
            patrol: {
                dropOff: '',
                timeIn: '',
                safePlaceDetails: '',
                violanceDetails: '',
                clientComments: '',

                isViolence: false,
                isMedicalCondition: false,
                isServerRefused: false,
                isCheckout: false,
                isBelonging: false,
                isSafePlace: false,
                isReferral: false,
                isLeaveDiversion: 1,
                leaveDiversionComment1: "",
                isLeaveAmbulance: false,
                isRelease: false,
                isOther: false,
                leaveDiversionComment2: "",
                isArchived: false,
                clientId: 0,
                createdBy: 0,
                serverRefusedBy: 0,
                thingsToConsider: false,
                thingsToConsider2: false,
                thingsToConsider3: false,
                isAmbulance: false,
                isArrangements: 0,
                estimatedTime: 0,
                isAggression: false,
                isOnMedication: false,
                medicationName: '',
                medicationDate: '',
                medicationTime: '',
            }
        };
        this.searchChanged = this.searchChanged.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.handleDropDown = this.handleDropDown.bind(this);
        this.tabChangeHandler = this.tabChangeHandler.bind(this);
        this.onChangeConcent = this.onChangeConcent.bind(this);
    }

    componentDidMount() {
        this.setState({ patrolClickedData: this.state.patrol }, () => {

        });
        if (this.props.match.params.key) {

            this.tabChangeHandler(this.props.match.params.key);
        }
        if (this.props.match.params.id && this.props.searchClients.length > 0) {
            this.setState({ selectedClientId: this.props.match.params.id })
            setTimeout(() => {
                this.onRowSelect(false, this.props.match.params.id)
            }, 1000)
        } else {
            store.dispatch(SearchClientsAction(`from=community&query=&page=1`))
        }

        if (this.props.locations && this.props.locations.length > 0) {
            let locations = this.props.locations.filter(elem => elem.communityPatrol);
            console.log("Updated Community Patrol Locations", locations);
            this.setState({ locations: locations.map(elem => elem = { label: elem.name, value: elem.id, name: elem.name }) })
        }

        store.dispatch(GetLocationAction());
        store.dispatch(GetContactsAction())
    }
    searchChanged(search) {
        this.setState({ search })
    }
    onClose() {

        let data = this.state.patrolClickedData
        data.isReferral = false
        this.setState({ patrolClickedData: data })
    }
    onChangeConcent(e, loc, id) {


        let concentClickedData = this.state.concentClickedData

        // 
        concentClickedData[e.target.name] = eval(e.target.value)
        this.setState({ concentClickedData })
    }
    onSave() {
        this.setState({ patrolClickedData: { ...this.state.patrolClickedData, ...this.state.concentClickedData } })
    }
    onRowSelect(e, id) {
        this.setState({ selectedClientId: id })
        let selectedClient = this.props.searchClients.filter(elem => elem.id === id)
        // store.dispatch({
        //     type: SEARCH_CLIENT_RES,
        //     payload: selectedClient
        // });

        // return;

        if (selectedClient?.length > 0 && selectedClient[0]?.CommunityPatrols?.length > 0) {
            this.tabChangeHandler('client');
            this.setState({
                patrolClickedData: {
                    ...selectedClient[0].CommunityPatrols[0], Client: {
                        id: selectedClient[0].id, name: selectedClient[0].name, firstName: selectedClient[0].firstName,
                        lastName: selectedClient[0].lastName, dob: selectedClient[0].dob, phone: selectedClient[0].phone
                    }
                }, isSaveButtons: false
            }, () => {
                store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: { ...this.state.patrolClickedData } })
                store.dispatch({ type: IS_CLICKED_PATROL, payload: true });


            })
            // store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: selectedClient[0] })

            // store.dispatch({ type: PETROL_CLIENT, payload: selectedClient.CommunityPatrols[0] })
        } else {
            // this.setState({ patrolClickedData: {}, isSaveButtons: true },() =>{

            let patrolClickedData = this.state.patrol
            patrolClickedData['clientId'] = id
            patrolClickedData['timeIn'] = new Date()
            this.setState({ patrolClickedData, isSaveButtons: true }, () => {
                store.dispatch({ type: IS_CLICKED_PATROL, payload: true });
            })
            // store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: patrolClickedData, Client: { id: selectedClient[0].id, name: selectedClient[0].name } })
            // })



            // store.dispatch({ type: PETROL_CLIENT, payload: {} })
        }
        // store.dispatch({ type: IS_CLICKED_PATROL, payload: true });

    }

    handleOnSubmit(e, flag) {
        e.preventDefault();

        let patrolClickedData = this.state.patrolClickedData
        patrolClickedData["serviceLocation"] = getUser().location
        if (this.props.isMedicalObservation) {
            patrolClickedData['medicalObservation'] = this.props.medicalObservations
        } else {
            patrolClickedData['medicalObservation'] = []
        }

        if (this.props.domesticViolance) {
            patrolClickedData['domesticViolance'] = this.props.domesticViolance
        } else {
            patrolClickedData['domesticViolance'] = []
        }
        if (!patrolClickedData.isReferral) {
            patrolClickedData['agreeFor'] = false
            patrolClickedData['isReferralSupport'] = false
            patrolClickedData['agreeForDetail1'] = ''
            patrolClickedData['agreeForDetail2'] = ''
            patrolClickedData['agreeForDetail3'] = ''
            patrolClickedData['agreeForService'] = 0
            patrolClickedData['clientName'] = ''
            patrolClickedData['thingsToConsider'] = false
            patrolClickedData['thingsToConsider2'] = false
            patrolClickedData['thingsToConsider3'] = false
        }


        if (patrolClickedData.isSafePlace &&
            (!patrolClickedData.safePlaceDetails || patrolClickedData.safePlaceDetails == '')) {
            toast('Please provide SAFE PLACE contact person details', 'error')
            return false;
        } else {
            if (!patrolClickedData.isSafePlace) {
                patrolClickedData["safePlaceDetails"] = ""
                this.setState({ patrolClickedData })
            }
        }

        if (patrolClickedData.isServerRefused &&
            (patrolClickedData.serverRefusedBy == 0)) {
            toast('Please provide Service Refused By', 'error')
            return false;
        } else {
            if (!patrolClickedData.isServerRefused) {
                patrolClickedData["serverRefusedBy"] = 0
                this.setState({ patrolClickedData })
            }
        }
        if (patrolClickedData.isBelongingTaken === '') {
            toast('Please provide personal belonging status ', 'error')
            return false;
        }

        if (!patrolClickedData.locationId || patrolClickedData.locationId === null) {
            toast('Please provide Pick Up location', 'error')
            return false;
        }
        
        if (!patrolClickedData.accessComment) {
            toast('Please indicate how the client accessed the service', 'error')
            return false;
        }

        if (patrolClickedData.isOnMedication &&
            (!patrolClickedData.medicationName || patrolClickedData.medicationName == '')) {
            toast('Please provide Medication name', 'error')
            return false;
        } else {
            if (!patrolClickedData.isOnMedication) {
                patrolClickedData["medicationName"] = ""
                this.setState({ patrolClickedData })
            }
        }
        if (flag === 'checkOut') {
            if (patrolClickedData.isLeaveDiversion === 1 &&
                (!patrolClickedData.leaveDiversionComment1 || patrolClickedData.leaveDiversionComment1 === '')) {
                toast('Please advise the location', 'error')
                return false;
            } else {
                if (patrolClickedData.isLeaveDiversion === 4 || patrolClickedData.isLeaveDiversion === 3 || patrolClickedData.isLeaveDiversion === 2) {
                    patrolClickedData["leaveDiversionComment1"] = ""
                    this.setState({ patrolClickedData })
                }
            }
            if (patrolClickedData.isLeaveDiversion === 4 &&
                (!patrolClickedData.leaveDiversionComment2 || patrolClickedData.leaveDiversionComment2 === '')) {
                toast('Please provide the details', 'error')
                return false;
            } else {
                if (patrolClickedData.isLeaveDiversion === 1 || patrolClickedData.isLeaveDiversion === 3 || patrolClickedData.isLeaveDiversion === 2) {
                    patrolClickedData["leaveDiversionComment2"] = ""
                    this.setState({ patrolClickedData })
                }
            }
        }
        if (flag === 'checkOut' && !patrolClickedData.isLeaveDiversion) {
            toast('Please provide client leave service', 'error')
            return false;
        }
        if (flag === 'checkOut' && (!patrolClickedData.estimatedTime || patrolClickedData.estimatedTime < 1)) {
            toast('Please provide client Estimated Time', 'error')
            return false;
        }
        if (!this.state.isSaveButtons) {

            if (flag == 'checkOut') {
                patrolClickedData['isCheckout'] = true
                patrolClickedData['checkoutAt'] = new Date()

                // return;

                store.dispatch(updateClientPatrolAction(patrolClickedData))
            } else {

                store.dispatch(updateClientPatrolAction(patrolClickedData))
            }

            // store.dispatch({ type: PETROL_CLIENT, payload: {} })
        } else {

            store.dispatch(CreateClientPatrolAction(patrolClickedData))
        }
        this.setState({ patrolClickedData: this.state.patrol })
        store.dispatch({ type: MEDICAL_OBSERVATION, payload: [] });
        store.dispatch({ type: IS_MEDICAL_OBSERVATION, payload: false });
        store.dispatch({ type: IS_CLICKED_PATROL, payload: false });
        this.setState({ isSaveButtons: false })
    }

    onChange(e, loc, id) {


        let patrolClickedData = this.state.patrolClickedData
        if (loc === 'check') {

            patrolClickedData[e.target.name] = eval(e.target.value)
            if (e.target.name == 'isMedicalCondition') {
                this.setState({ isMedicalCheck: eval(e.target.value) })
                store.dispatch({
                    type: IS_MEDICAL_OBSERVATION,
                    payload: eval(e.target.value)
                });
            }
            if (e.target.name == 'isServerRefused' && eval(e.target.value)) {
                patrolClickedData['isLeaveDiversion'] = 4
                patrolClickedData['leaveDiversionComment2'] = "Service Refused"
            }
        } else {

            patrolClickedData[e.target.name] = e.target.value
        }
        this.setState({ patrolClickedData })
    }

    componentDidUpdate(prevProp) {
        if (this.props.newSearch !== prevProp.newSearch) {
            // 
            // this.onRowSelect(false, this.props.match.params.id)
            this.setState({ selectedClientId: '' })
        }
        if (this.props.searchClients !== prevProp.searchClients) {

        }
        if (this.props.communityPatrolTab && this.props.communityPatrolTab !== prevProp.communityPatrolTab) {

            this.setState({ patrolClickedData: this.props.communityPatrolTab })
        }
        if (this.props.isClickedPatrolClients !== prevProp.isClickedPatrolClients) {

        }
        if (JSON.stringify(prevProp.communityPatrol) !== JSON.stringify(this.props.communityPatrol)) {
            this.setState({ key: 'actives' })
        }

        if (JSON.stringify(prevProp.locations) !== JSON.stringify(this.props.locations)) {
            let locations = this.props.locations.filter(elem => elem.communityPatrol);
            console.log("Updated Community Patrol Locations", locations);
            this.setState({ locations: locations.map(elem => elem = { label: elem.name, value: elem.id, name: elem.name }) })
        }
    }
    handleDropDown(value, name) {
        let patrol = this.state.patrolClickedData
        if (value[0]) {
            patrol[name] = value[0].value
            this.setState({ patrolClickedData: patrol })
        }

    }
    tabChangeHandler(key) {

        switch (key) {
            case 'actives':
                // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [] })
                // store.dispatch({ type: IS_CLICKED_PATROL, payload: false });
                // store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: {} })
                break;
            case 'new':
                if (!this.props.match.params.id && this.props.searchClients.length > 1) {
                    // store.dispatch({ type: DIVERSION_TAB, payload: {} })
                    store.dispatch({ type: IS_CLICKED_PATROL, payload: false });
                }
                // store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: {} })
                // store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: {} })
                break;
            case 'client':
                // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [] })
                // store.dispatch({ type: IS_CLICKED_PATROL, payload: false });
                // store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: {} })
                break;
            default:
                break;
        }
        this.setState({ key })
    }
    componentWillUnmount() {
        store.dispatch({ type: IS_CLICKED_PATROL, payload: false });
        store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: {} })
    }
    render() {
        const options = [
            { label: "Brisbane", value: 1 },
            { label: "Bowman Johnson", value: 2 },
            { label: "Mackay", value: 3 },
            { label: "Townsville", value: 4 },
            { label: "Palm Island", value: 5 },
        ];

        return (
            <div className="community-patrol">
                <div className="tabs-section full-width mt-4">
                    <Tabs defaultActiveKey="actives" activeKey={this.state.key} onSelect={(key) => this.tabChangeHandler(key)} id="uncontrolled-tab-example">
                        <Tab eventKey="actives" title={<span>Active </span>} className="tab-table">
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'actives' && <ActiveUserCommunity from={"patrol"} setKey={(key) => this.setState({ key })} {...this.props} />}
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="new" title={<span>Add New</span>} className="tab-table comunity-user">
                            {this.state.key === 'new' && <Search from={'community'} searchChanged={this.searchChanged} {...this.props} />}
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'new' &&
                                        <TableHeadCommunity
                                            search={this.state.search}
                                            handleDropDown={this.handleDropDown}
                                            isOnlyHead={this.props.isClickedPatrolClients}
                                            patrol={this.state.patrolClickedData}
                                            clientId={this.state.selectedClientId}
                                            onChange={this.onChange}
                                            onRowSelect={this.onRowSelect}
                                            communityLocations={this.state.locations || []}
                                            {...this.props}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'new' && this.props.isClickedPatrolClients && <AddNewCommunity isMedicalCheck={this.state.isMedicalCheck} contacts={this.props.contacts} concentClickedData={this.state.concentClickedData} onSave={this.onSave} handleDropDown={this.handleDropDown} changeState={this.onClose} onChangeConcent={this.onChangeConcent} isSaveButtons={this.state.isSaveButtons} patrol={this.state.patrolClickedData} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} {...this.props} />}
                                    {/* <UpdateCommunity {...this.props} /> */}
                                </Col>
                            </Row>
                        </Tab>
                        {this.props.communityPatrolTab.id && <Tab eventKey="client" title={<span>{this.props.communityPatrolTab.Client.name}</span>} className="tab-table comunity-user">
                            {/* <Search {...this.props} /> */}
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'client' &&
                                        <TableHeadCommunity
                                            isOnlyHead={this.props.isClickedPatrolClients}
                                            clientTab={true}
                                            handleDropDown={this.handleDropDown}
                                            onChange={this.onChange}
                                            patrol={this.props.communityPatrolTab}
                                            onRowSelect={this.onRowSelect}
                                            clientId={this.props.communityPatrolTab.Client.id}
                                            communityLocations={this.state.locations || []}
                                            {...this.props}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'client' && <AddNewCommunity isMedicalCheck={this.state.isMedicalCheck} contacts={this.props.contacts} concentClickedData={this.state.concentClickedData} onSave={this.onSave} changeState={this.onClose} clientTab={true} handleDropDown={this.handleDropDown} onChangeConcent={this.onChangeConcent} isSaveButtons={false} patrol={this.props.communityPatrolTab} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} {...this.props} />}
                                    {/* <UserDetailCommunity {...this.props} /> */}
                                </Col>
                            </Row>
                        </Tab>}
                    </Tabs>
                </div>
            </div>

        )
    }
}


const stateMap = (state) => {
    return {
        searchClients: state.client.searchClients,
        searchResult: state.client.searchResult,
        totalClients: state.client.totalClients,
        clickedPatrolClients: state.communityPatrol.clickedPatrolClients,
        isClickedPatrolClients: state.communityPatrol.isClickedPatrolClients,
        communityPatrol: state.communityPatrol.communityPatrol,
        communityPatrolTab: state.communityPatrol.communityPatrolTab,
        contacts: state.contact.contacts,

        newSearch: state.client.newSearch,

        isMedicalObservation: state.medicalObservation.isMedicalObservation,
        medicalObservations: state.medicalObservation.medicalObservations,

        isDomesticViolance: state.domesticViolance.isDomesticViolance,
        domesticViolance: state.domesticViolance.domesticViolance,

        locations: state.location.locations
    };
};
// export default connect(stateMap)(MainClientsContainer);
export default connect(stateMap)(CommunityForm);