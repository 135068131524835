import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import download from "js-file-download";
import CustomForm from "react-validation/build/form";
import "./report-from.scss";
import { getSystemReportAction } from "../../actions/report-action";
import { toast } from "../../helpers/utility";
import moment from "moment";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { CsvToHtmlTable } from "react-csv-to-table";
import { connect } from "react-redux";
import store from "../../store";
import { REPORT_DATA } from "../../helpers/constants";

import Styles from "../shared/css/default.module.css";

class ReportFrom extends React.Component {
  constructor(props) {
    super(props);
    this.fetchReport = this.fetchReport.bind(this);
    this.changeStart = this.changeStart.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeEnd = this.changeEnd.bind(this);

    if (props.downloadReport) {
      this.downloadReport = props.downloadReport.bind(this);
    } else {
      this.downloadReport = this.downloadReport.bind(this);
    }

    this.handleDropDown = this.handleDropDown.bind(this);
    this.state = {
      startDate: null,
      endDate: null,
      location: [],
      allLocation: [],
      minAge: 0,
      maxAge: 0,
    };
  }

  fetchReport() {
    if (!this.state.startDate) {
      toast("Select From Date", "error");
      return false;
    }
    if (!this.state.endDate) {
      toast("Select To Date", "error");
      return false;
    }
    this.props.generateReport(this.state);
  }

  changeStart(e) {
    this.setState({ startDate: e.target.value });
  }

  changeEnd(e) {
    this.setState({ endDate: e.target.value });
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  downloadReport(e) {
    if (this.props.reportData)
      download(
        this.props.reportData,
        moment(this.props.start).format("DD-MM-YYYY") +
          "-" +
          moment(this.props.end).format("DD-MM-YYYY") +
          "-Report.csv"
      );
    else toast("No data found to download", "error");
  }

  componentWillUnmount() {
    store.dispatch({
      type: REPORT_DATA,
      payload: { data: "", start: new Date(), end: new Date() },
    });
  }

  componentDidMount() {
    if (this.props.locations && this.props.locations.length > 0) {
      let locations = this.props.locations.map(
        (elem) => (elem = { label: elem.name, value: elem.id, name: elem.name })
      );
      this.setState({ allLocation: locations });
    }
  }

  componentDidUpdate(prevProp) {
    if (
      JSON.stringify(prevProp.locations) !==
      JSON.stringify(this.props.locations)
    ) {
      let locations = this.props.locations.map(
        (elem) => (elem = { label: elem.name, value: elem.id, name: elem.name })
      );
      this.setState({ allLocation: locations });
    }
  }

  handleDropDown(e) {
    let loc = [];
    e.map((ele) => {
      loc.push(ele.value);
    });
    this.setState({ location: loc });
  }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        backgroundColor: "transparent",
        border: "none",
      }),
      indicatorsContainer: (base) => ({
        display: "none",
      }),
    };

    const LOCATION = [
      { label: "Brisbane", value: "Brisbane", name: "location" },
      { label: "Mackay", value: "Mackay", name: "location" },
      { label: "Townsville", value: "Townsville", name: "location" },
      { label: "Palm Island", value: "PalmIsland", name: "location" },
      { label: "Bowman Johnson", value: "BowmanJohnson", name: "location" },
    ];
    const animatedComponents = makeAnimated();
    // return (
    //   <div className="report-form">
    //     <div className="tabs-section full-width mt-4">
    //       <Tabs
    //         defaultActiveKey="past-activities"
    //         id="uncontrolled-tab-example"
    //       >
    //         <Tab
    //           eventKey="past-activities"
    //           title={<span>{this.props.title}</span>}
    //           className="tab-table"
    //         >
    //           <Row>
    //             <Col md={12}>
    //               <CustomForm>
    //                 <Row className="report-section d-flex align-items-center p-1 bg-grey">
    //                   <Col md={4}>
    //                     <Row className="d-flex align-items-center">
    //                       <Col md={4}>
    //                         <Form.Label className="text-label">
    //                           Location
    //                         </Form.Label>
    //                       </Col>
    //                       <Col md={8}>
    //                         <div className="select-dropdown-custom">
    //                           <Select
    //                             className="custom-select-networking"
    //                             closeMenuOnSelect={false}
    //                             components={animatedComponents}
    //                             onChange={(e) => this.handleDropDown(e)}
    //                             // isDisabled={}
    //                             // defaultValue={[options2a[4], options2a[5]]}
    //                             isMulti
    //                             options={this.state.allLocation}
    //                             placeholder="Location"
    //                           />
    //                         </div>
    //                       </Col>
    //                     </Row>
    //                   </Col>
    //                   <Col md={4}>
    //                     <Row className="d-flex align-items-center">
    //                       <Col md={4}>
    //                         <Form.Label className="text-label">From</Form.Label>
    //                       </Col>
    //                       <Col md={8}>
    //                         <Form.Control
    //                           max={moment()
    //                             .format("YYYY-MM-DD")
    //                             .toString()
    //                             .trim()}
    //                           onChange={(e) => {
    //                             this.changeStart(e);
    //                           }}
    //                           placeholder="YYYY-MM-DD"
    //                           type="date"
    //                           name="start"
    //                           id="start"
    //                           className="form-field-border form-control custom-width"
    //                         />
    //                       </Col>
    //                     </Row>
    //                   </Col>
    //                   <Col md={4}>
    //                     <Row className="d-flex align-items-center">
    //                       <Col md={4}>
    //                         <Form.Label className="text-label">To</Form.Label>
    //                       </Col>
    //                       <Col md={8}>
    //                         <Form.Control
    //                           max={moment()
    //                             .format("YYYY-MM-DD")
    //                             .toString()
    //                             .trim()}
    //                           onChange={(e) => {
    //                             this.changeEnd(e);
    //                           }}
    //                           placeholder="YYYY-MM-DD"
    //                           type="date"
    //                           name="end"
    //                           end="end"
    //                           className="form-field-border custom-width"
    //                         />
    //                       </Col>
    //                     </Row>
    //                   </Col>
    //                 </Row>
    //                 <Row className="report-section d-flex align-items-center p-1 ">
    //                   <Col md={4}>
    //                     <Row className="d-flex align-items-center">
    //                       <Col md={4}>
    //                         <Form.Label className="text-label">Age</Form.Label>
    //                       </Col>
    //                     </Row>
    //                   </Col>
    //                   <Col md={4}>
    //                     <Row className="d-flex align-items-center">
    //                       <Col md={4}>
    //                         <Form.Label className="text-label">
    //                           Mininmum Age
    //                         </Form.Label>
    //                       </Col>
    //                       <Col md={8}>
    //                         <Form.Control
    //                           onChange={(e) => {
    //                             this.handleChange(e);
    //                           }}
    //                           value={this.state.minAge}
    //                           placeholder="min age"
    //                           type="number"
    //                           name="minAge"
    //                           id="minAge"
    //                           className="form-field-border form-control custom-width"
    //                         />
    //                       </Col>
    //                     </Row>
    //                   </Col>
    //                   <Col md={4}>
    //                     <Row className="d-flex align-items-center">
    //                       <Col md={5}>
    //                         <Form.Label className="text-label">
    //                           Maximum Age
    //                         </Form.Label>
    //                       </Col>
    //                       <Col md={7}>
    //                         <Form.Control
    //                           onChange={(e) => {
    //                             this.handleChange(e);
    //                           }}
    //                           placeholder="max age"
    //                           value={this.state.maxAge}
    //                           type="number"
    //                           name="maxAge"
    //                           end="end"
    //                           className="form-field-border custom-width"
    //                         />
    //                       </Col>
    //                     </Row>
    //                   </Col>
    //                 </Row>
    //                 <Row>
    //                   <Col md={4}></Col>
    //                   <Col md={2}></Col>
    //                   <Col md={3}>
    //                     <div className="btn-sec full-width text-left form-btn text-right mt-2">
    //                       <button
    //                         download
    //                         onClick={this.downloadReport}
    //                         type="button"
    //                         className="main-btn report-btn orange-btn default-btn btn btn-primary"
    //                       >
    //                         Download Report
    //                       </button>
    //                     </div>
    //                   </Col>

    //                   {this.props.generateReport && (
    //                     <Col md={3}>
    //                       <div className="btn-sec full-width text-left form-btn text-right mt-2">
    //                         <button
    //                           download
    //                           onClick={this.fetchReport}
    //                           type="button"
    //                           className="main-btn report-btn orange-btn default-btn btn btn-primary"
    //                         >
    //                           Generate Report
    //                         </button>
    //                       </div>
    //                     </Col>
    //                   )}
    //                 </Row>
    //                 <Row>
    //                   <Col md={12}>
    //                     <CsvToHtmlTable
    //                       data={this.props.reportData}
    //                       csvDelimiter=","
    //                       tableClassName="table table-striped table-hover"
    //                     />
    //                   </Col>
    //                 </Row>
    //               </CustomForm>
    //             </Col>
    //           </Row>
    //         </Tab>
    //       </Tabs>
    //     </div>
    //   </div>
    // );

    return (
      // <div className="report-form">
      //   <div className="tabs-section full-width mt-4">
      //     <Tabs
      //       defaultActiveKey="past-activities"
      //       id="uncontrolled-tab-example"
      //     >
      //       <Tab
      //         eventKey="past-activities"
      //         title={<span>{this.props.title}</span>}
      //         className="tab-table"
      //       >
      //         <Row>
      //           <Col md={12}>
      //             <CustomForm>
      //               <Row className="report-section d-flex align-items-center p-1 bg-grey">
      //                 <Col md={4}>
      //                   <Row className="d-flex align-items-center">
      //                     <Col md={4}>
      //                       <Form.Label className="text-label">
      //                         Location
      //                       </Form.Label>
      //                     </Col>
      //                     <Col md={8}>
      //                       <div className="select-dropdown-custom">
      //                         <Select
      //                           className="custom-select-networking"
      //                           closeMenuOnSelect={false}
      //                           components={animatedComponents}
      //                           onChange={(e) => this.handleDropDown(e)}
      //                           // isDisabled={}
      //                           // defaultValue={[options2a[4], options2a[5]]}
      //                           isMulti
      //                           options={this.state.allLocation}
      //                           placeholder="Location"
      //                         />
      //                       </div>
      //                     </Col>
      //                   </Row>
      //                 </Col>
      //                 <Col md={4}>
      //                   <Row className="d-flex align-items-center">
      //                     <Col md={4}>
      //                       <Form.Label className="text-label">From</Form.Label>
      //                     </Col>
      //                     <Col md={8}>
      //                       <Form.Control
      //                         max={moment()
      //                           .format("YYYY-MM-DD")
      //                           .toString()
      //                           .trim()}
      //                         onChange={(e) => {
      //                           this.changeStart(e);
      //                         }}
      //                         placeholder="YYYY-MM-DD"
      //                         type="date"
      //                         name="start"
      //                         id="start"
      //                         className="form-field-border form-control custom-width"
      //                       />
      //                     </Col>
      //                   </Row>
      //                 </Col>
      //                 <Col md={4}>
      //                   <Row className="d-flex align-items-center">
      //                     <Col md={4}>
      //                       <Form.Label className="text-label">To</Form.Label>
      //                     </Col>
      //                     <Col md={8}>
      //                       <Form.Control
      //                         max={moment()
      //                           .format("YYYY-MM-DD")
      //                           .toString()
      //                           .trim()}
      //                         onChange={(e) => {
      //                           this.changeEnd(e);
      //                         }}
      //                         placeholder="YYYY-MM-DD"
      //                         type="date"
      //                         name="end"
      //                         end="end"
      //                         className="form-field-border custom-width"
      //                       />
      //                     </Col>
      //                   </Row>
      //                 </Col>
      //               </Row>
      //               <Row className="report-section d-flex align-items-center p-1 ">
      //                 <Col md={4}>
      //                   <Row className="d-flex align-items-center">
      //                     <Col md={4}>
      //                       <Form.Label className="text-label">Age</Form.Label>
      //                     </Col>
      //                   </Row>
      //                 </Col>
      //                 <Col md={4}>
      //                   <Row className="d-flex align-items-center">
      //                     <Col md={4}>
      //                       <Form.Label className="text-label">
      //                         Mininmum Age
      //                       </Form.Label>
      //                     </Col>
      //                     <Col md={8}>
      //                       <Form.Control
      //                         onChange={(e) => {
      //                           this.handleChange(e);
      //                         }}
      //                         value={this.state.minAge}
      //                         placeholder="min age"
      //                         type="number"
      //                         name="minAge"
      //                         id="minAge"
      //                         className="form-field-border form-control custom-width"
      //                       />
      //                     </Col>
      //                   </Row>
      //                 </Col>
      //                 <Col md={4}>
      //                   <Row className="d-flex align-items-center">
      //                     <Col md={5}>
      //                       <Form.Label className="text-label">
      //                         Maximum Age
      //                       </Form.Label>
      //                     </Col>
      //                     <Col md={7}>
      //                       <Form.Control
      //                         onChange={(e) => {
      //                           this.handleChange(e);
      //                         }}
      //                         placeholder="max age"
      //                         value={this.state.maxAge}
      //                         type="number"
      //                         name="maxAge"
      //                         end="end"
      //                         className="form-field-border custom-width"
      //                       />
      //                     </Col>
      //                   </Row>
      //                 </Col>
      //               </Row>
      //               <Row>
      //                 <Col md={4}></Col>
      //                 <Col md={2}></Col>
      //                 <Col md={3}>
      //                   <div className="btn-sec full-width text-left form-btn text-right mt-2">
      //                     <button
      //                       download
      //                       onClick={this.downloadReport}
      //                       type="button"
      //                       className="main-btn report-btn orange-btn default-btn btn btn-primary"
      //                     >
      //                       Download Report
      //                     </button>
      //                   </div>
      //                 </Col>

      //                 {this.props.generateReport && (
      //                   <Col md={3}>
      //                     <div className="btn-sec full-width text-left form-btn text-right mt-2">
      //                       <button
      //                         download
      //                         onClick={this.fetchReport}
      //                         type="button"
      //                         className="main-btn report-btn orange-btn default-btn btn btn-primary"
      //                       >
      //                         Generate Report
      //                       </button>
      //                     </div>
      //                   </Col>
      //                 )}
      //               </Row>
      //               <Row>
      //                 <Col md={12}>
      //                   <CsvToHtmlTable
      //                     data={this.props.reportData}
      //                     csvDelimiter=","
      //                     tableClassName="table table-striped table-hover"
      //                   />
      //                 </Col>
      //               </Row>
      //             </CustomForm>
      //           </Col>
      //         </Row>
      //       </Tab>
      //     </Tabs>
      //   </div>
      // </div>
      <div className={`${Styles.ml_main} ${Styles.mt_40}`}>
        <div className="container-fluid px-0">
          <div
            className="bg-white pb-100 overflow-hidden"
            style={{ paddingBottom: "100px" }}
          >
            <div className={`${Styles.tab_block} report-block`}>
              <div className={Styles.bg_yellow} style={{ lineHeight: 2.5 }}>
                <ul className={`nav ${Styles.nav_tabs}`} role="tablist">
                  <li className={Styles.nav_item}>
                    <a
                      className={`${Styles.nav_link} ${Styles.active}`}
                      data-bs-toggle="tab"
                      href="#tab1"
                      style={{ paddingLeft: "45px", paddingRight: "45px" }}
                    >
                      Report Diversion Center
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div id="tab1" className="tab-pane active">
                  <div className={Styles.report_form}>
                    <div className="row gx-4">
                      <div
                        className="col-md-4 col-sm-12 mb-30"
                        style={{
                          marginBottom: "30px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        <label>Location</label>
                        <Select
                          className={`${Styles.form_select} ${Styles.select_dropdown} p-0`}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          onChange={(e) => this.handleDropDown(e)}
                          // isDisabled={}
                          // defaultValue={[options2a[4], options2a[5]]}
                          isMulti
                          options={this.state.allLocation}
                          placeholder="Location"
                          styles={customStyles}
                        />
                      </div>
                      <div
                        className="col-md-4 col-sm-6 mb-30"
                        style={{
                          marginBottom: "30px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        <label>From</label>

                        <Form.Control
                          max={moment().format("YYYY-MM-DD").toString().trim()}
                          onChange={(e) => {
                            this.changeStart(e);
                          }}
                          placeholder="YYYY-MM-DD"
                          type="date"
                          name="start"
                          id="start"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                          style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "0px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "38px",
                            backgroundImage: "none",
                          }}
                        />

                        {/* <select
                          name=""
                          id="from"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                        >
                          <option value="">dd/mm/yyyy</option>
                          <option value="">one</option>
                          <option value="">two</option>
                        </select> */}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 mb-30"
                        style={{
                          marginBottom: "30px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        <label>To</label>

                        <Form.Control
                          max={moment().format("YYYY-MM-DD").toString().trim()}
                          onChange={(e) => {
                            this.changeEnd(e);
                          }}
                          placeholder="YYYY-MM-DD"
                          type="date"
                          name="end"
                          end="end"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                          style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "0px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "38px",
                            backgroundImage: "none",
                          }}
                        />

                        {/* <select
                          name=""
                          id="to"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                        >
                          <option value="">dd/mm/yyyy</option>
                          <option value="">one</option>
                          <option value="">two</option>
                        </select> */}
                      </div>
                      <div
                        className="col-md-4 col-sm-6 mb-30 mb-sm-0"
                        style={{
                          marginBottom: "30px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        <label>Minimum Age</label>

                        <Form.Control
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          value={this.state.minAge}
                          placeholder="min age"
                          type="number"
                          name="minAge"
                          id="minAge"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                          style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "0px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "38px",
                            backgroundImage: "none",
                          }}
                        />

                        {/* <select
                          name=""
                          id="min-age"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                        >
                          <option value="">dd/mm/yyyy</option>
                          <option value="">one</option>
                          <option value="">two</option>
                        </select> */}
                      </div>
                      <div
                        className="col-md-4 col-sm-6"
                        style={{
                          marginBottom: "30px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        <label>Maximum Age</label>

                        <Form.Control
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          placeholder="max age"
                          value={this.state.maxAge}
                          type="number"
                          name="maxAge"
                          end="end"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                          style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "0px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "38px",
                            backgroundImage: "none",
                          }}
                        />

                        {/* <select
                          name=""
                          id="msax-age"
                          className={`${Styles.form_select} ${Styles.select_dropdown}`}
                        >
                          <option value="">dd/mm/yyyy</option>
                          <option value="">one</option>
                          <option value="">two</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                  <hr
                    className="mb-30"
                    style={{ marginTop: "10px", marginBottom: "30px" }}
                  />
                  <div
                    className={`${Styles.report_btn} text-end`}
                    style={{ textAlign: "end" }}
                  >
                    <button
                      className={`${Styles.btn} ${Styles.btn_yellow}`}
                      onClick={this.downloadReport}
                      download
                      type="button"
                    >
                      Download Report
                    </button>
                    {this.props.generateReport && (
                      <div
                        className={`${Styles.btn} ${Styles.btn_red}`}
                        onClick={this.fetchReport}
                      >
                        Generate Report
                      </div>
                    )}
                  </div>

                  {this.props.generateReport && (
                    <div
                      className="table-responsive"
                      style={{ position: "relative" }}
                    >
                      <CsvToHtmlTable
                        data={this.props.reportData}
                        csvDelimiter=","
                        tableClassName="table table-striped table-hover"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    start: state.report.start,
    end: state.report.end,
    locations: state.location.locations,
  };
};

export default connect(stateMap)(ReportFrom);
