import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import DiversionCentreForm from "../../components/diversion-centre/diversion-centre";

import Styles from "../../components/shared/css/default.module.css";

export default class MainDiversionCentreContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
      isSidebarOpen: false,
    };
  }
  render() {
    return (
      // <div className="admin-content" style={{ display: 'flex' }}>
      <div
        id={Styles["wrapper"]}
        className={this.state.isSidebarOpen ? Styles.toggled : ""}
      >
        <Sidebar
          isSidebarOpen={this.state.isSidebarOpen}
          {...this.props}
        ></Sidebar>
        <Header
          handleSidebar={() =>
            this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
          }
          {...this.props}
        />
        <DiversionCentreForm {...this.props} />
      </div>
    );
  }
}
