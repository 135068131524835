
import { http } from "./http";
import { CREATE_LOCATION } from "./constants";

export const createLocation = async (data) => {
    return http.post(CREATE_LOCATION, data)
}
// export const getContact = async (data) => {
//     return http.get(CREATE_CONTACT + '/' +data)
// }
export const getLocationsList = async (data) => {
    return http.get(CREATE_LOCATION + '?' + (data || ''));
}
// // export const searchContacts = async (data) => {
// //     
// //     return http.get(Contact_SEARCH + '?' + data)
// // }
export const updateLocation = async (data) => {
    return http.put(CREATE_LOCATION + '/' + data.id, data)
}
